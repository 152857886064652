 .gray {
   background-color: var(--theme-color);
 }

 .header-style-1 .category>li>a,
 .header-style-1 .category>li a:hover,
 .header-style-1 .category>li a:active {
   padding-top: 15px;
   padding-bottom: 15px
 }

 .header-style-1 .category>li ul li a {
   padding: 5px 35px
 }

 .header-style-1 .category>li ul li a:hover,
 .header-style-1 .category>li ul li a:active {
   padding: 5px 35px
 }

 .header-style-1 .upper-menu.category a {
   padding-top: 10px;
   padding-bottom: 10px;
   padding-right: 35px
 }

 .text-light-header .category>li>a {
   color: #cecece !important
 }


 .header-compact .category a,
 .header-compact .category a:hover,
 .header-compact .category a:active {
   font-size: 14px
 }

 .category.sm-vertical a .sub-arrow {
   margin-top: -22px;
   right: 7px
 }

 .category ul {
   border-radius: 0
 }

 .category.light-font-menu li>a {
   color: #393230
 }

 .category a,
 .category a:hover,
 .category a:active {
   padding-right: 45px;
   color: black;
   font-size: 16px;
   font-weight: 600;
   line-height: 23px;
   text-decoration: none;
   text-transform: uppercase;
   padding-top: 42px;
   padding-bottom: 42px;
   letter-spacing: 0.07em
 }

 .category a:hover {
   color: var(--theme-color);
 }

 .category a.disabled {
   color: #ffd8d4
 }

 .category a .sub-arrow {
   position: absolute;
   top: 50%;
   margin-top: -17px;
   left: auto;
   right: 15px;
   width: 34px;
   height: 34px;
   overflow: hidden;
   font: bold 16px/34px monospace !important;
   text-align: center;
   text-shadow: none;
   border-radius: 4px
 }

 .category>li:last-child>a,
 .category>li:last-child>*:not(ul) a,
 .category>li:last-child>ul,
 .category>li:last-child>ul>li:last-child>a,
 .category>li:last-child>ul>li:last-child>*:not(ul) a,
 .category>li:last-child>ul>li:last-child>ul,
 .category>li:last-child>ul>li:last-child>ul>li:last-child>a,
 .category>li:last-child>ul>li:last-child>ul>li:last-child>*:not(ul) a,
 .category>li:last-child>ul>li:last-child>ul>li:last-child>ul,
 .category>li:last-child>ul>li:last-child>ul>li:last-child>ul>li:last-child>a,
 .category>li:last-child>ul>li:last-child>ul>li:last-child>ul>li:last-child>*:not(ul) a,
 .category>li:last-child>ul>li:last-child>ul>li:last-child>ul>li:last-child>ul,
 .category>li:last-child>ul>li:last-child>ul>li:last-child>ul>li:last-child>ul>li:last-child>a,
 .category>li:last-child>ul>li:last-child>ul>li:last-child>ul>li:last-child>ul>li:last-child>*:not(ul) a,
 .category>li:last-child>ul>li:last-child>ul>li:last-child>ul>li:last-child>ul>li:last-child>ul {
   border-radius: 0 0 0 0
 }

 .category>li:last-child>a.highlighted,
 .category>li:last-child>*:not(ul) a.highlighted,
 .category>li:last-child>ul>li:last-child>a.highlighted,
 .category>li:last-child>ul>li:last-child>*:not(ul) a.highlighted,
 .category>li:last-child>ul>li:last-child>ul>li:last-child>a.highlighted,
 .category>li:last-child>ul>li:last-child>ul>li:last-child>*:not(ul) a.highlighted,
 .category>li:last-child>ul>li:last-child>ul>li:last-child>ul>li:last-child>a.highlighted,
 .category>li:last-child>ul>li:last-child>ul>li:last-child>ul>li:last-child>*:not(ul) a.highlighted,
 .category>li:last-child>ul>li:last-child>ul>li:last-child>ul>li:last-child>ul>li:last-child>a.highlighted,
 .category>li:last-child>ul>li:last-child>ul>li:last-child>ul>li:last-child>ul>li:last-child>*:not(ul) a.highlighted {
   border-radius: 0
 }

 .category ul {
   background: #fff
 }




 .category ul a.disabled {
   color: #b3b3b3
 }

 .category ul ul a,
 .category ul ul a:hover,
 .category ul ul a:focus,
 .category ul ul a:active {
   border-left: 16px solid transparent
 }

 .category ul ul ul a,
 .category ul ul ul a:hover,
 .category ul ul ul a:focus,
 .category ul ul ul a:active {
   border-left: 24px solid transparent
 }

 .category ul ul ul ul a,
 .category ul ul ul ul a:hover,
 .category ul ul ul ul a:focus,
 .category ul ul ul ul a:active {
   border-left: 32px solid transparent
 }

 .category ul ul ul ul ul a,
 .category ul ul ul ul ul a:hover,
 .category ul ul ul ul ul a:focus,
 .category ul ul ul ul ul a:active {
   border-left: 40px solid transparent
 }

 .category ul li {
   border-top: 1px solid rgba(0, 0, 0, 0.05)
 }

 .category ul li:first-child {
   border-top: 0
 }

 .category .mega {
   position: unset !important
 }

 

 .category .mega ul {
   margin-left: 0 !important;
   max-width: -webkit-max-content;
   max-width: -moz-max-content;
   max-width: max-content
 }

 .category .icon-trend {
   color: var(--theme-color)
 }



 .category .link-section h5 {
   font-weight: 700;
   text-transform: capitalize;
   font-size: 14px
 }

 .category .link-section ul {
   position: relative;
   display: block;
   padding: 0
 }

 .category .link-section ul li a {
   padding-top: 3px;
   padding-bottom: 3px;
   font-size: 14px;
   padding-left: 0
 }

 .category .clothing-menu {
   width: 815px !important;
   padding: 15px 30px 25px !important;
   background-color: #fff
 }

 .category .clothing-menu li img {
   padding: 20px 0 10px 0
 }

 .category .clothing-menu .link-section h5 {
   margin-top: 15px
 }

 .category .clothing-menu .link-section ul {
   background: none
 }

 .category .mega-menu ul {
   -webkit-box-shadow: none;
   box-shadow: none
 }

 .category li a {
   position: relative
 }

 .category li .lable-nav {
   position: absolute;
   background-color: var(--theme-color);
   padding: 2px 7px;
   color: #fff;
   top: 20px;
   text-transform: uppercase;
   font-size: 9px;
   left: 28px;
   line-height: 1.3
 }

 .category li .lable-nav:before {
   position: absolute;
   content: "";
   width: 0;
   height: 0;
   border-left: 5px solid transparent;
   border-right: 5px solid transparent;
   border-top: 5px solid var(--theme-color);
   top: 15px;
   left: 22px
 }

 .category li .lable-nav.lable-red {
   background-color: #ff4c3b
 }

 .category li .lable-nav.lable-red:before {
   border-top: 5px solid #ff4c3b
 }

 .sidenav .category li a {
   padding: 10px 50px 20px 40px
 }

 .sidenav .category li ul li a {
   padding: 5px 35px
 }

 .sidenav .category li ul li a:active,
 .sidenav .category li ul li a:focus,
 .sidenav .category li ul li a.highlighted {
   padding: 5px 35px
 }

 .sidenav .category li .clothing-menu li a {
   padding: 5px 0 !important
 }

 .sidenav .category li .clothing-menu li a:focus {
   font-size: 14px
 }

 .sidenav .category li .clothing-menu li a:hover {
   font-size: 14px;
   padding: 5px 0
 }

 .sidenav .category li .clothing-menu li a:focus,
 .sidenav .category li .clothing-menu li a:hover {
   padding: 5px 0 !important
 }

 .category ul a.highlighted:hover:before, .category ul a:active:hover:before, .category ul a:focus:hover:before, .category ul a:hover:before, .category ul a:hover:hover:before {
  height: 2px;
  transition: .5s ease;
  width: 50px;
}
.category ul a:before {
  background-color: var(--theme-color);
  bottom: 0;
  content: "";
  height: 2px;
  position: absolute;
  transition: .5s ease;
  width: 0;
}
.category ul a, .category ul a.highlighted, .category ul a:active, .category ul a:focus, .category ul a:hover {
  background: transparent;
  border: 0!important;
  border-radius: 0!important;
  color: #222;
  font-size: 16px;
  font-weight: 400;
  padding: 5px 15px;
  text-transform: capitalize;
}
.category ul li {
  border: 0;
}
.category ul {
  border: none;
  padding: 15px 0;
}
/* .category ul {
  box-shadow: 0 1px 6px 0 #ebebeb;
} */


 /* .carousel-indicators {  
    display: none;
  } */