.contact-page .contact-right ul li p {
    font-weight: 500;
    margin-bottom: 0;
    line-height: 30px;
}

.media-body{
    width: 100%;
    word-wrap: break-word;
}
.contact-office{
    color: gray;
}