.footer-title1:after{
    position: absolute;
    height: 3px;
    width: 200px;
    background-color: #bde27a;
    content: "";
    left: 0;
    right: 0;
    bottom: 0;
}
.footer-title1 {
    padding-bottom: 15px;
    margin-bottom: 30px;
    position: relative;
    color:  #bde27a;
  }