.section-b-space {
    padding-bottom: 70px
}  
 .section-b-space {
    padding-bottom: 60px
}

.section-b-space {
    padding-bottom: 50px
}
.section-b-space {
    padding-bottom: 40px
}
.dark-layout .section-b-space {
    border: none;
    padding-bottom: 0
}

.section-b-space {
    padding-bottom: 30px
}

a:hover{
    transition: .5s ease;
    text-decoration: none;
  }
.blog-details {
    padding-left: 0;
    padding-right: 0
}

.nav-tabs .nav-link.active, .nav-link .show>.nav-link {
    color: var(--theme-color);
    background-color: transparent;
    text-transform: uppercase;
    border-color: transparent;
  }

 .nav-link {
    color: #2d2a25;
    text-transform: uppercase;
  }

  .nav-tabs .nav-link {
    margin-bottom: -1px;
    background: 0 0;
    border: transparent;
    border-top-left-radius: 0.25rem;
    color: #2d2a25;
    border-top-right-radius: 0.25rem;
}
.nav-tabs .nav-link.active, .nav-link .show>.nav-link {
  color: var(--theme-color);
  font-weight: 500;
  background-color: transparent;
  text-transform: uppercase;
  border-color: transparent;
  border-bottom: 2px solid var(--theme-color) ;
}
.nav-tabs {
  border-bottom: none;
}
  .cart-wrap button{
    color: #bbb;
    font-size: 18px;
  }

  .cart-wrap button:hover{
    color: var(--theme-color);
  }

  a {
    transition: 0.5s ease;
    text-decoration: none;
  }

  .product-box .img-wrapper .product-thumb-list li, .product-wrap .img-wrapper .product-thumb-list li {
    display: block;
    opacity: .3;
    cursor: pointer;
}

.product-box .img-wrapper .product-thumb-list, .product-wrap .img-wrapper .product-thumb-list {
  position: absolute;
  bottom: 0;
  left: 0;
}

.product-box .img-wrapper .product-thumb-list li img, .product-wrap .img-wrapper .product-thumb-list li img {
  height: 50px;
  padding: 0;
}

.product-box .img-wrapper .product-thumb-list li.active, .product-wrap .img-wrapper .product-thumb-list li.active {
  opacity: 1;
}

.product-filters{
  cursor: pointer;
}

.description{
  white-space: pre-wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 45px;
}

.badgecode{
  background-color: var(--theme-color) !important;
}

.image-size{
  height: 250px;
  
}
.product-detail-section{
  background: #FAFFF7;
}

.box-b{
  text-align: center;
    background-color: #9ECB7F;
    border-radius: 5px;
    padding: 5px;
    font-weight: 600;
    color: #40590f;
    /* color: #ffffff; */
    cursor: pointer;
    margin: 5px 0px;
}

.nav-link:focus, .nav-link:hover {
  background-color: transparent !important;
  border-color: transparent !important;
  border: none !important;
}


.text-box .btn:link,
.text-box .btn:visited {
  text-transform: uppercase;
  text-decoration: none;
  padding: 15px 40px;
  display: inline-block;
  border-radius: 100px;
  transition: all .2s;
  position: absolute;
}

.text-box .btn:hover {
  transform: translateY(-3px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.text-box .btn:active {
  transform: translateY(-1px);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.text-box .btn-box {
  background-color: #517b04;
  color: #fff2f2;
  border-radius: 20px;
    padding: 20px;
}

.text-box .btn::after {
  content: "";
  display: inline-block;
  height: 100%;
  width: 100%;
  border-radius: 100px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transition: all .4s;
}

.text-box .btn-white::after {
  background-color: #ffffff;
}

.text-box .btn:hover::after {
  transform: scaleX(1.4) scaleY(1.6);
  opacity: 0;
}

.text-box .btn-animated {
  animation: moveInBottom 5s ease-out;
  animation-fill-mode: backwards;
}

@keyframes moveInBottom {
  0% {
      opacity: 0;
      transform: translateY(30px);
  }

  100% {
      opacity: 1;
      transform: translateY(0px);
  }
}

.specification .title{
  font-weight: 600;
}

.product-image-container img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  transition: transform 0.3s ease-in-out;
  cursor: pointer;
}

.zoom-image-container {
  overflow: hidden;
  position: relative;
}

.zoom-image-container:hover img {
  transform: scale(1.2); 
  transition: transform 0.3s ease-in-out;
}

.zoom-image-container img {
  transition: transform 0.3s ease-in-out;
}

.product-detail-section .product-description p,
.product-detail-section .product-description h6{
  font-size: 1rem !important;
}

.gallery-image {
  border: 2px solid transparent; 
  transition: border-color 0.5s ease; 
}

.selected .gallery-image {
  border-color: #2c2f33; 
}

.col-sm-2 {
  flex: inherit;
  width: 10%;
  position: relative;

}
