.right-toolbar {
    text-align: end;
}

.top-header .header-dropdown .mobile-account,
.top-header .header-dropdown .mobile-wishlist {
    position: fixed;
    bottom: 20px;
    right: 32%;
    font-size: 0;
    padding: 0;
    z-index: 9
}

.top-header .header-dropdown .mobile-account i:before,
.top-header .header-dropdown .mobile-wishlist i:before {
    content: "\e602"
}

.top-header .header-dropdown .mobile-account .onhover-show-div,
.top-header .header-dropdown .mobile-wishlist .onhover-show-div {
    top: unset;
    bottom: 42px;
    right: 0
}

.top-header .header-dropdown .mobile-account:hover i,
.top-header .header-dropdown .mobile-wishlist:hover i {
    color: #999
}

.top-header .header-dropdown .mobile-wishlist {
    right: 64%
}

.top-header .header-dropdown .mobile-wishlist i:before {
    content: "\e634"
}

header.green-gradient .top-header .header-contact {
    padding: 10px 0
}

header.green-gradient .top-header .header-contact li {
    color: #fff
}

.dropdown{
    text-align: start;

}
.dropdown-menu {
    display: none;
    position: absolute;
    background-color: #fff;
    border: 1px solid #ccc;
}

.dropdown-menu li {
    list-style: none;
}

.dropdown:hover > .dropdown-menu {
    display: block;
}

.submenu-dropdown-menu {
    display: none;
    position: absolute;
    top: 0;
    left: 100%; 
    background-color: #fff;
    border: 1px solid #ccc;
}
.pixelstrap ul li {
    border: 0;
}
.pixelstrap ul a:before {
    background-color: var(--theme-color);
    bottom: 0;
    content: "";
    height: 2px;
    position: absolute;
    transition: .5s ease;
    width: 0;
}

.pixelstrap ul a, .pixelstrap ul a.highlighted, .pixelstrap ul a:active, .pixelstrap ul a:focus, .pixelstrap ul a:hover {
    background: transparent;
    border: 0!important;
    border-radius: 0!important;
    color: #222;
    font-weight: 400;
    padding: 5px 35px !important;
    text-transform: capitalize;
}
.pixelstrap ul {
    border: none;
    padding: 15px 0;
}
.pixelstrap ul, .pixelstrap ul ul {
    background: #fff;
}
/* .pixelstrap ul {
    box-shadow: 0 1px 6px 0 #ebebeb;
} */
.pixelstrap ul a.highlighted:hover:before, .pixelstrap ul a:active:hover:before, .pixelstrap ul a:focus:hover:before, .pixelstrap ul a:hover:before, .pixelstrap ul a:hover:hover:before {
    height: 2px;
    transition: .5s ease;
    width: 50px;
}
.dropdown:hover > .dropdown-menu > .dropdown:hover .submenu-dropdown-menu {
    /* display: grid;
    grid-gap: 1em;
    padding: 1em;
    grid-template-columns: repeat(3, 1fr); */
    display: block;
    /* width: 16em; */
    width: 100%;
}
.dropdown-menu a {
    text-decoration: none; 
}

.dropdown-menu .fa-chevron-right {
    float: right;
}
.top{

margin-top: -40px;}

.menu-header{
    position: relative;
    z-index: 10;
}

.onhover-show-div{
    display: none;
}

.myAccount:hover .onhover-show-div{
    display: block;
    background-color: #fff;
    top: 20px;
    z-index: 10;
    text-align: left;
    box-shadow: 0 0 1px 0 #ebebeb;
    position: absolute;
    transition: all .3s linear;
    /* min-width: 160px; */
    text-align: left;
    box-shadow: 0 0 1px 0 #ebebeb;
    padding: 15px 20px;
    border-radius: 0!important;
    transform: translateY(30px);
}

.cart-qty-cls {
    position: absolute;
    background: var(--theme-color);
    width: 20px;
    height: 20px;
    color: #fff;
    border-radius: 20px;
    text-align: center;
    font-size: 12px;
    line-height: 14px;
    font-weight: 600;
    top: 20%;
    right: -8px;
    padding: 3px;
}

.show-div .setting {
    padding: 15px 20px 20px;
    min-width: 175px;
    right: 0;
    left: unset;
}

.main-menu .menu-left {
    display: flex;
    align-items: center;
}

.main-menu .menu-left .navbar {
    display: inline-block;
    padding: 40px 45px 40px 0;
}

.flex-data{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    color: #999;
}

.header-icon{
    font-size: 20px;
    margin-top: 2px;
}

.fixed-bottom {
    position: fixed;
    right: 0;
    bottom: 0;
    height: 75px;
    left: 0;
    z-index: 1030;
}
.close-sidebar{
    cursor: pointer;
}

@media (max-width: 1199px) {

.dropdown:hover > .dropdown-menu {
    display: block;
    border: none;
    position: relative;
}
.dropdown:hover > .dropdown-menu > .dropdown:hover .submenu-dropdown-menu {
    display: block;
    position: static;
    border: none;
}
}

/* .overlay-content input {
    background-color: transparent;
    border: 0;
    border-bottom: 2px solid #777;
    border-radius: 0;
    padding: 20px 0;
} */
.modal .btn-close{
    top:20px
}
.category-header {
    position: relative;
    z-index: 1;
    top: 80px;
    /* margin-bottom: 70px; */
}

.overlay-content .form-control:focus {
    box-shadow: none;
}

 .onhover-div .show-div.shopping-cart {
    padding: 15px;
    min-width: 270px;
    left: unset;
    right: 0;
    max-height: 250px;
    overflow-y: auto;
}

.onhover-div .show-div.shopping-cart li {
    position: relative;
    padding-bottom: 10px;
    width: 100%;
}
.onhover-div .show-div.shopping-cart li .media img {
    height: 90px;
}
.onhover-div .show-div.shopping-cart li .media .media-body {
    align-self: center;
}

.onhover-div .show-div.shopping-cart li .media .media-body h6 {
    font-size: 14px;
    color: #777;
}
.onhover-div .show-div.shopping-cart li .media .media-body h4 {
    color: #333;
}
.onhover-div .show-div.shopping-cart li .media .media-body h4 span {
    color: #777;
    font-size: 16px;
}
.onhover-div .show-div.shopping-cart li .close-circle {
    position: absolute;
    top: 0;
    right: 0;
}
.onhover-div .show-div.shopping-cart li {
    position: relative;
    padding-bottom: 10px;
    width: 100%;
}

.onhover-div .show-div li {
    padding: 0;
}
.onhover-div .show-div.shopping-cart li .total {
    border-top: 1px solid #f1f5f4;
    border-bottom: 1px solid #f1f5f4;
    padding-top: 10px;
    padding-bottom: 10px;
}
.onhover-div .show-div.shopping-cart li .total h5 {
    text-transform: capitalize;
    margin-bottom: 0;
    font-size: 14px;
    color: #999;
}
.onhover-div .show-div.shopping-cart li .buttons .checkout {
    float: right;
}

.onhover-div .show-div.shopping-cart li .buttons a {
    font-size: 14px;
    color: #333;
    text-transform: capitalize;
    font-weight: 700;
}

.onhover-div .show-div.shopping-cart li .total h5 span {
    float: right;
}

.onhover-div .show-div.shopping-cart li .buttons a:hover{
    color: red;
}

.empty-cart{
    font-size: 16px;
    color: #222;
    letter-spacing: .05em;
}
.fix{
    height: 80px;
}