.section-b-space {
    padding-bottom: 70px
}  
 .section-b-space {
    padding-bottom: 60px
}

.section-b-space {
    padding-bottom: 50px
}
.section-b-space {
    padding-bottom: 40px
}
.dark-layout .section-b-space {
    border: none;
    padding-bottom: 0
}

.section-b-space {
    padding-bottom: 30px
}

a:hover{
    transition: .5s ease;
    text-decoration: none;
  }
.blog-details {
    padding-left: 0;
    padding-right: 0
}

.nav-tabs .nav-link.active, .nav-link .show>.nav-link {
    color: var(--theme-color);
    background-color: transparent;
    text-transform: uppercase;
    border-color: transparent;
  }

 .nav-link {
    color: #2d2a25;
    text-transform: uppercase;
  }

  .nav-tabs .nav-link {
    background: 0 0;
    border: transparent;
    border-top-left-radius: 0.25rem;
    color: #2d2a25;
    font-size: 1.3rem !important;
    font-weight: 600;
    border-top-right-radius: 0.25rem;
}
.nav-tabs .nav-link.active, .nav-link .show>.nav-link {
  color: var(--theme-color);
  font-weight: 600;
  background-color: transparent;
  text-transform: uppercase;
  border-color: transparent;
  border-bottom: 2px solid var(--theme-color) !important;
}
.nav-tabs {
  border-bottom: none;
}
  .cart-wrap button{
    color: #bbb;
    font-size: 18px;
  }

  .cart-wrap button:hover{
    color: var(--theme-color);
  }

  a {
    transition: 0.5s ease;
    text-decoration: none;
  }

  .product-box .img-wrapper .product-thumb-list li, .product-wrap .img-wrapper .product-thumb-list li {
    display: block;
    opacity: .3;
    cursor: pointer;
}

.product-box .img-wrapper .product-thumb-list, .product-wrap .img-wrapper .product-thumb-list {
  position: absolute;
  bottom: 0;
  left: 0;
}

.product-box .img-wrapper .product-thumb-list li img, .product-wrap .img-wrapper .product-thumb-list li img {
  height: 50px;
  padding: 0;
}

.product-box .img-wrapper .product-thumb-list li.active, .product-wrap .img-wrapper .product-thumb-list li.active {
  opacity: 1;
}

.product-filters{
  cursor: pointer;
}

.description{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.badgecode{
  background-color: var(--theme-color) !important;
}