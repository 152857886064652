.home-slider .slider-contain h2,
.center-slider .slider-contain h2 {
  font-weight: 600;
  margin-bottom: 0;
  margin-top: 10px;
  color: white;
  font-size: 43px !important;
}

.home-slider .slider-contain h4, .center-slider .slider-contain h4 {
  font-weight: 700;
    letter-spacing: 2px;
    color: #ffffff;
    margin-bottom: 0;
    font-size: 2.5rem;
    text-transform: uppercase;
}
.carousel-control-next, .carousel-control-prev {
  width: 5%;
}

.image {
  width: 100%;
  height: 100%;
  /* object-fit: cover; */
}

.set {
  position: absolute;
  top: 5%;
  left: 5%;
}

.test{
  padding-top: 80px;
}

.bg-image {
  height: 100%;
}

.headind-2{
  color: white;
}
.custom-font-size {
  font-size: 24px;
}