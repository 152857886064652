@import url('https://fonts.googleapis.com/css?family=Montserrat:400,700&display=swap');

body {
  margin: 0;
  font-family: "Montserrat" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.error-input {
  border-color: red !important;
}

.error-message {
  color: red;
}

code {
  font-family: "Montserrat";
}

.theme-color-1 {
  --theme-color: #517b04;
}

.theme-color-1 {
  --theme-color: #ff4c3b
}

.theme-color-2 {
  --theme-color: #f0583d
}

.theme-color-3 {
  --theme-color: #f0b54d
}

.theme-color-4 {
  --theme-color: #e4604a
}

.theme-color-5 {
  --theme-color: #d4b196
}

.theme-color-6 {
  --theme-color: #866e6c
}

.theme-color-7 {
  --theme-color: #cc2121
}

.theme-color-8 {
  --theme-color: #dc457e
}

.theme-color-9 {
  --theme-color: #6d7e87
}

.theme-color-10 {
  --theme-color: #fa869b
}

.theme-color-11 {
  --theme-color: #81ba00
}

.theme-color-12 {
  --theme-color: #fe816d
}

.theme-color-13 {
  --theme-color: #01effc;
  --theme-color2: #485ff2
}

.theme-color-14 {
  --theme-color: #5d7227;
  --theme-color2: #203f15
}

.theme-color-15 {
  --theme-color: #ff9944
}

.theme-color-16 {
  --theme-color: #5fcbc4
}

.theme-color-17 {
  --theme-color: #e38888
}

.theme-color-18 {
  --theme-color: #000000
}

.theme-color-19 {
  --theme-color: #3e5067
}

.theme-color-20 {
  --theme-color: #3fc1c9
}

.theme-color-21 {
  --theme-color: #5ecee4
}

.theme-color-22 {
  --theme-color: #38c6bb
}

.theme-color-23 {
  --theme-color: #2874f0
}

.theme-color-24 {
  --theme-color: #90453e
}

.theme-color-25 {
  --theme-color: #3fd09e
}

.theme-color-26 {
  --theme-color: #6d6659
}

.theme-color-27 {
  --theme-color: #f39910;
  --theme-color2: #394868
}

.theme-color-28 {
  --theme-color: #387ef0
}

.theme-color-29 {
  --theme-color: #dd5e89;
  --theme-color2: #f7bb97
}

.theme-color-30 {
  --theme-color: #206664;
  --theme-color2: #ee7a63
}

.theme-color-31 {
  --theme-color: #ee7a63
}

.theme-color-32 {
  --theme-color: #234ca1
}

.theme-color-32.dark {
  background-color: #262934
}

:root {
  --theme-color: #517b04
}

@-webkit-keyframes textNoise1 {
  0% {
    -webkit-clip-path: inset(14% 0 50% 0);
    clip-path: inset(14% 0 50% 0)
  }

  10% {
    -webkit-clip-path: inset(41% 0 82% 0);
    clip-path: inset(41% 0 82% 0)
  }

  20% {
    -webkit-clip-path: inset(86% 0 54% 0);
    clip-path: inset(86% 0 54% 0)
  }

  30% {
    -webkit-clip-path: inset(25% 0 5% 0);
    clip-path: inset(25% 0 5% 0)
  }

  40% {
    -webkit-clip-path: inset(48% 0 100% 0);
    clip-path: inset(48% 0 100% 0)
  }

  50% {
    -webkit-clip-path: inset(80% 0 60% 0);
    clip-path: inset(80% 0 60% 0)
  }

  60% {
    -webkit-clip-path: inset(88% 0 27% 0);
    clip-path: inset(88% 0 27% 0)
  }

  70% {
    -webkit-clip-path: inset(8% 0 71% 0);
    clip-path: inset(8% 0 71% 0)
  }

  80% {
    -webkit-clip-path: inset(32% 0 63% 0);
    clip-path: inset(32% 0 63% 0)
  }

  90% {
    -webkit-clip-path: inset(82% 0 58% 0);
    clip-path: inset(82% 0 58% 0)
  }

  100% {
    -webkit-clip-path: inset(19% 0 70% 0);
    clip-path: inset(19% 0 70% 0)
  }
}

@keyframes textNoise1 {
  0% {
    -webkit-clip-path: inset(14% 0 50% 0);
    clip-path: inset(14% 0 50% 0)
  }

  10% {
    -webkit-clip-path: inset(41% 0 82% 0);
    clip-path: inset(41% 0 82% 0)
  }

  20% {
    -webkit-clip-path: inset(86% 0 54% 0);
    clip-path: inset(86% 0 54% 0)
  }

  30% {
    -webkit-clip-path: inset(25% 0 5% 0);
    clip-path: inset(25% 0 5% 0)
  }

  40% {
    -webkit-clip-path: inset(48% 0 100% 0);
    clip-path: inset(48% 0 100% 0)
  }

  50% {
    -webkit-clip-path: inset(80% 0 60% 0);
    clip-path: inset(80% 0 60% 0)
  }

  60% {
    -webkit-clip-path: inset(88% 0 27% 0);
    clip-path: inset(88% 0 27% 0)
  }

  70% {
    -webkit-clip-path: inset(8% 0 71% 0);
    clip-path: inset(8% 0 71% 0)
  }

  80% {
    -webkit-clip-path: inset(32% 0 63% 0);
    clip-path: inset(32% 0 63% 0)
  }

  90% {
    -webkit-clip-path: inset(82% 0 58% 0);
    clip-path: inset(82% 0 58% 0)
  }

  100% {
    -webkit-clip-path: inset(19% 0 70% 0);
    clip-path: inset(19% 0 70% 0)
  }
}

@-webkit-keyframes textNoise2 {
  0% {
    -webkit-clip-path: inset(72% 0 4% 0);
    clip-path: inset(72% 0 4% 0)
  }

  10% {
    -webkit-clip-path: inset(18% 0 33% 0);
    clip-path: inset(18% 0 33% 0)
  }

  20% {
    -webkit-clip-path: inset(37% 0 24% 0);
    clip-path: inset(37% 0 24% 0)
  }

  30% {
    -webkit-clip-path: inset(78% 0 51% 0);
    clip-path: inset(78% 0 51% 0)
  }

  40% {
    -webkit-clip-path: inset(90% 0 12% 0);
    clip-path: inset(90% 0 12% 0)
  }

  50% {
    -webkit-clip-path: inset(92% 0 62% 0);
    clip-path: inset(92% 0 62% 0)
  }

  60% {
    -webkit-clip-path: inset(63% 0 28% 0);
    clip-path: inset(63% 0 28% 0)
  }

  70% {
    -webkit-clip-path: inset(20% 0 8% 0);
    clip-path: inset(20% 0 8% 0)
  }

  80% {
    -webkit-clip-path: inset(19% 0 44% 0);
    clip-path: inset(19% 0 44% 0)
  }

  90% {
    -webkit-clip-path: inset(51% 0 50% 0);
    clip-path: inset(51% 0 50% 0)
  }

  100% {
    -webkit-clip-path: inset(20% 0 75% 0);
    clip-path: inset(20% 0 75% 0)
  }
}

@keyframes textNoise2 {
  0% {
    -webkit-clip-path: inset(72% 0 4% 0);
    clip-path: inset(72% 0 4% 0)
  }

  10% {
    -webkit-clip-path: inset(18% 0 33% 0);
    clip-path: inset(18% 0 33% 0)
  }

  20% {
    -webkit-clip-path: inset(37% 0 24% 0);
    clip-path: inset(37% 0 24% 0)
  }

  30% {
    -webkit-clip-path: inset(78% 0 51% 0);
    clip-path: inset(78% 0 51% 0)
  }

  40% {
    -webkit-clip-path: inset(90% 0 12% 0);
    clip-path: inset(90% 0 12% 0)
  }

  50% {
    -webkit-clip-path: inset(92% 0 62% 0);
    clip-path: inset(92% 0 62% 0)
  }

  60% {
    -webkit-clip-path: inset(63% 0 28% 0);
    clip-path: inset(63% 0 28% 0)
  }

  70% {
    -webkit-clip-path: inset(20% 0 8% 0);
    clip-path: inset(20% 0 8% 0)
  }

  80% {
    -webkit-clip-path: inset(19% 0 44% 0);
    clip-path: inset(19% 0 44% 0)
  }

  90% {
    -webkit-clip-path: inset(51% 0 50% 0);
    clip-path: inset(51% 0 50% 0)
  }

  100% {
    -webkit-clip-path: inset(20% 0 75% 0);
    clip-path: inset(20% 0 75% 0)
  }
}

body {
  position: relative;
  background: #fff;
  font-size: 14px
}

h1 {
  font-size: 60px;
  color: #222;
  font-weight: 700;
  text-transform: uppercase
}

h1 span {
  font-size: 107px;
  font-weight: 700;
  color: var(--theme-color)
}

h2 {
  font-size: 1.7rem !important;
  font-weight: 600 !important;
  color: #222;
  text-transform: uppercase;
  /* font-weight: 700; */
  line-height: 1;
  letter-spacing: 0.02em
}

h3 {
  font-size: 1.5rem !important;
  font-weight: 400;
  color: #777;
  letter-spacing: 0.03em
}

h4 {
  font-size: 18px;
  text-transform: capitalize;
  font-weight: 400;
  letter-spacing: 0.03em;
  line-height: 1
}

h5 {
  font-size: 1.3rem !important;
  font-weight: 600 !important;
  color: #222;
  line-height: 24px;
  letter-spacing: 0.05em
}

h6 {
  font-size: 1.2rem !important;
  font-weight: 400;
  color: #777;
  line-height: 24px
}

ul {
  padding-left: 0;
  margin-bottom: 0
}

li {
  display: inline-block
}

p {
  font-size: 1.2rem;
  color: #000000;
  line-height: 30px
}

.container-fluid {
  padding: 0 !important;
}

.content-page p {
  text-align: start;
}

a {
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
  text-decoration: none
}

a:hover {
  text-decoration: none;
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease
}

a:focus {
  outline: none
}

button:focus {
  outline: none
}

.btn-close:focus {
  -webkit-box-shadow: none;
  box-shadow: none
}

label {
  margin-bottom: 0.5rem
}

:focus {
  outline: none
}

.form-control:focus {
  -webkit-box-shadow: none;
  box-shadow: none
}

.form-select:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  border-color: #ced4da
}

.text-theme {
  color: var(--theme-color) !important
}

.bg-success-10 {
  background-color: rgba(25, 135, 84, 0.1) !important
}

.bg-secondary-10 {
  background-color: rgba(108, 117, 125, 0.1) !important
}

.bg-danger-10 {
  background-color: rgba(220, 53, 69, 0.1) !important
}

.page-title1 {
  text-align: left;
}

.page-title1 .title-inner1 {
  position: relative;
 
  font-size: 1.2rem !important;
}
.green-colour {
  color: #517b04;
}

.form-check-input:checked {
  background-color: var(--theme-color);
  border-color: var(--theme-color)
}

section,
.section-t-space {
  padding-top: 70px
}

.section-b-space {
  padding-bottom: 70px
}

hr.style1 {
  width: 75px;
  height: 3px;
  margin-top: 13px;
  background-color: var(--theme-color);
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  opacity: 1
}

.table thead th {
  border-bottom: 1px solid #dee2e6 !important
}

.form-control {
  border-radius: 0
}

.btn:hover {
  border-color: transparent !important;
}

.banner-padding {
  padding-top: 30px
}

.border-section {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd
}

.border-b {
  border-bottom: 1px solid #38352f
}


.border-top-grey {
  border-top: 1px solid #efefef
}


.dark-layout {
  background-color: #2d2a25
}

.light-layout {
  background-color: #f9f9f9
}

.white-layout {
  background-color: #fff
}

.bg-light0 {
  background-color: #d0edff
}

.bg-light1 {
  background-color: #f1e7e6
}

.bg-light2 {
  background-color: #bfbfbf
}

.bg-color1 {
  background-color: #c6c6c6
}

.bg-color2 {
  background-color: #ab5252
}

.bg-color3 {
  background-color: #6d6d6d
}

.bg-blog {
  background-color: #eee
}

.bg-grey {
  background-color: #f7f7f7
}

.bg_cls {
  background-color: #fafafa
}

.bg-loader {
  background-color: #f3f3f3
}

.overflow-hidden {
  overflow: hidden
}

[data-notify="progressbar"] {
  margin-bottom: 0;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 5px
}

.progress-bar {
  background-color: #19a340
}

.progress-bar-info {
  background-color: #00829a
}

/* .container-fluid.custom-container {
  padding-left: 90px;
  padding-right: 90px
} */


.background-transparent {
  background-color: transparent !important
}

.bg-overlay {
  background-blend-mode: overlay;
  background-color: rgba(250, 250, 250, 0.98)
}

.bg-blue-light {
  background-color: rgba(40, 115, 239, 0.06)
}

.bg-theme {
  background-color: var(--theme-color);
  background-color: var(--theme-color2)
}

.blur-up {
  -webkit-filter: blur(5px);
  filter: blur(5px);
  transition: filter 400ms, -webkit-filter 400ms
}

.blur-up.lazyloaded {
  -webkit-filter: blur(0);
  filter: blur(0)
}


.pl-section {
  padding-left: 310px
}

.section-white section {
  background-color: #fff
}

.w-80 {
  width: 80% !important
}

.fz-16 {
  font-size: 16px
}

.form-group {
  margin-bottom: 15px
}

.form-check-input:focus {
  -webkit-box-shadow: none;
  box-shadow: none
}

.media {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start
}

.media .media-body {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1
}

.form-check {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex
}

.input-group-prepend,
.input-group-append {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex
}

.breadcrumb {
  padding: 0.75rem 1rem
}

.row div[class*="col-"],
.row .col {
  position: relative
}

.form-inline {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center
}


@-webkit-keyframes animate {
  from {
    background-position: 0 0
  }

  to {
    background-position: 0 500px
  }
}

@keyframes animate {
  from {
    background-position: 0 0
  }

  to {
    background-position: 0 500px
  }
}

@-webkit-keyframes loading {
  0% {
    border: 0 solid #fff;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease
  }

  20% {
    border: 8px solid #fff;
    width: 0;
    height: 0;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease
  }

  100% {
    border: 8px solid #fff;
    width: 100%;
    height: 100%;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease
  }
}

@keyframes loading {
  0% {
    border: 0 solid #fff;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease
  }

  20% {
    border: 8px solid #fff;
    width: 0;
    height: 0;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease
  }

  100% {
    border: 8px solid #fff;
    width: 100%;
    height: 100%;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease
  }
}

@-webkit-keyframes skeleton-loader {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    background-position: -468px 0
  }

  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    background-position: 468px 0
  }
}

@keyframes skeleton-loader {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    background-position: -468px 0
  }

  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    background-position: 468px 0
  }
}

@-webkit-keyframes ring-rotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg)
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg)
  }
}

@keyframes ring-rotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg)
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg)
  }
}

@-webkit-keyframes up-down {
  0% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px)
  }

  50% {
    -webkit-transform: translateY(0);
    transform: translateY(0)
  }

  100% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px)
  }
}

@keyframes up-down {
  0% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px)
  }

  50% {
    -webkit-transform: translateY(0);
    transform: translateY(0)
  }

  100% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px)
  }
}

@-webkit-keyframes float {
  0% {
    -webkit-transform: translatey(0px);
    transform: translatey(0px)
  }

  50% {
    -webkit-transform: translatey(-15px);
    transform: translatey(-15px)
  }

  100% {
    -webkit-transform: translatey(0px);
    transform: translatey(0px)
  }
}

@keyframes float {
  0% {
    -webkit-transform: translatey(0px);
    transform: translatey(0px)
  }

  50% {
    -webkit-transform: translatey(-15px);
    transform: translatey(-15px)
  }

  100% {
    -webkit-transform: translatey(0px);
    transform: translatey(0px)
  }
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg)
  }

  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg)
  }
}

@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg)
  }

  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg)
  }
}



@-webkit-keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg)
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg)
  }
}

@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg)
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg)
  }
}

@-webkit-keyframes grow {

  0%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 0
  }

  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1
  }
}

@keyframes grow {

  0%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 0
  }

  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1
  }
}

@-webkit-keyframes checkmark {

  0%,
  100% {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0)
  }

  10%,
  50%,
  90% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1)
  }
}

@keyframes checkmark {

  0%,
  100% {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0)
  }

  10%,
  50%,
  90% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1)
  }
}

@-webkit-keyframes gradient {
  0% {
    background-position: 0% 50%
  }

  50% {
    background-position: 100% 50%
  }

  100% {
    background-position: 0% 50%
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 50%
  }

  50% {
    background-position: 100% 50%
  }

  100% {
    background-position: 0% 50%
  }
}

button {
  cursor: pointer
}

.btn {
  line-height: 20px !important;
  text-transform: uppercase !important;
  font-size: 14px !important;
  font-weight: 700 !important;
  border-radius: 0 !important;
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out
}

.btn:hover {
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out
}

.btn:focus {
  -webkit-box-shadow: none;
  box-shadow: none
}

.btn-solid {
  padding: 13px 29px;
  color: #fff;
  letter-spacing: 0.05em;
  border: 2px solid var(--theme-color);
  background-image: linear-gradient(30deg, var(--theme-color) 50%, transparent 50%);
  background-size: 850px;
  background-repeat: no-repeat;
  background-position: 0;
  -webkit-transition: background 300ms ease-in-out;
  transition: background 300ms ease-in-out
}

.btn-solid:hover {
  background-position: 100%;
  /* color: #fff !important; */
  color: #40590f !important;
  background-color: #9ECB7F !important
}

.btn-solid.black-btn {
  background-image: linear-gradient(30deg, #222 50%, transparent 50%);
  border: 2px solid #222
}

.btn-solid:focus {
  color: #000
}

.btn-solid.btn-gradient {
  background: var(--theme-color);
  background-image: -webkit-gradient(linear, left top, right top, from(var(--theme-color)), color-stop(var(--theme-color2)), color-stop(var(--theme-color)), to(var(--theme-color2)));
  background-image: linear-gradient(to right, var(--theme-color), var(--theme-color2), var(--theme-color), var(--theme-color2));
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  background-size: 300% 100%;
  border: none
}

.btn-solid.btn-gradient:hover {
  background-position: 100% 0;
  color: white;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out
}

.btn-solid.btn-green {
  background-image: -webkit-gradient(linear, left top, right top, from(var(--theme-color)), to(var(--theme-color2)));
  background-image: linear-gradient(to right, var(--theme-color), var(--theme-color2));
  border: none;
  background-color: var(--theme-color)
}

.btn-solid.btn-green:hover {
  background-color: var(--theme-color);
  background-image: none;
  color: white
}

.btn-solid.btn-sm {
  padding: 9px 16px
}

.btn-solid.btn-xs {
  padding: 5px 8px;
  text-transform: capitalize
}

.btn-solid.hover-solid {
  -webkit-transition: none;
  transition: none
}

.btn-solid.hover-solid:hover,
.btn-solid.hover-solid:focus {
  background-color: var(--theme-color);
  opacity: 0.92;
  color: #fff;
  -webkit-transition: none;
  transition: none
}

.btn-outline {
  display: inline-block;
  padding: 13px 29px;
  letter-spacing: 0.05em;
  border: 2px solid var(--theme-color);
  position: relative;
  color: #000
}

.btn-outline:before {
  -webkit-transition: 0.5s all ease;
  transition: 0.5s all ease;
  position: absolute;
  top: 0;
  left: 50%;
  right: 50%;
  bottom: 0;
  opacity: 0;
  content: "";
  background-color: var(--theme-color);
  z-index: -2
}

.btn-outline:hover,
.btn-outline:focus {
  background-color: var(--theme-color);
  color: #fff !important
}

.btn-outline:hover i,
.btn-outline:focus i {
  color: #fff !important
}

.btn-outline:hover:before,
.btn-outline:focus:before {
  -webkit-transition: 0.5s all ease;
  transition: 0.5s all ease;
  left: 0;
  right: 0;
  opacity: 1
}

.btn-outline.btn-sm {
  padding: 9px 16px;
  font-size: 12px
}

.btn-rounded {
  border-radius: 30px
}

button.btn.btn-solid:active,
button.btn.btn-outline:active {
  background-image: linear-gradient(30deg, var(--theme-color) 50%, transparent 50%);
  color: #fff;
  background: var(--theme-color)
}

.btn-classic:hover {
  background-color: var(--theme-color)
}

.btn-theme {
  background-color: var(--theme-color);
  color: #fff
}

.btn-white {
  background-color: white;
  color: var(--theme-color);
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  padding: 10px 29px
}

.btn-white:hover {
  color: #000;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease
}

.btn-block {
  display: block;
  width: 100%
}

.btn-close {
  background: none
}

.btn-custom {
  padding: 6px 30px !important;
  border: none
}

.btn-animation {
  position: relative;
  overflow: hidden
}

.btn-animation i {
  -webkit-animation: ring 4s 0.7s ease-in-out infinite;
  animation: ring 4s 0.7s ease-in-out infinite;
  font-size: 20px
}

.btn-animation:before {
  position: absolute;
  content: "";
  display: inline-block;
  top: -180px;
  left: 0;
  width: 30px;
  height: 100%;
  background-color: #fff;
  -webkit-animation: shiny-btn1 5s ease-in-out infinite;
  animation: shiny-btn1 5s ease-in-out infinite
}

@-webkit-keyframes shiny-btn1 {
  0% {
    -webkit-transform: scale(0) rotate(45deg);
    transform: scale(0) rotate(45deg);
    opacity: 0
  }

  80% {
    -webkit-transform: scale(0) rotate(45deg);
    transform: scale(0) rotate(45deg);
    opacity: 0.5
  }

  81% {
    -webkit-transform: scale(4) rotate(45deg);
    transform: scale(4) rotate(45deg);
    opacity: 1
  }

  100% {
    -webkit-transform: scale(50) rotate(45deg);
    transform: scale(50) rotate(45deg);
    opacity: 0
  }
}

@keyframes shiny-btn1 {
  0% {
    -webkit-transform: scale(0) rotate(45deg);
    transform: scale(0) rotate(45deg);
    opacity: 0
  }

  80% {
    -webkit-transform: scale(0) rotate(45deg);
    transform: scale(0) rotate(45deg);
    opacity: 0.5
  }

  81% {
    -webkit-transform: scale(4) rotate(45deg);
    transform: scale(4) rotate(45deg);
    opacity: 1
  }

  100% {
    -webkit-transform: scale(50) rotate(45deg);
    transform: scale(50) rotate(45deg);
    opacity: 0
  }
}

@-webkit-keyframes ring {
  0% {
    -webkit-transform: rotateZ(0);
    transform: rotateZ(0)
  }

  1% {
    -webkit-transform: rotateZ(15deg);
    transform: rotateZ(15deg)
  }

  3% {
    -webkit-transform: rotateZ(-14deg);
    transform: rotateZ(-14deg)
  }

  5% {
    -webkit-transform: rotateZ(17deg);
    transform: rotateZ(17deg)
  }

  7% {
    -webkit-transform: rotateZ(-16deg);
    transform: rotateZ(-16deg)
  }

  9% {
    -webkit-transform: rotateZ(15deg);
    transform: rotateZ(15deg)
  }

  11% {
    -webkit-transform: rotateZ(-14deg);
    transform: rotateZ(-14deg)
  }

  13% {
    -webkit-transform: rotateZ(13deg);
    transform: rotateZ(13deg)
  }

  15% {
    -webkit-transform: rotateZ(-12deg);
    transform: rotateZ(-12deg)
  }

  17% {
    -webkit-transform: rotateZ(12deg);
    transform: rotateZ(12deg)
  }

  19% {
    -webkit-transform: rotateZ(-10deg);
    transform: rotateZ(-10deg)
  }

  21% {
    -webkit-transform: rotateZ(9deg);
    transform: rotateZ(9deg)
  }

  23% {
    -webkit-transform: rotateZ(-8deg);
    transform: rotateZ(-8deg)
  }

  25% {
    -webkit-transform: rotateZ(7deg);
    transform: rotateZ(7deg)
  }

  27% {
    -webkit-transform: rotateZ(-5deg);
    transform: rotateZ(-5deg)
  }

  29% {
    -webkit-transform: rotateZ(5deg);
    transform: rotateZ(5deg)
  }

  31% {
    -webkit-transform: rotateZ(-4deg);
    transform: rotateZ(-4deg)
  }

  33% {
    -webkit-transform: rotateZ(3deg);
    transform: rotateZ(3deg)
  }

  35% {
    -webkit-transform: rotateZ(-2deg);
    transform: rotateZ(-2deg)
  }

  37% {
    -webkit-transform: rotateZ(1deg);
    transform: rotateZ(1deg)
  }

  39% {
    -webkit-transform: rotateZ(-1deg);
    transform: rotateZ(-1deg)
  }

  41% {
    -webkit-transform: rotateZ(1deg);
    transform: rotateZ(1deg)
  }

  43% {
    -webkit-transform: rotateZ(0);
    transform: rotateZ(0)
  }

  100% {
    -webkit-transform: rotateZ(0);
    transform: rotateZ(0)
  }
}

@keyframes ring {
  0% {
    -webkit-transform: rotateZ(0);
    transform: rotateZ(0)
  }

  1% {
    -webkit-transform: rotateZ(15deg);
    transform: rotateZ(15deg)
  }

  3% {
    -webkit-transform: rotateZ(-14deg);
    transform: rotateZ(-14deg)
  }

  5% {
    -webkit-transform: rotateZ(17deg);
    transform: rotateZ(17deg)
  }

  7% {
    -webkit-transform: rotateZ(-16deg);
    transform: rotateZ(-16deg)
  }

  9% {
    -webkit-transform: rotateZ(15deg);
    transform: rotateZ(15deg)
  }

  11% {
    -webkit-transform: rotateZ(-14deg);
    transform: rotateZ(-14deg)
  }

  13% {
    -webkit-transform: rotateZ(13deg);
    transform: rotateZ(13deg)
  }

  15% {
    -webkit-transform: rotateZ(-12deg);
    transform: rotateZ(-12deg)
  }

  17% {
    -webkit-transform: rotateZ(12deg);
    transform: rotateZ(12deg)
  }

  19% {
    -webkit-transform: rotateZ(-10deg);
    transform: rotateZ(-10deg)
  }

  21% {
    -webkit-transform: rotateZ(9deg);
    transform: rotateZ(9deg)
  }

  23% {
    -webkit-transform: rotateZ(-8deg);
    transform: rotateZ(-8deg)
  }

  25% {
    -webkit-transform: rotateZ(7deg);
    transform: rotateZ(7deg)
  }

  27% {
    -webkit-transform: rotateZ(-5deg);
    transform: rotateZ(-5deg)
  }

  29% {
    -webkit-transform: rotateZ(5deg);
    transform: rotateZ(5deg)
  }

  31% {
    -webkit-transform: rotateZ(-4deg);
    transform: rotateZ(-4deg)
  }

  33% {
    -webkit-transform: rotateZ(3deg);
    transform: rotateZ(3deg)
  }

  35% {
    -webkit-transform: rotateZ(-2deg);
    transform: rotateZ(-2deg)
  }

  37% {
    -webkit-transform: rotateZ(1deg);
    transform: rotateZ(1deg)
  }

  39% {
    -webkit-transform: rotateZ(-1deg);
    transform: rotateZ(-1deg)
  }

  41% {
    -webkit-transform: rotateZ(1deg);
    transform: rotateZ(1deg)
  }

  43% {
    -webkit-transform: rotateZ(0);
    transform: rotateZ(0)
  }

  100% {
    -webkit-transform: rotateZ(0);
    transform: rotateZ(0)
  }
}


.ratio_45 .bg-size:before {
  padding-top: 45%;
  content: "";
  display: block
}


.ratio2_3 .bg-size:before {
  padding-top: 60%;
  content: "";
  display: block
}

.ratio3_2 .bg-size:before {
  padding-top: 66.66%;
  content: "";
  display: block
}


.ratio_square .bg-size:before {
  padding-top: 100%;
  content: "";
  display: block
}


.ratio_asos .bg-size:before {
  padding-top: 127.7777778%;
  content: "";
  display: block
}

.slick-slider {
  overflow: hidden
}

.slick-slider.slick-slider {
  overflow: visible
}

.no-arrow .slick-next,
.no-arrow .slick-prev {
  display: none !important
}

.theme-dots .slick-dots {
  bottom: 10px
}

.theme-dots .slick-dots li button:before {
  font-size: 12px
}

.theme-dots .slick-dots li.slick-active button:before {
  color: var(--theme-color)
}

.theme-dots.slick-dotted.slick-slider {
  margin-bottom: 0
}

.slick-default-margin .slick-list {
  margin-left: -12px;
  margin-right: -12px
}

.slick-default-margin .slick-slide>div {
  margin-left: 12px;
  margin-right: 12px
}

.slick-sm-margin .slick-list {
  margin-left: -8px;
  margin-right: -8px
}

.slick-sm-margin .slick-slide>div {
  margin-left: 8px;
  margin-right: 8px
}

.title1 {
  text-align: center
}

.title1 h4 {
  color: var(--theme-color);
  padding-bottom: 10px;
  margin-bottom: 0;
  margin-top: -3px
}

.title1 .title-inner1 {
  /* padding-bottom: 15px;
  margin-bottom: 30px; */
  position: relative;
  color: #517b04;

}

/* 
.title1 .title-inner1:after {
  position: absolute;
  height: 5px;
  width: 70px;
  background-color: var(--theme-color);
  content: "";
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: 0
} */

.title1.title-gradient .title-inner1:after {
  background: var(--theme-color);
  background: -webkit-gradient(linear, left top, left bottom, from(var(--theme-color)), color-stop(99%, var(--theme-color2)));
  background: linear-gradient(180deg, var(--theme-color) 0%, var(--theme-color2) 99%);
  background-color: var(--theme-color)
}

.title1.title5 .title-inner1 {
  padding-bottom: 0;
  margin-bottom: 0
}

.title1.title5 .title-inner1:after {
  display: none
}

.title1.title5 hr[role="tournament6"] {
  border: 0px solid;
  height: 1px;
  background-image: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, 0)), color-stop(var(--theme-color)), to(rgba(0, 0, 0, 0)));
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0), var(--theme-color), rgba(0, 0, 0, 0));
  display: block;
  width: 25%;
  margin: 15px auto 30px auto
}

.title1.title5 hr[role="tournament6"]::before {
  position: absolute;
  background-color: #efefef;
  border: 1px solid;
  border-color: var(--theme-color);
  padding: 5px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  left: 50%;
  margin: -6px 0px 0px 0;
  content: ""
}

.title1.wo-border .title-inner1 {
  padding-bottom: 0
}

.title1.wo-border .title-inner1:after {
  display: none
}

.ajax-search.form_search {
  overflow: unset
}

.ajax-search .twitter-typeahead {
  width: 100%
}

.ajax-search .typeahead {
  padding: 15px 20px;
  width: 100% !important;
  background-color: #fff !important;
  border-radius: 5px;
  font-size: 18px
}

.ajax-search .empty-message {
  color: var(--theme-color);
  text-align: center;
  padding: 10px 0px;
  font-size: 16px
}

.ajax-search .tt-menu {
  display: block;
  width: 100%;
  background-color: #fff;
  border: unset !important;
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  max-height: 350px;
  overflow: auto;
  top: 55px !important;
  border-radius: 5px;
  padding: 8px 0
}

.ajax-search .tt-suggestion {
  padding: 0px 14px;
  font-size: 18px;
  line-height: 24px;
  cursor: pointer
}

.ajax-search .man-section {
  position: relative;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center
}

.ajax-search .image-section {
  padding: 5px 0
}

.ajax-search .image-section img {
  width: 50px;
  -webkit-filter: invert(0) !important;
  filter: invert(0) !important
}

.ajax-search .description-section {
  width: 80%
}

.ajax-search .description-section h4 {
  margin: 0px;
  padding: 0px 7px;
  font-size: 17px;
  color: #222222;
  margin-top: 0;
  margin-bottom: 2px;
  text-transform: capitalize
}

.ajax-search .description-section span {
  padding: 7px;
  font-size: 16px;
  color: #a09999
}

.full-banner {
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 190px;
  padding-bottom: 190px;
  background-position: center
}

.full-banner.parallax-layout {
  padding-top: 350px;
  padding-bottom: 350px
}

.full-banner.parallax-layout .banner-contain h4 {
  color: #7f786d
}

.full-banner.banner-layout-3 .banner-contain h4 {
  color: #fff
}

.full-banner.banner-layout-3 .banner-contain h3 {
  color: #fff;
  margin-top: 10px
}

.full-banner.banner-layout-3 .banner-contain .color {
  color: var(--theme-color);
  line-height: 1
}

.full-banner.parallax {
  background-attachment: fixed
}

.full-banner.p-left .banner-contain {
  float: left
}

.full-banner.p-right .banner-contain {
  float: right
}

.full-banner.p-center .banner-contain {
  left: 0;
  right: 0;
  margin: 0 auto
}

.full-banner.feature-banner {
  padding: 50px 0
}

.full-banner.feature-banner h2 {
  text-align: center;
  color: #fff;
  margin-bottom: 180px;
  margin-top: -7px
}

.full-banner.feature-banner .feature-object,
.full-banner.feature-banner .feature-object-right {
  text-align: right;
  margin-bottom: -5px
}

.full-banner.feature-banner .feature-object li,
.full-banner.feature-banner .feature-object-right li {
  margin-bottom: 55px;
  margin-right: 50px
}

.full-banner.feature-banner .feature-object li .media img,
.full-banner.feature-banner .feature-object-right li .media img {
  border-radius: 100%;
  border: 1px solid white;
  margin-left: 15px;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease
}

.full-banner.feature-banner .feature-object li .media .media-body,
.full-banner.feature-banner .feature-object-right li .media .media-body {
  text-align: right;
  margin-top: 18px
}

.full-banner.feature-banner .feature-object li .media .media-body h4,
.full-banner.feature-banner .feature-object li .media .media-body p,
.full-banner.feature-banner .feature-object-right li .media .media-body h4,
.full-banner.feature-banner .feature-object-right li .media .media-body p {
  color: white
}

.full-banner.feature-banner .feature-object li .media .media-body h4,
.full-banner.feature-banner .feature-object-right li .media .media-body h4 {
  font-size: 20px
}

.full-banner.feature-banner .feature-object li .media .media-body p,
.full-banner.feature-banner .feature-object-right li .media .media-body p {
  margin-bottom: 0
}

.full-banner.feature-banner .feature-object li:hover .media img,
.full-banner.feature-banner .feature-object-right li:hover .media img {
  background-color: white;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  -webkit-transform: scale(1.05);
  transform: scale(1.05)
}

.full-banner.feature-banner .feature-object li:nth-child(2),
.full-banner.feature-banner .feature-object-right li:nth-child(2) {
  padding-right: 30px
}

.full-banner.feature-banner .feature-object li:nth-child(3),
.full-banner.feature-banner .feature-object-right li:nth-child(3) {
  padding-right: 60px;
  margin-bottom: 0
}

.full-banner.feature-banner .feature-object-right {
  text-align: left;
  margin-left: 50px
}

.full-banner.feature-banner .feature-object-right li .media img {
  margin-left: 0;
  margin-right: 15px
}

.full-banner.feature-banner .feature-object-right li .media .media-body {
  text-align: left
}

.full-banner.feature-banner .feature-object-right li:nth-child(2) {
  padding-right: 0;
  padding-left: 30px
}

.full-banner.feature-banner .feature-object-right li:nth-child(3) {
  padding-right: 0;
  padding-left: 60px
}

.full-banner.feature-banner .center-img {
  position: absolute;
  bottom: -112px;
  left: 50%;
  margin: 0 auto;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%)
}

.full-banner.feature-banner .banner-decor .left-img {
  left: 50px;
  position: absolute;
  top: 0;
  -webkit-animation: movebounce 4.9s linear infinite;
  animation: movebounce 4.9s linear infinite
}

.full-banner.feature-banner .banner-decor .right-img {
  right: 50px;
  position: absolute;
  top: 0;
  -webkit-animation: movebounce 4.9s linear infinite;
  animation: movebounce 4.9s linear infinite
}

@-webkit-keyframes movebounce {
  0% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px)
  }

  50% {
    -webkit-transform: translateY(20px);
    transform: translateY(20px)
  }

  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px)
  }
}

@keyframes movebounce {
  0% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px)
  }

  50% {
    -webkit-transform: translateY(20px);
    transform: translateY(20px)
  }

  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px)
  }
}

.full-banner.small-banner {
  padding-top: 45px !important;
  padding-bottom: 45px !important
}

.full-banner.small-banner .banner-contain.app-detail {
  padding-left: 40px
}

.full-banner.small-banner .banner-contain.app-detail h4 {
  padding-top: 0;
  margin-bottom: 10px
}

.full-banner.small-banner .banner-contain.app-detail .store-btn a+a {
  margin-left: 10px
}

.full-banner .banner-contain .btn-solid,
.full-banner .banner-contain .btn-outline {
  margin-top: 20px
}

.full-banner .banner-contain h2 {
  font-size: 100px;
  font-weight: 700;
  color: var(--theme-color);
  text-transform: uppercase;
  margin-top: -15px
}

@media (max-width:480px) {
  .portfolio-bg .portfolio-img-container {
    height: 15rem;
  }
}

@media (max-width: 768px) {

  .home-slider .home,
  .center-slider .home {
    height: 45vh !important;
  }

  .page-slide {
    height: 35vh !important;
  }

  .home-slider .slider-contain h4,
  .center-slider .slider-contain h4,
  .page-slide h4 {
    font-size: 1.5rem !important;
  }

  .home-slider .slider-contain,
  .center-slider .slider-contain {
    width: 100%;
    height: 45vh !important;
    display: flex;
    align-items: center;
  }

  .page-slide .block-ellipsis-4line {
    display: -webkit-box;
    line-height: 1;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    white-space: initial;
    text-overflow: ellipsis;
  }

}

@media (max-width:991px) {

  .home-slider .home,
  .center-slider .home {
    height: 50vh !important;
  }

  /* 
  .home-slider .slider-contain h4,
  .center-slider .slider-contain h4 {
    font-size: 1.5rem !important;
  } */

  .home-slider .slider-contain,
  .center-slider .slider-contain {
    width: 100%;
    height: 50vh !important;
    display: flex;
    align-items: center;
  }

}

.full-banner .banner-contain h3 {
  font-size: 50px !important;
  color: var(--theme-color);
  text-transform: uppercase;
  font-weight: 700
}

.full-banner .banner-contain h4 {
  font-size: 24px;
  color: #777;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.3em;
  padding-top: 10px;
  margin-bottom: -5px;
  line-height: 1.3
}

.full-banner .banner-contain .btn-solid,
.full-banner .banner-contain .btn-outline {
  margin-top: calc(20px + (65 - 20) * ((100vw - 320px) / (1920 - 320)))
}

.full-banner .banner-contain h2 {
  font-size: calc(24px + (70 - 24) * ((100vw - 320px) / (1920 - 320)));
  font-weight: 700;
  color: #fff;
  text-transform: capitalize;
  margin-bottom: 0;
  margin-top: 0
}

.full-banner .banner-contain.christmas-contain h3 {
  font-size: calc(20px + (30 - 20) * ((100vw - 320px) / (1920 - 320)));
  color: #fff;
  text-transform: capitalize;
  font-weight: 700;
  margin-bottom: 15px;
  margin-top: -6px
}

.full-banner .banner-contain.christmas-contain h4 {
  font-size: calc(18px + (24 - 18) * ((100vw - 320px) / (1920 - 320)));
  color: #fff;
  font-weight: 600;
  text-transform: capitalize;
  letter-spacing: 0.07em;
  padding-top: 10px;
  margin-bottom: -5px;
  line-height: 1.3
}

.full-banner .banner-contain.christmas-contain h4 span {
  color: var(--theme-color)
}

.full-banner .santa-img {
  position: absolute;
  bottom: -62px;
  left: 28px
}

.full-banner .santa-img img {
  display: inline-block;
  -webkit-animation-name: dance;
  animation-name: dance;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-transition-timing-function: cubic-bezier(0.6, -0.28, 0.735, 0.045);
  transition-timing-function: cubic-bezier(0.6, -0.28, 0.735, 0.045)
}

.full-banner .decor {
  position: absolute;
  top: -150px;
  left: 0
}

@-webkit-keyframes dance {
  0% {
    -webkit-transform: rotate(10deg);
    transform: rotate(10deg)
  }

  16.66%,
  49.98% {
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg)
  }

  32.32% {
    -webkit-transform: rotate(-5deg);
    transform: rotate(-5deg)
  }

  66.64%,
  100% {
    -webkit-transform: rotate(10deg);
    transform: rotate(10deg)
  }

  83.8% {
    -webkit-transform: rotate(5deg);
    transform: rotate(5deg)
  }
}

@keyframes dance {
  0% {
    -webkit-transform: rotate(10deg);
    transform: rotate(10deg)
  }

  16.66%,
  49.98% {
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg)
  }

  32.32% {
    -webkit-transform: rotate(-5deg);
    transform: rotate(-5deg)
  }

  66.64%,
  100% {
    -webkit-transform: rotate(10deg);
    transform: rotate(10deg)
  }

  83.8% {
    -webkit-transform: rotate(5deg);
    transform: rotate(5deg)
  }
}

.full-banner.section-space {
  padding: 100px 0
}

.full-banner.custom-space {
  padding: 100px 0
}

.parallax {
  background-attachment: fixed
}

.banner-text-white .collection-banner .contain-banner h2 {
  color: #fff
}

.collection-banner {
  position: relative;
  overflow: hidden
}

.collection-banner .img-part {
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  width: 100%
}

.collection-banner.p-top .contain-banner {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start
}

.collection-banner.p-left .contain-banner {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start
}

.collection-banner.p-right .contain-banner {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end
}

.collection-banner.p-center .contain-banner {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center
}

.collection-banner.tl-content .contain-banner,
.collection-banner.tl-content .contain-banner.banner-3 {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 30px
}

.collection-banner .contain-banner {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 70px;
  padding-right: 70px
}

.collection-banner .contain-banner.banner-3 {
  padding-left: 30px;
  padding-right: 30px
}

.collection-banner .contain-banner.banner-3 h2 {
  font-size: 26px;
  letter-spacing: 0.05em;
  color: white;
  margin-top: 5px;
  margin-bottom: -6px
}

.collection-banner .contain-banner.banner-3 h4 {
  color: #fff
}

.collection-banner .contain-banner.banner-4 {
  padding-left: 50px;
  padding-right: 50px
}

.collection-banner .contain-banner.banner-4 h2 {
  font-size: 28px;
  letter-spacing: 0.03em;
  color: white;
  margin-bottom: -6px
}

.collection-banner .contain-banner.content-bg h2 {
  font-size: calc(20px + (40 - 20) * ((100vw - 320px) / (1920 - 320)));
  padding: 10px;
  background-color: white
}

.collection-banner .contain-banner.content-bg.banner-3 h2 {
  font-size: calc(18px + (30 - 18) * ((100vw - 320px) / (1920 - 320)))
}

.collection-banner .contain-banner h4 {
  color: var(--theme-color);
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  margin-bottom: 0;
  line-height: 28px
}

.collection-banner .contain-banner h2 {
  font-size: 60px;
  font-weight: 700;
  color: #222;
  letter-spacing: 0.1em;
  margin-bottom: -6px
}

.collection-banner .contain-banner h2.font-smaller {
  font-size: calc(20px + (45 - 20) * ((100vw - 320px) / (1920 - 320)))
}

.collection-banner.christmas-banner .contain-banner {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-left: calc(30px + (60 - 30) * ((100vw - 320px) / (1920 - 320)));
  padding-right: calc(30px + (60 - 30) * ((100vw - 320px) / (1920 - 320)))
}

.collection-banner.christmas-banner .contain-banner h4 {
  font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
  color: #fff;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  margin-bottom: 0
}

.collection-banner.christmas-banner .contain-banner h2 {
  font-size: calc(24px + (40 - 24) * ((100vw - 320px) / (1920 - 320)));
  font-weight: 700;
  color: #fff;
  letter-spacing: 0.1em;
  margin-bottom: -6px
}

.collection-banner.overlay-banner {
  position: relative;
  z-index: 1
}

.collection-banner.overlay-banner .contain-banner {
  z-index: 1
}

.collection-banner.overlay-banner:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: black;
  opacity: 0.3
}

.collection-banner:hover .img-part {
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease
}


.banner-furniture {
  padding-left: 15px;
  padding-right: 15px
}

.banner-furniture .collection-banner .contain-banner.banner-3 h2 {
  color: #222;
  margin-bottom: 0
}

.banner-furniture .collection-banner .contain-banner.banner-3 h4 {
  color: var(--theme-color)
}

.banner-6 .collection-banner .contain-banner.banner-3 h2 {
  color: #222222
}


.home-slider .banner-content-bottom .collection-banner .contain-banner {
  background: none
}

.home-slider .banner-content-bottom .collection-banner .contain-banner h2 {
  font-size: 20px
}

.home-slider .banner-content-bottom .collection-banner .sale {
  position: absolute;
  top: 20px;
  left: 20px;
  display: inline-block;
  background: var(--theme-color);
  color: white;
  height: 2.5rem;
  width: 2.5rem;
  text-align: center;
  vertical-align: middle;
  line-height: 2.5rem;
  -webkit-transform: rotate(-20deg);
  transform: rotate(-20deg);
  -webkit-animation: beat 1s ease infinite alternate;
  animation: beat 1s ease infinite alternate
}

.home-slider .banner-content-bottom .collection-banner .sale:before,
.home-slider .banner-content-bottom .collection-banner .sale:after {
  content: "";
  position: absolute;
  background: inherit;
  height: inherit;
  width: inherit;
  top: 0;
  left: 0;
  z-index: -1;
  -webkit-transform: rotate(30deg);
  transform: rotate(30deg)
}

.home-slider .banner-content-bottom .collection-banner .sale:after {
  -webkit-transform: rotate(60deg);
  transform: rotate(60deg)
}

@-webkit-keyframes beat {
  from {
    -webkit-transform: rotate(-20deg) scale(1);
    transform: rotate(-20deg) scale(1)
  }

  to {
    -webkit-transform: rotate(-20deg) scale(1.1);
    transform: rotate(-20deg) scale(1.1)
  }
}

@keyframes beat {
  from {
    -webkit-transform: rotate(-20deg) scale(1);
    transform: rotate(-20deg) scale(1)
  }

  to {
    -webkit-transform: rotate(-20deg) scale(1.1);
    transform: rotate(-20deg) scale(1.1)
  }
}

.home-slider .banner-content-bottom .center-cls .slick-slider {
  margin-top: -40px
}

.home-slider .banner-content-bottom .slick-slider {
  margin-top: 6%
}

.home-slider .banner-content-bottom .slick-list {
  margin: 0 -12px
}

.home-slider .banner-content-bottom .slick-list .slick-slide>div {
  margin: 0 12px
}

.blog .slick-slide {
  line-height: 1
}

.classic-effect {
  position: relative;
  overflow: hidden
}

.classic-effect:before,
.classic-effect:after {
  background: var(--theme-color);
  width: 25%;
  position: absolute;
  content: "";
  opacity: 0;
  -webkit-transition: all 0.3s steps(4);
  transition: all 0.3s steps(4);
  z-index: 1;
  bottom: 100%;
  top: 0
}

.classic-effect:before {
  left: 0;
  -webkit-transition-delay: 0s;
  transition-delay: 0s
}

.classic-effect:after {
  left: 25%;
  -webkit-transition-delay: 0.025s;
  transition-delay: 0.025s
}

.classic-effect span:before,
.classic-effect span:after {
  background: var(--theme-color);
  width: 25%;
  position: absolute;
  content: "";
  opacity: 0;
  -webkit-transition: all 0.3s steps(4);
  transition: all 0.3s steps(4);
  z-index: 1;
  bottom: 100%;
  top: 0
}

.classic-effect span:before {
  left: 50%;
  -webkit-transition-delay: 0.05s;
  transition-delay: 0.05s
}

.classic-effect span:after {
  left: 75%;
  -webkit-transition-delay: 0.15s;
  transition-delay: 0.15s
}

.classic-effect:hover:before,
.classic-effect:hover:after {
  bottom: 0;
  opacity: 0.5
}

.classic-effect:hover span:before,
.classic-effect:hover span:after {
  bottom: 0;
  opacity: 0.5
}

.blog-details {
  text-align: center;
  padding-left: 15px;
  padding-right: 15px
}

.blog-details h4 {
  color: var(--theme-color);
  margin-top: 25px;
  line-height: 1;
  margin-bottom: 0;
  font-weight: 600;
}

.blog-details p {
  color: #222;
  font-size: 18px;
  text-transform: capitalize;
  font-weight: 700;
  line-height: 1.5;
  letter-spacing: 0.03em;
  margin-top: 10px;
  margin-bottom: -5px;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease
}

.blog-details p:hover {
  color: var(--theme-color);
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease
}

.blog-details h6 {
  line-height: 1;
  letter-spacing: 0.1em;
  margin-bottom: 0
}

.blog-2 .blog-details h6 {
  padding: 5px 0
}

.blog-left .blog-details {
  text-align: left;
  padding: 0
}

.blog-left .blog-details h4 {
  font-weight: 700;
  color: #000
}

.blog-left .blog-details p {
  font-size: 14px;
  text-transform: capitalize;
  font-weight: 400;
  margin-top: -3px
}

.blog-left .blog-details h6 {
  color: black;
  margin: 12px 0
}

.home-slider.fullpage .home {
  height: calc(100vh - 2px)
}

.home-slider.fullpage .home .slider-contain {
  height: calc(100vh - 2px);
  padding-left: 25px
}

.home-slider.fullpage .home .slider-contain h1 {
  font-style: italic;
  font-size: 50px;
  margin-top: -12px
}

.home-slider.fullpage .home .slider-contain p {
  max-width: 600px;
  line-height: 1.5;
  margin-top: 15px;
  margin-bottom: 10px;
  font-size: 16px;
  color: #777777
}

.home-slider.fullpage .slick-next,
.home-slider.fullpage .slick-prev {
  display: none !important
}

.home-slider.fullpage:hover .slick-next,
.home-slider.fullpage:hover .slick-prev {
  display: none !important
}

.home-slider.fullpage .slick-dots {
  top: 50%;
  bottom: unset;
  width: unset;
  left: 20px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%)
}

.home-slider.fullpage .slick-dots li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex
}

.home-slider.fullpage .slick-dots li button:before {
  font-size: 15px;
  color: var(--theme-color);
  opacity: 0.2
}

.home-slider.fullpage .slick-dots li.slick-active button:before {
  opacity: 0.8
}

.home-slider.instagram-slider .home {
  height: 50vh
}

.home-slider.instagram-slider .home .slider-contain {
  height: 50vh
}

.home-slider.slick-dotted.slick-slider {
  margin-bottom: 0
}

.form_search {
  height: 45px;
  -webkit-box-shadow: 0 0 0 1px #e2e2e2;
  box-shadow: 0 0 0 1px #e2e2e2;
  overflow: hidden;
  position: relative;
  width: 700px;
  background-color: #f5f5f5;
  border-radius: 25px
}

.form_search input {
  width: 100%;
  height: 45px;
  font-size: 16px;
  color: darkgrey;
  border: none;
  outline: none;
  background: none;
  padding: 0 10px
}

.form_search button {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 40px;
  height: 35px;
  padding: 0;
  border: none;
  outline: none;
  cursor: pointer;
  background-color: transparent;
  border-radius: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center
}

.form_search button i {
  font-size: 18px;
  color: var(--theme-color)
}

.blog.blog_box .blog-details {
  text-align: left;
  padding: 0
}

.blog.blog_box .blog-details p {
  font-size: 14px;
  text-transform: none;
  color: #333;
  font-weight: 400;
  margin-bottom: 8px;
  margin-top: 3px
}

.blog.blog_box .blog-details a p {
  color: #40494f;
  text-transform: capitalize;
  font-weight: 700;
  margin-bottom: 0;
  margin-top: 10px
}

.blog.blog_box .blog-details .read-cls {
  text-transform: capitalize;
  font-weight: 700;
  color: #000
}

.collection-banner.ratio_asos .bg-size:before {
  padding-top: 28%;
  content: "";
  display: block
}

.slider [data-animation-in] {
  opacity: 0;
  -webkit-animation-duration: 1.5s;
  animation-duration: 1.5s;
  -webkit-transition: opacity 0.5s ease 0.3s;
  transition: opacity 0.5s ease 0.3s
}

footer {
  position: relative
}

footer.dark-version .footer-theme2 .subscribe-block {
  border-color: #323232
}

footer.dark-version .border-b {
  border-color: #323232
}

footer.dark-version .dark-layout {
  background-color: transparent
}

footer.dark-version .sub-footer.darker-subfooter {
  background-color: #17181c
}

footer.dark-version .sub-footer.darker-subfooter p {
  color: #dddddd
}

footer.dark-version .footer-theme2 p {
  color: #dddddd
}

footer.dark-version .footer-theme2 .contact-details li {
  color: #dddddd
}

footer.dark-version .footer-theme2 .contact-details li a {
  color: #dddddd
}

footer.dark-version .footer-theme2 .footer-link a,
footer.dark-version .footer-theme2 .footer-link-b a {
  color: #dddddd
}

footer .footer-theme .footer-mobile-title {
  display: none
}


footer.footer-classic .upper-footer {
  background-color: var(--theme-color)
}


footer.footer-classic.footer-light .subscribe {
  border-right: none
}

footer.footer-classic.footer-light .subscribe h4 {
  color: #fff
}

footer.footer-classic.footer-light .subscribe p {
  color: #fff
}

footer.footer-classic .sub-footer {
  background-color: #494949
}

footer.footer-classic .sub-footer p {
  color: #fff;
  padding: 10px 0
}

footer.footer_digital .white-bg {
  background-color: white
}

footer.footer-5 {
  background-image: -webkit-gradient(linear, left top, right top, from(var(--theme-color)), to(var(--theme-color2)));
  background-image: linear-gradient(to right, var(--theme-color), var(--theme-color2))
}

footer.footer-5 .dark-layout {
  background-color: transparent
}

footer.footer-5 .sub-footer {
  background-color: transparent
}

footer.footer-5 .sub-footer>.container {
  border-top: 1px solid #9b9b9b
}

footer.footer-5 .sub-footer p {
  color: #fff
}

footer.footer-5 .footer-theme2 p {
  color: #fff
}

footer.footer-5 .footer-theme2 .contact-details li {
  color: #fff
}

footer.footer-5 .footer-theme2 .contact-details li a {
  color: #fff
}

footer.footer-5 .footer-theme2 .subscribe-block {
  border-left: 1px solid #9b9b9b;
  border-right: 1px solid #9b9b9b
}

footer .subscribe-form.rounded-input .form-control {
  border-radius: 25px 0 0 25px
}

footer .subscribe-form.rounded-input .btn-solid {
  border-radius: 0 25px 25px 0
}

footer.darken-background {
  background-color: #2d2a25;
  background-image: none !important
}


footer.dark-footer .sub-footer.dark-subfooter {
  background-color: #1d1d1d
}

footer.footer-expand p {
  font-size: 16px
}

footer.footer-expand .footer-theme .footer-title h4 {
  color: var(--theme-color2)
}

footer.footer-expand .footer-theme .sub-title li {
  font-size: 16px
}

footer.footer-expand .footer-theme .sub-title h4 {
  color: var(--theme-color2)
}

.footer-light .subscribe {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-right: 1px solid #ddd;
  text-align: left
}

.footer-light .subscribe p {
  line-height: 1
}

.footer-light .subscribe h4 {
  margin-bottom: 10px
}

.footer-light .subscribe-form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 100%;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end
}

.subscribe {
  text-align: center
}

.subscribe h4 {
  color: #222;
  text-transform: uppercase;
  font-weight: 700;
  display: block;
  margin-bottom: 15px
}

.subscribe h4 i {
  color: var(--theme-color);
  padding-right: 5px;
  font-size: 23px
}

.subscribe p {
  line-height: 1.6
}

.subscribe-form {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex
}

.subscribe-form .form-group {
  margin-bottom: 0
}

.subscribe-form .form-control {
  padding: 13px;
  width: 345px;
  border-radius: 0;
  border: 1px solid #eee
}

.subscribe-form .form-control.name-cls {
  width: 265px
}

.subscribe-form.classic-form .form-control {
  border-radius: 0;
  border: none;
  background-color: #f5f2f2;
  margin-left: 15px
}

.subscribe-form.classic-form .btn-solid,
.subscribe-form.classic-form .btn-outline {
  margin-bottom: 0 !important
}

.footer-social,
.social-white {
  margin-top: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center
}

.footer-social li,
.social-white li {
  display: inline-block !important;
  padding-right: 45px
}

.footer-social i,
.social-white i {
  font-size: 20px;
  color: #000;
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease
}

.footer-social i:hover,
.social-white i:hover {
  color: var(--theme-color) !important;
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease
}

.social-white li {
  padding-left: 22px;
  padding-right: 22px
}

.sub-footer {
  background-color: #fff
}

.sub-footer.dark-subfooter {
  background-color: #2e2726
}

.sub-footer.dark-subfooter p {
  color: #938d8c
}

.sub-footer.darker-subfooter {
  background-color: #25221e
}

.sub-footer.darker-subfooter p {
  color: #7f786d
}

.sub-footer.black-subfooter {
  background-color: #222
}

.sub-footer.black-subfooter p {
  color: #777
}

.sub-footer p {
  color: #777;
  margin-bottom: 0;
  padding: 20px 0
}

.sub-footer .payment-card-bottom {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end
}

.sub-footer .payment-card-bottom li {
  padding-left: 7px;
  padding-right: 7px;
  display: inline-block
}

/* ------------------------------------------------------------------------------------------ */


footer.footer-theme-color .sub-footer.dark-subfooter {
  background-color: #313e59
}

footer.footer-theme-color .sub-footer.dark-subfooter p {
  color: rgba(255, 255, 255, 0.9)
}

header {
  background-color: #fff
}

header.header-absolute {
  position: absolute;
  width: 100%;
  top: 62px
}

header.header-absolute .main-menu {
  background-color: #fff
}

header.header-absolute .main-menu .menu-left .navbar {
  padding: 20px 45px 20px 0
}

header.header-absolute .main-menu .brand-logo {
  padding-top: 20px;
  padding-bottom: 20px
}

header.header-absolute .main-menu .brand-logo img {
  width: 150px
}

header.header-absolute .pixelstrap>li>a {
  padding-top: 20px;
  padding-bottom: 20px
}

header.header-absolute .pixelstrap>li>a:hover,
header.header-absolute .pixelstrap>li>a:active,
header.header-absolute .pixelstrap>li>a:focus,
header.header-absolute .pixelstrap>li>a.highlighted {
  padding-top: 20px;
  padding-bottom: 20px
}

header.header-absolute .pixelstrap li .lable-nav {
  top: 0
}

header.header-absolute .onhover-div {
  padding-top: 20px;
  padding-bottom: 20px
}

header.header-black {
  background-color: #000
}

header.header-black .main-menu .menu-left .navbar {
  padding: 20px 45px 20px 0
}

header.header-black .main-menu .menu-left .navbar i {
  color: #fff
}

header.header-black .main-menu .brand-logo {
  padding-top: 20px;
  padding-bottom: 20px
}

header.header-black .pixelstrap>li>a {
  padding-top: 20px;
  padding-bottom: 20px;
  color: #e5e5e5
}

header.header-black .pixelstrap>li>a:hover,
header.header-black .pixelstrap>li>a:active,
header.header-black .pixelstrap>li>a:focus,
header.header-black .pixelstrap>li>a.highlighted {
  padding-top: 20px;
  padding-bottom: 20px;
  color: #e5e5e5
}

header.header-black .pixelstrap li .lable-nav {
  top: 0
}

header.header-black .onhover-div {
  padding-top: 20px;
  padding-bottom: 20px
}

header.header-black .onhover-div>div img {
  -webkit-filter: brightness(54);
  filter: brightness(54)
}

header.header-style .onhover-dropdown:before {
  opacity: 0
}

.color-dark .top-header {
  background-color: #2d2a25
}

.hover-unset {
  position: static !important
}

.sidebar-overlay {
  visibility: hidden;
  opacity: 0;
  background-color: #212331;
  position: fixed;
  z-index: 6;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease
}

.sidebar-overlay.show {
  opacity: 0.8;
  visibility: visible;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease
}

.onhover-dropdown {
  position: relative;
  font-size: 14px
}

.onhover-dropdown .onhover-show-div {
  top: 50px;
  position: absolute;
  z-index: 10;
  background-color: #fff;
  -webkit-transition: all linear 0.3s;
  transition: all linear 0.3s;
  min-width: 160px;
  text-align: left;
  -webkit-box-shadow: 0px 1px 2px 0px #cacaca;
  box-shadow: 0px 1px 2px 0px #cacaca;
  -webkit-transform: translateY(30px);
  transform: translateY(30px);
  opacity: 0;
  visibility: hidden;
  right: 0;
  padding: 15px 20px;
  border-radius: 0 !important
}

.onhover-dropdown .onhover-show-div.product-page-full {
  z-index: 99999
}

.onhover-dropdown:hover .onhover-show-div {
  opacity: 1;
  -webkit-transform: translateY(0px);
  transform: translateY(0px);
  visibility: visible
}

.onhover-div {
  position: relative;
  padding-top: 40px;
  padding-bottom: 40px;
  font-family: "Montserrat";
}

.onhover-div img,
.onhover-div i {
  cursor: pointer
}

.onhover-div .show-div {
  top: 103px;
  position: absolute;
  z-index: 15;
  background-color: #fff;
  -webkit-transition: all linear 0.3s;
  transition: all linear 0.3s;
  min-width: 236px;
  text-align: left;
  -webkit-transform: translateY(30px);
  transform: translateY(30px);
  opacity: 0;
  visibility: hidden;
  right: 0;
  padding: 0;
  border-radius: 0 !important;
  -webkit-box-shadow: 0 0 8px #ddd;
  box-shadow: 0 0 8px #ddd
}

.onhover-div:hover .show-div {
  opacity: 1;
  -webkit-transform: translateY(0px);
  transform: translateY(0px);
  visibility: visible
}

.search-overlay {
  height: 100%;
  width: 100%;
  display: none;
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  background-color: #fff
}

.search-overlay>div {
  height: 100%;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center
}

.search-overlay>div .overlay-content {
  width: 100%
}

.search-overlay>div .overlay-content .form-control:focus {
  -webkit-box-shadow: none;
  box-shadow: none
}

.search-overlay>div .overlay-content input {
  background-color: transparent;
  border: 0;
  border-bottom: 2px solid #777;
  border-radius: 0;
  padding: 20px 0
}

.search-overlay>div .overlay-content input::-webkit-input-placeholder {
  color: #777;
  font-size: 18px
}

.search-overlay>div .overlay-content input::-moz-placeholder {
  color: #777;
  font-size: 18px
}

.search-overlay>div .overlay-content input:-ms-input-placeholder {
  color: #777;
  font-size: 18px
}

.search-overlay>div .overlay-content input:-moz-placeholder {
  color: #777;
  font-size: 18px
}

.search-overlay>div .overlay-content button {
  position: absolute;
  top: 10px;
  background-color: transparent;
  border: 0;
  font-size: 24px;
  color: #777;
  right: 10px
}

.search-overlay>div .closebtn {
  position: fixed;
  top: 10px;
  right: 25px;
  color: #777;
  font-size: 40px;
  cursor: pointer
}

/* .top-header {
  background-color: #f8f8f8
} */

.top-header .header-contact {
  padding: 15px 0
}

.top-header .header-contact li {
  color: #999;
  font-size: 14px;
  padding-right: 25px
}

.top-header .header-contact li i {
  color: var(--theme-color);
  padding-left: 10px;
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg)
}

.top-header .header-dropdown li {
  cursor: pointer;
  color: #999;
  padding: 15px 25px;
  padding-right: 0
}

.top-header .header-dropdown li:first-child {
  padding-right: 0
}

.top-header .header-dropdown li a {
  text-transform: capitalize;
  color: #999
}

.top-header .header-dropdown li i {
  padding-right: 5px;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease
}

.top-header .header-dropdown li ul li {
  padding-top: 0;
  padding-bottom: 0
}

.top-header .header-dropdown li a i {
  color: #999;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease
}

.top-header .header-dropdown li a:hover i {
  color: var(--theme-color);
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease
}

.top-header .header-dropdown li:hover i {
  color: var(--theme-color);
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease
}

.top-header .header-dropdown .onhover-dropdown .onhover-show-div li {
  padding-left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 15px;
  padding-bottom: 5px
}

.top-header .header-dropdown .onhover-dropdown .onhover-show-div li:last-child {
  padding-bottom: 0
}

.top-header .header-dropdown .onhover-dropdown .onhover-show-div li a {
  color: #333
}

.top-header.top-header-dark {
  background-color: #222
}

.top-header.top-header-dark .header-contact li {
  color: rgba(255, 255, 255, 0.8)
}

.top-header.top-header-dark .header-contact li i {
  color: rgba(255, 255, 255, 0.8)
}

.top-header.top-header-dark .header-dropdown li {
  color: rgba(255, 255, 255, 0.8)
}

.top-header.top-header-dark .header-dropdown li a {
  color: rgba(255, 255, 255, 0.8)
}

.top-header.top-header-dark .header-dropdown li a i {
  color: rgba(255, 255, 255, 0.8)
}

.top-header.top-header-dark .header-dropdown li a:hover i {
  color: var(--theme-color)
}

.top-header.top-header-dark2 {
  background-color: #2d2a25
}

.top-header.top-header-dark2 .header-contact li {
  color: #cecece
}

.top-header.top-header-dark2 .header-contact li i {
  color: #cecece
}

.top-header.top-header-dark2 .header-dropdown li {
  color: #cecece
}

.top-header.top-header-dark2 .header-dropdown li a {
  color: #cecece
}

.top-header.top-header-dark2 .header-dropdown li a i {
  color: #cecece
}

.top-header.top-header-dark2 .header-dropdown li a:hover i {
  color: var(--theme-color)
}

.top-header.top-header-dark3 {
  background-color: #393230
}

.top-header.top-header-dark3 .header-contact li {
  color: #948e8c
}

.top-header.top-header-dark3 .header-contact li i {
  color: #948e8c
}

.top-header.top-header-dark3 .header-dropdown li {
  color: #948e8c
}

.top-header.top-header-dark3 .header-dropdown li a {
  color: #948e8c
}

.top-header.top-header-dark3 .header-dropdown li a i {
  color: #948e8c
}

.top-header.top-header-dark3 .header-dropdown li a:hover i {
  color: var(--theme-color)
}

.top-header.top-header-sm .header-contact {
  padding: 10px 0
}

.top-header.top-header-sm .header-dropdown li {
  padding: 10px 25px
}

.layout3-menu .main-menu .menu-left .navbar i {
  font-size: 22px
}

.absolute-logo {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  width: auto
}

.main-menu {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  z-index: 9
}

.main-menu.product-full-page {
  z-index: 9999
}

.main-menu .brand-logo {
  display: inline-block;
  padding-top: 35px;
  padding-bottom: 35px
}

.main-menu .menu-left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center
}

.main-menu .menu-left .navbar {
  display: inline-block;
  padding: 40px 45px 40px 0
}

.main-menu .menu-left .navbar i {
  font-size: 28px;
  color: #222;
  cursor: pointer
}

.main-menu .menu-left .navbar.white-navbar i {
  color: #fff
}

.main-menu .menu-left.around-border .navbar>a {
  padding: 0
}

.main-menu .menu-left.category-nav-right .navbar {
  padding-right: 0;
  padding-left: 45px
}

.main-menu .menu-left.category-nav-right .navbar>a {
  padding: 0
}

.main-menu .menu-left.category-nav-right .navbar i {
  color: #938d8c
}

.main-menu .menu-right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  float: right
}

.main-menu .menu-right .icon-nav {
  display: inline-block
}

.main-menu .menu-right .icon-nav li {
  padding-left: 20px
}

.main-menu .menu-right .icon-nav .delivery-area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center
}

.main-menu .menu-right .icon-nav .delivery-area>div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 12px;
  background-color: var(--theme-color);
  color: white;
  border-radius: 5px
}

.main-menu .menu-right .icon-nav .delivery-area i {
  margin-right: 5px;
  font-size: 16px
}

.main-menu .menu-right .icon-nav .delivery-area h6 {
  margin-bottom: 0;
  color: #fff;
  font-size: 16px
}

.main-menu .menu-right .icon-nav .onhover-div .show-div.shopping-cart {
  padding: 15px 15px;
  min-width: 270px;
  left: unset;
  right: 0
}

.main-menu .menu-right .icon-nav .onhover-div .show-div.shopping-cart li {
  position: relative;
  padding-bottom: 10px;
  width: 100%
}

.main-menu .menu-right .icon-nav .onhover-div .show-div.shopping-cart li:last-child {
  padding-bottom: 0
}

.main-menu .menu-right .icon-nav .onhover-div .show-div.shopping-cart li .media img {
  height: 90px
}

.main-menu .menu-right .icon-nav .onhover-div .show-div.shopping-cart li .media .media-body {
  -ms-flex-item-align: center;
  align-self: center
}

.main-menu .menu-right .icon-nav .onhover-div .show-div.shopping-cart li .media .media-body h4 {
  color: #333
}

.main-menu .menu-right .icon-nav .onhover-div .show-div.shopping-cart li .media .media-body h4 span {
  color: #777;
  font-size: 16px
}

.main-menu .menu-right .icon-nav .onhover-div .show-div.shopping-cart li .close-circle {
  position: absolute;
  top: 0;
  right: 0
}

.main-menu .menu-right .icon-nav .onhover-div .show-div.shopping-cart li .close-circle i {
  color: #a1a1a1;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease
}

.main-menu .menu-right .icon-nav .onhover-div .show-div.shopping-cart li .close-circle i:hover {
  color: #000;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease
}

.main-menu .menu-right .icon-nav .onhover-div .show-div.shopping-cart li .total {
  border-top: 1px solid #f1f5f4;
  border-bottom: 1px solid #f1f5f4;
  padding-top: 10px;
  padding-bottom: 10px
}

.main-menu .menu-right .icon-nav .onhover-div .show-div.shopping-cart li .total h5 {
  text-transform: capitalize;
  margin-bottom: 0;
  color: #999
}

.main-menu .menu-right .icon-nav .onhover-div .show-div.shopping-cart li .total h5 span {
  float: right
}

.main-menu .menu-right .icon-nav .onhover-div .show-div.shopping-cart li .buttons a {
  font-size: 16px;
  color: #333;
  text-transform: capitalize;
  font-weight: 700
}

.main-menu .menu-right .icon-nav .onhover-div .show-div.shopping-cart li .buttons a:hover {
  color: var(--theme-color)
}

.main-menu .menu-right .icon-nav .onhover-div .show-div.shopping-cart li .buttons .checkout {
  float: right
}

.main-menu .menu-right .icon-nav .onhover-div .show-div.setting {
  padding: 15px 20px 20px;
  min-width: 175px;
  right: 0;
  left: unset
}

.main-menu .menu-right .icon-nav .onhover-div .show-div.setting h6 {
  font-size: 16px;
  text-transform: capitalize;
  color: #333;
  font-weight: 700;
  margin-bottom: 0;
  margin-top: 5px
}

.main-menu .menu-right .icon-nav .onhover-div .show-div.setting h6:first-child {
  margin-top: 0
}

.main-menu .menu-right .icon-nav .onhover-div .show-div.setting ul li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-bottom: 0;
  padding-left: 17px;
  padding-top: 5px
}

.main-menu .menu-right .icon-nav .onhover-div .show-div.setting ul li a {
  color: #333;
  font-weight: 400;
  font-size: 16px;
  text-transform: capitalize
}

.main-menu .menu-right .icon-nav .onhover-div .show-div li {
  padding: 0
}

.main-menu .menu-right .icon-nav .onhover-div .show-div li .form-control {
  border-radius: 0
}

.main-menu .menu-right .icon-nav .onhover-div .show-div li .form-control:focus {
  border: 1px solid #ced4da;
  -webkit-box-shadow: none;
  box-shadow: none
}

.main-menu .menu-right .icon-nav .onhover-div .show-div li .search-btn {
  padding: 8px 12px;
  background-color: var(--theme-color);
  color: #fff
}

.main-menu .menu-right .icon-nav.white-icon .onhover-div img {
  -webkit-filter: brightness(5);
  filter: brightness(5)
}

.main-menu .menu-right .icon-nav.white-icon .onhover-div .show-div img {
  -webkit-filter: none;
  filter: none
}

.nav-cat li {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-left: 25px;
  padding-right: 25px
}

.nav-cat li .round-cat {
  height: 50px;
  width: 50px;
  border: 1px solid #ddd;
  padding: 12px;
  border-radius: 100%;
  margin-right: 10px
}

.nav-cat li a {
  color: #222;
  font-size: 16px;
  text-transform: uppercase
}

.sidebar-unset {
  overflow-y: unset !important
}

.header-style-1 .main-menu {
  z-index: 1
}

.header-style-1 .main-menu .brand-logo {
  padding-top: 5px;
  padding-bottom: 5px
}

.header-style-1 .main-menu .menu-left .navbar {
  padding: 20px 45px 20px 0
}

.header-style-1 .main-menu .menu-right .icon-nav .onhover-div .show-div {
  top: 63px
}

.header-style-1 .onhover-div {
  padding-top: 5px;
  padding-bottom: 5px
}

.header-style-1 .top-header {
  background-color: var(--theme-color)
}

.header-style-1 .top-header .header-contact {
  padding: 10px 0
}

.header-style-1 .top-header .header-dropdown li {
  padding: 10px 25px
}

.header-style-1 .top-header .header-dropdown li {
  color: #fff
}

.header-style-1 .top-header .header-dropdown li i {
  color: #fff
}

.header-style-1 .top-header .header-contact li {
  color: #fff
}

.header-style-1 .top-header .header-contact li i {
  color: #fff
}

.header-style-1 .pixelstrap>li>a,
.header-style-1 .pixelstrap>li a:hover,
.header-style-1 .pixelstrap>li a:active {
  padding-top: 15px;
  padding-bottom: 15px
}

.header-style-1 .pixelstrap>li ul li a {
  padding: 5px 35px
}

.header-style-1 .pixelstrap>li ul li a:hover,
.header-style-1 .pixelstrap>li ul li a:active {
  padding: 5px 35px
}

.header-style-1 .upper-menu.pixelstrap a {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 35px
}

.header-style-1 .navbar .nav-link {
  padding-right: 25px;
  color: #222222;
  font-size: 16px;
  font-weight: 400;
  line-height: 23px;
  text-decoration: none;
  text-transform: uppercase;
  padding-top: 15px;
  padding-bottom: 15px;
  letter-spacing: 0.07em
}

.header-style-1 .navbar .nav-item.active .nav-link {
  color: var(--theme-color)
}

.header-style-1.sticky .main-menu .menu-left .navbar {
  padding: 10px 45px 10px 0px
}

.top-header.top-header-theme {
  background-color: var(--theme-color)
}

.top-header.top-header-theme .header-dropdown li {
  color: #fff
}

.top-header.top-header-theme .header-dropdown li i,
.top-header.top-header-theme .header-dropdown li a {
  color: #fff
}

.top-header.top-header-theme .header-dropdown li i:hover i,
.top-header.top-header-theme .header-dropdown li a:hover i {
  color: #fff
}

.top-header.top-header-theme .header-contact li {
  color: #fff
}

.top-header.top-header-theme .header-contact li i {
  color: #fff
}


header.sticky {
  position: fixed;
  width: 100vw;
  top: 0;
  z-index: 9;
  -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.12);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.12);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease
}

header.sticky .top-header {
  display: none;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease
}

header.stickycls {
  position: fixed;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 99;
  -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.12);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.12);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease
}


.text-light-header .pixelstrap>li>a {
  color: #cecece !important
}

.text-light-header .onhover-div>div img {
  -webkit-filter: invert(0.9) !important;
  filter: invert(0.9) !important
}

.text-light-header .top-header .header-dropdown>li img {
  -webkit-filter: invert(0.9) !important;
  filter: invert(0.9) !important
}


.header-options {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: right;
  height: 100%
}

.header-options span {
  display: block;
  font-size: 16px;
  color: #2b2b2b;
  text-transform: uppercase
}

.header-options span i {
  margin-right: 6px
}

.zindex-up {
  z-index: 9 !important
}

.header-compact .main-menu .brand-logo {
  padding-top: 25px;
  padding-bottom: 20px
}

.header-compact .main-menu .brand-logo img {
  width: 150px
}

.header-compact .menu-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center
}

.header-compact .menu-row .delivery-area {
  margin-right: 25px
}

.header-compact .onhover-div {
  padding-top: 25px;
  padding-bottom: 25px
}

.header-compact .form_search {
  border-radius: 5px;
  height: 38px;
  -webkit-box-shadow: 0 0 0 1px #e2e2e2;
  box-shadow: 0 0 0 1px #e2e2e2;
  background-color: #f8f8f8
}

.header-compact .form_search input {
  height: 38px
}

.header-compact .form_search button {
  top: 1px;
  right: 1px;
  width: 40px;
  height: 35px
}

.header-compact .form_search button i {
  font-size: 16px
}

.header-compact .delivery-area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: rgba(32, 102, 100, 0.12);
  padding: 6px 17px;
  border-radius: 8px;
  border: 1px dashed var(--theme-color)
}

.header-compact .delivery-area svg {
  margin-right: 10px;
  width: 18px
}

.header-compact .delivery-area div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center
}

.header-compact .delivery-area div h5,
.header-compact .delivery-area div h6 {
  margin-bottom: 0
}

.header-compact .delivery-area div h6 {
  margin-right: 5px
}

.header-compact .pixelstrap a,
.header-compact .pixelstrap a:hover,
.header-compact .pixelstrap a:active {
  font-size: 14px
}

.header-compact .top-header .header-contact {
  padding: 10px 0
}

.header-compact .top-header .header-dropdown li {
  padding: 10px 25px
}

.header-compact .top-header .header-dropdown li:last-child {
  padding-right: 0
}

.header-compact .bottom-part {
  padding-bottom: 10px
}

.header-compact .main-nav-center .sm>li>a {
  padding-top: 10px;
  padding-bottom: 10px
}

.header-compact .onhover-div .show-div {
  top: 60px
}

header.sticky {
  position: fixed !important;
  width: 100vw;
  top: 0;
  z-index: 9;
  -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.12);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.12);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -webkit-animation: smoothScroll 0.05s forwards;
  animation: smoothScroll 0.05s forwards
}

header.sticky .top-header {
  display: none;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease
}


header.stickycls {
  position: fixed !important;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 99
}

@-webkit-keyframes smoothScroll {
  0% {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px)
  }

  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px)
  }
}

@keyframes smoothScroll {
  0% {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px)
  }

  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px)
  }
}

.left-arrow {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2
}

.right-arrow {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1
}

.snow-slider {
  position: relative
}

.snow-slider .slick-slide {
  position: relative
}

.home-slider .slick-prev,
.home-slider .slick-next,
.center-slider .slick-prev,
.center-slider .slick-next {
  opacity: 0;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  margin-top: -10px
}

.home-slider .slick-prev:before,
.home-slider .slick-next:before,
.center-slider .slick-prev:before,
.center-slider .slick-next:before {
  font: normal normal normal 14px/1 FontAwesome;
  opacity: 1;
  color: black;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center
}

.home-slider .slick-next,
.center-slider .slick-next {
  right: 1px
}

.home-slider .slick-next:before,
.center-slider .slick-next:before {
  content: "\f105";
  font-family: "Montserrat";
  font-size: 10px
}

.home-slider .slick-prev,
.center-slider .slick-prev {
  left: 1px;
  z-index: 1
}

.home-slider .slick-prev:before,
.center-slider .slick-prev:before {
  content: "\f104";
  font-size: 10px
}

.home-slider .home,
.center-slider .home {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 80vh
}

.home-slider .slider-details,
.center-slider .slider-details {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  top: 26%;
  right: 18%;
  text-align: center;
  padding: 55px;
  width: 510px;
  height: 510px
}

.home-slider .slider-details.blue-slide,
.center-slider .slider-details.blue-slide {
  background-color: #223b7d
}

.home-slider .slider-details .btn-white,
.center-slider .slider-details .btn-white {
  margin-top: 15px
}

.home-slider .slider-details h1,
.center-slider .slider-details h1 {
  color: #ffffff;
  font-weight: 700
}

.home-slider .slider-details h3,
.center-slider .slider-details h3 {
  color: #fff;
  position: relative;
  text-transform: uppercase;
  display: inline-block
}

.home-slider .slider-details h3:after,
.home-slider .slider-details h3:before,
.center-slider .slider-details h3:after,
.center-slider .slider-details h3:before {
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  background-color: white;
  border-radius: 100%;
  top: 8px
}

.home-slider .slider-details h3:after,
.center-slider .slider-details h3:after {
  left: -22px;
  top: 8px
}

.home-slider .slider-details h3:before,
.center-slider .slider-details h3:before {
  top: 8px;
  right: -22px
}

.home-slider .slider-details h2,
.center-slider .slider-details h2 {
  font-weight: 400;
  margin-bottom: 0;
  font-size: 45px
}

.home-slider .slider-details h4,
.center-slider .slider-details h4 {
  font-size: 24px
}

.home-slider .slider-details h2,
.home-slider .slider-details h3,
.home-slider .slider-details h4,
.center-slider .slider-details h2,
.center-slider .slider-details h3,
.center-slider .slider-details h4 {
  color: white
}

.home-slider .slider-contain,
.center-slider .slider-contain {
  width: 100%;
  height: 75vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center
}

.home-slider .slider-contain h1,
.center-slider .slider-contain h1 {
  margin-bottom: 0;
  margin-top: 10px;
  color: #222;
  font-size: 60px;
}

.home-slider .slider-contain h4,
.center-slider .slider-contain h4 {
  font-weight: 700;
  letter-spacing: 0.4em;
  color: #777;
  margin-bottom: 0
}

.home-slider .slider-contain .btn-solid,
.home-slider .slider-contain .btn-outline,
.center-slider .slider-contain .btn-solid,
.center-slider .slider-contain .btn-outline {
  margin-top: 20px
}

.home-slider .slider-contain.product-detail p,
.center-slider .slider-contain.product-detail p {
  max-width: 50%;
  line-height: 30px;
  font-size: 16px;
  color: #777777
}

.home-slider .slider-contain.product-detail h3,
.center-slider .slider-contain.product-detail h3 {
  margin-top: 14px;
  margin-bottom: 0;
  font-weight: 600;
  color: #777777
}

.home-slider .slider-contain.product-detail h3 del,
.center-slider .slider-contain.product-detail h3 del {
  margin-left: 10px
}

.home-slider .thumbnail-porduct,
.center-slider .thumbnail-porduct {
  position: absolute;
  bottom: 40px;
  left: 0;
  width: 100vw
}

.home-slider .thumbnail-porduct img,
.center-slider .thumbnail-porduct img {
  margin: 0 auto;
  border: 1px solid transparent;
  opacity: 0.5
}

.home-slider .thumbnail-porduct .slick-current img,
.center-slider .thumbnail-porduct .slick-current img {
  border: 1px solid #dddddd;
  opacity: 1
}

.home-slider .p-left .slider-contain,
.center-slider .p-left .slider-contain {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start
}

.home-slider .p-right .slider-contain,
.center-slider .p-right .slider-contain {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end
}

.home-slider .p-center .slider-contain,
.center-slider .p-center .slider-contain {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center
}

.home-slider .p-bottom .slider-contain,
.center-slider .p-bottom .slider-contain {
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  padding-bottom: calc(20px + (50 - 20) * ((100vw - 320px) / (1920 - 320)))
}

.home-slider .p-bottom .slider-contain>div,
.center-slider .p-bottom .slider-contain>div {
  padding: 25px 30px;
  background-color: #fff
}

.home-slider .text-center .slider-contain p,
.center-slider .text-center .slider-contain p {
  margin: 0 auto
}

.home-slider .home-decor .decor,
.center-slider .home-decor .decor {
  position: absolute
}

.home-slider .home-decor .decor img,
.center-slider .home-decor .decor img {
  -webkit-box-shadow: 0px 38px 62px -40px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 38px 62px -40px rgba(0, 0, 0, 0.2)
}

.home-slider .home-decor .decor span,
.center-slider .home-decor .decor span {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 80px;
  font-size: 16px;
  line-height: 1.2;
  background-color: black;
  border-radius: 100%;
  color: white;
  font-weight: 600;
  padding: 10px;
  height: 80px;
  text-align: center;
  -webkit-box-shadow: 0px 38px 62px -40px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 38px 62px -40px rgba(0, 0, 0, 0.2)
}

.home-slider .home-decor .decor.decor-1,
.center-slider .home-decor .decor.decor-1 {
  top: 26%;
  right: 13%
}

.home-slider .home-decor .decor.decor-1 span,
.center-slider .home-decor .decor.decor-1 span {
  -webkit-animation: movebounce 3.2s linear infinite;
  animation: movebounce 3.2s linear infinite
}

.home-slider .home-decor .decor.decor-2,
.center-slider .home-decor .decor.decor-2 {
  top: 35%;
  right: 37%
}

.home-slider .home-decor .decor.decor-2 span,
.center-slider .home-decor .decor.decor-2 span {
  -webkit-animation: movebounce 2s linear infinite;
  animation: movebounce 2s linear infinite
}

.home-slider .home-decor .decor.decor-3,
.center-slider .home-decor .decor.decor-3 {
  top: 52%;
  right: 12%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center
}

.home-slider .home-decor .decor.decor-3 img,
.center-slider .home-decor .decor.decor-3 img {
  width: 40%;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1)
}

.home-slider .home-decor .decor.decor-3 span,
.center-slider .home-decor .decor.decor-3 span {
  font-size: 17px;
  background-color: transparent;
  color: #222222
}

.home-slider .home-decor .decor.decor-4,
.center-slider .home-decor .decor.decor-4 {
  top: 32%;
  right: 35%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center
}

.home-slider .home-decor .decor.decor-4 img,
.center-slider .home-decor .decor.decor-4 img {
  width: 40%
}

.home-slider .home-decor .decor.decor-4 span,
.center-slider .home-decor .decor.decor-4 span {
  font-size: 17px;
  background-color: transparent;
  color: #222222
}

.home-slider:hover .slick-prev,
.home-slider:hover .slick-next,
.center-slider:hover .slick-prev,
.center-slider:hover .slick-next {
  opacity: 1;
  -webkit-transform: scale(2.5);
  transform: scale(2.5);
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease
}

.home-slider:hover .slick-prev:before,
.home-slider:hover .slick-next:before,
.center-slider:hover .slick-prev:before,
.center-slider:hover .slick-next:before {
  opacity: 1
}

.home-slider:hover .slick-next,
.center-slider:hover .slick-next {
  right: 100px
}

.home-slider:hover .slick-prev,
.center-slider:hover .slick-prev {
  left: 100px
}

.home-slider-container .slider-contain {
  padding: 0 100px
}

.home-slider-container.home-fashion .slider-contain {
  padding: 0 calc(20px + (100 - 20) * ((100vw - 320px) / (1920 - 320)))
}

.center-slider {
  border-left: 1px solid #f1f5f4;
  border-right: 1px solid #f1f5f4
}

.home-slider.text-white .slider-contain h1,
.home-slider.text-white .slider-contain h4 {
  color: #fff
}

.gym-slider .home-slider .home {
  height: 85vh
}

.gym-slider .home-slider .home .slider-contain {
  height: 85vh
}

.gym-slider .home-slider .home .slider-contain h4,
.gym-slider .home-slider .home .slider-contain h1 {
  color: #fff
}

.metro-slider .home-slider .home {
  background-position-y: 70%
}

.metro-slider .home-slider .home .slider-contain {
  margin-top: 75px
}

.metro-slider .home-slider .home .slider-contain h4,
.metro-slider .home-slider .home .slider-contain h1 {
  color: #000
}

.small-slider .home-slider .home {
  height: 55vh
}

.small-slider .home-slider .home .slider-contain {
  height: 55vh
}

.small-slider .home-slider .home .slider-contain h4,
.small-slider .home-slider .home .slider-contain h1 {
  color: #000
}

.height-100 .home-slider .home {
  height: 100vh
}

.height-100 .home-slider .slider-contain {
  height: calc(99vh + 80px)
}

.height-85 .home-slider .home {
  height: 85vh
}

.height-85 .home-slider .slider-contain {
  height: 85vh
}

.height-85.content_top .slider-contain {
  padding-top: 150px;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start
}

.height-85.content_top .slider-contain h1 {
  font-size: 40px;
  letter-spacing: 0.05em
}

.height-85.content_top .slider-contain .btn-solid {
  padding: 9px 18px;
  margin-top: 10px
}

.height-85.content_top .slider-contain h4 {
  letter-spacing: 0.08em
}

.layout-7.home-slider .home {
  height: 60vh
}

.layout-7.home-slider .home .slider-contain {
  height: 60vh
}

.height-65 .home {
  height: 65vh !important
}

.height-65 .home .slider-contain {
  height: 65vh !important
}

.height-80 .home {
  height: 80vh
}

.height-80 .home .slider-contain {
  height: 80vh
}

.banner-slider .height-banner {
  height: 100%
}

.banner-slider .home {
  height: 81vh
}

.banner-slider .home .slider-contain {
  height: 81vh
}

.banner-slider .home-banner>div img {
  width: 100%
}

.banner-slider .home-banner>div:last-child img {
  margin-top: 30px
}

.absolute-banner {
  margin-top: -105px
}

.absolute-banner .absolute-bg {
  background-color: white;
  position: relative;
  padding: 25px;
  -webkit-box-shadow: 0 0 8px 0 #ddd;
  box-shadow: 0 0 8px 0 #ddd
}

.absolute-banner.mt-banner {
  margin-top: -55px
}

.home-fashion {
  margin-top: 25px
}

.home-fashion .home-slider .slider-contain h3 {
  margin-bottom: 0;
  margin-top: 10px;
  font-size: calc(16px + (24 - 16) * ((100vw - 320px) / (1920 - 320)))
}

.slide-center .slick-list {
  margin-left: -20px;
  margin-right: -20px
}

.slide-center .slick-list .slick-slide>div {
  padding-left: 20px;
  padding-right: 20px
}

.home-form {
  margin-top: -40px;
  z-index: 1;
  position: relative
}

.home-form .table-form {
  -webkit-box-shadow: 0px -1px 30px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0px -1px 30px 0 rgba(0, 0, 0, 0.15);
  margin-top: 0;
  border-radius: 10px;
  padding: 20px 10px;
  background-color: white
}

.home-form .table-form .form-control {
  border: none;
  background-color: #f9f9f9
}

.home-form .table-form .form-group {
  margin-bottom: 0
}

.home-form .table-form .btn {
  font-size: 14px;
  width: 100%;
  border-radius: 5px;
  padding: 9px;
  line-height: 1;
  margin-top: 0
}

.px-padding {
  padding: 0 calc(0px + (50 - 0) * ((100vw - 320px) / (1920 - 320)))
}

.slick-instagram {
  margin-bottom: -5px
}

.insta-title {
  position: absolute;
  height: 45px;
  width: 277px;
  background-color: #fff;
  z-index: 1;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center
}

.insta-title h4 {
  margin-bottom: 0;
  text-transform: capitalize;
  font-weight: 600
}

.insta-title h4 span {
  color: var(--theme-color)
}

.instagram-box {
  position: relative;
  background-color: var(--theme-color);
  overflow: hidden
}

.instagram-box img {
  width: 100%
}

.instagram-box .overlay {
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: var(--theme-color);
  top: 0;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-transform: scale(3) rotate(90deg);
  transform: scale(3) rotate(90deg)
}

.instagram-box .overlay i {
  font-size: 60px;
  color: #fff
}

.instagram-box:hover .overlay {
  opacity: 0.5;
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
  -webkit-transform: scale(1) rotate(0deg);
  transform: scale(1) rotate(0deg)
}

.insta-style .slick-current .instagram-box .overlay {
  opacity: 0.4;
  -webkit-transform: scale(1) rotate(0deg);
  transform: scale(1) rotate(0deg)
}

.loader-wrapper {
  height: 100vh;
  width: 100vw;
  background-color: #fff;
  display: block;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: fixed;
  z-index: 9999;
  top: 0
}

.loader-wrapper .loader {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 50px;
  height: 50px;
  background-color: var(--theme-color);
  border-radius: 50%
}

.loader-wrapper .loader:after {
  content: "";
  position: absolute;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  border: 0 solid white;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-animation: loading 1000ms ease-out forwards infinite;
  animation: loading 1000ms ease-out forwards infinite;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease
}

.loader-wrapper.gradient-loader .loader {
  background: -webkit-gradient(linear, left top, left bottom, from(var(--theme-color)), color-stop(99%, var(--theme-color2)));
  background: linear-gradient(180deg, var(--theme-color) 0%, var(--theme-color2) 99%);
  background-color: var(--theme-color)
}

.loader-wrapper.green-loader .loader {
  background-image: -webkit-gradient(linear, left top, right top, from(var(--theme-color)), to(var(--theme-color2)));
  background-image: linear-gradient(to right, var(--theme-color), var(--theme-color2));
  background-color: var(--theme-color)
}

.sm,
.sm ul,
.sm li {
  display: block;
  list-style: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
  line-height: normal;
  direction: ltr;
  text-align: left
}

.sm {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  z-index: 1
}

.sm ul {
  display: none
}

.sm li {
  position: relative
}

.sm>li>h1 {
  margin: 0;
  padding: 0
}

.sm>li>h2 {
  margin: 0;
  padding: 0
}

.sm>li>h3 {
  margin: 0;
  padding: 0
}

.sm>li>h4 {
  margin: 0;
  padding: 0
}

.sm>li>h5 {
  margin: 0;
  padding: 0
}

.sm>li>h6 {
  margin: 0;
  padding: 0
}

.sm a {
  position: relative;
  display: block
}

.sm a.disabled {
  cursor: default
}

.sm * {
  -webkit-box-sizing: inherit;
  box-sizing: inherit
}

.sm *::before {
  -webkit-box-sizing: inherit;
  box-sizing: inherit
}

.sm *::after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit
}

.sm::after {
  content: "";
  display: block;
  height: 0;
  font: 0/0 serif;
  clear: both;
  overflow: hidden
}

.toggle-nav {
  position: relative;
  padding-top: 40px;
  padding-bottom: 40px;
  cursor: pointer
}

.toggle-nav i {
  font-size: 24px;
  color: var(--theme-color);
  opacity: 0.6
}

.mega-menu-banner:hover:before {
  display: none
}

.new-tag {
  padding-left: 3px;
  color: var(--theme-color);
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700
}

.up-text {
  position: relative
}

.up-text span {
  position: absolute;
  top: -5px;
  right: -10px;
  color: var(--theme-color);
  font-size: 12px;
  font-weight: 700
}

.pixelstrap.sm-vertical a .sub-arrow {
  margin-top: -22px;
  right: 7px
}

.pixelstrap ul {
  border-radius: 0
}

.pixelstrap.light-font-menu li>a {
  color: #393230
}

.pixelstrap a,
.pixelstrap a:hover,
.pixelstrap a:active {
  padding-right: 45px;
  color: #222;
  /* font-size: 14px; */
  font-weight: 600;
  line-height: 23px;
  text-decoration: none;
  text-transform: uppercase;
  padding-top: 42px;
  padding-bottom: 42px;
  /* letter-spacing: 0.07em */
}

.pixelstrap a:hover {
  color: var(--theme-color)
}

.pixelstrap a.disabled {
  color: #ffd8d4
}

.pixelstrap a .sub-arrow {
  position: absolute;
  top: 50%;
  margin-top: -17px;
  left: auto;
  right: 15px;
  width: 34px;
  height: 34px;
  overflow: hidden;
  font: bold 16px/34px monospace !important;
  text-align: center;
  text-shadow: none;
  border-radius: 4px
}

.pixelstrap>li:last-child>a,
.pixelstrap>li:last-child>*:not(ul) a,
.pixelstrap>li:last-child>ul,
.pixelstrap>li:last-child>ul>li:last-child>a,
.pixelstrap>li:last-child>ul>li:last-child>*:not(ul) a,
.pixelstrap>li:last-child>ul>li:last-child>ul,
.pixelstrap>li:last-child>ul>li:last-child>ul>li:last-child>a,
.pixelstrap>li:last-child>ul>li:last-child>ul>li:last-child>*:not(ul) a,
.pixelstrap>li:last-child>ul>li:last-child>ul>li:last-child>ul,
.pixelstrap>li:last-child>ul>li:last-child>ul>li:last-child>ul>li:last-child>a,
.pixelstrap>li:last-child>ul>li:last-child>ul>li:last-child>ul>li:last-child>*:not(ul) a,
.pixelstrap>li:last-child>ul>li:last-child>ul>li:last-child>ul>li:last-child>ul,
.pixelstrap>li:last-child>ul>li:last-child>ul>li:last-child>ul>li:last-child>ul>li:last-child>a,
.pixelstrap>li:last-child>ul>li:last-child>ul>li:last-child>ul>li:last-child>ul>li:last-child>*:not(ul) a,
.pixelstrap>li:last-child>ul>li:last-child>ul>li:last-child>ul>li:last-child>ul>li:last-child>ul {
  border-radius: 0 0 0 0
}

.pixelstrap>li:last-child>a.highlighted,
.pixelstrap>li:last-child>*:not(ul) a.highlighted,
.pixelstrap>li:last-child>ul>li:last-child>a.highlighted,
.pixelstrap>li:last-child>ul>li:last-child>*:not(ul) a.highlighted,
.pixelstrap>li:last-child>ul>li:last-child>ul>li:last-child>a.highlighted,
.pixelstrap>li:last-child>ul>li:last-child>ul>li:last-child>*:not(ul) a.highlighted,
.pixelstrap>li:last-child>ul>li:last-child>ul>li:last-child>ul>li:last-child>a.highlighted,
.pixelstrap>li:last-child>ul>li:last-child>ul>li:last-child>ul>li:last-child>*:not(ul) a.highlighted,
.pixelstrap>li:last-child>ul>li:last-child>ul>li:last-child>ul>li:last-child>ul>li:last-child>a.highlighted,
.pixelstrap>li:last-child>ul>li:last-child>ul>li:last-child>ul>li:last-child>ul>li:last-child>*:not(ul) a.highlighted {
  border-radius: 0
}

.pixelstrap ul {
  background: #fff
}

.pixelstrap ul ul {
  background: rgba(255, 85, 69, 0.04)
}

.pixelstrap ul a.disabled {
  color: #b3b3b3
}

.pixelstrap ul ul a,
.pixelstrap ul ul a:hover,
.pixelstrap ul ul a:focus,
.pixelstrap ul ul a:active {
  border-left: 16px solid transparent
}

.pixelstrap ul ul ul a,
.pixelstrap ul ul ul a:hover,
.pixelstrap ul ul ul a:focus,
.pixelstrap ul ul ul a:active {
  border-left: 24px solid transparent
}

.pixelstrap ul ul ul ul a,
.pixelstrap ul ul ul ul a:hover,
.pixelstrap ul ul ul ul a:focus,
.pixelstrap ul ul ul ul a:active {
  border-left: 32px solid transparent
}

.pixelstrap ul ul ul ul ul a,
.pixelstrap ul ul ul ul ul a:hover,
.pixelstrap ul ul ul ul ul a:focus,
.pixelstrap ul ul ul ul ul a:active {
  border-left: 40px solid transparent
}

.pixelstrap ul li {
  border-top: 1px solid rgba(0, 0, 0, 0.05)
}

.pixelstrap ul li:first-child {
  border-top: 0
}

.pixelstrap .mega {
  position: unset !important
}

.pixelstrap .mega .full-mega-menu.center-mega-menu {
  margin-left: 0 !important
}

.pixelstrap .mega ul {
  margin-left: 0 !important;
  max-width: -webkit-max-content;
  max-width: -moz-max-content;
  max-width: max-content
}

.pixelstrap .icon-trend {
  color: var(--theme-color)
}

.pixelstrap .full-mega-menu,
.pixelstrap .clothing-menu {
  max-width: inherit !important;
  padding: 30px 0 15px
}

.pixelstrap .full-mega-menu .mega-img,
.pixelstrap .clothing-menu .mega-img {
  margin-top: 15px;
  width: 200%
}

.pixelstrap .full-mega-menu a,
.pixelstrap .clothing-menu a {
  padding: 0;
  position: relative
}

.pixelstrap .full-mega-menu a h6,
.pixelstrap .clothing-menu a h6 {
  font-size: 14px;
  text-align: center;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  font-weight: 400;
  text-transform: capitalize;
  color: #222222
}

.pixelstrap .full-mega-menu a img,
.pixelstrap .clothing-menu a img {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0 auto
}

.pixelstrap .full-mega-menu a:hover,
.pixelstrap .clothing-menu a:hover {
  padding: 0
}

.pixelstrap .full-mega-menu a:hover:before,
.pixelstrap .clothing-menu a:hover:before {
  width: 50px;
  height: 2px;
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease
}

.pixelstrap .full-mega-menu a:hover h6,
.pixelstrap .clothing-menu a:hover h6 {
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease
}

.pixelstrap .link-section h5 {
  font-weight: 700;
  text-transform: capitalize;
  font-size: 14px
}

.pixelstrap .link-section ul {
  position: relative;
  display: block;
  padding: 0
}

.pixelstrap .link-section ul li a {
  padding-top: 3px;
  padding-bottom: 3px;
  font-size: 14px;
  padding-left: 0
}

.pixelstrap .full-mega-menu {
  width: 100% !important;
  padding: 30px !important;
  border-radius: 0
}

.pixelstrap .full-mega-menu .mega-box .link-section ul li a:hover,
.pixelstrap .full-mega-menu .mega-box .link-section ul li a:focus {
  padding-top: 3px;
  padding-bottom: 3px
}

.pixelstrap .full-mega-menu .img-section img {
  margin-top: 15px
}

.pixelstrap .clothing-menu {
  width: 815px !important;
  padding: 15px 30px 25px !important;
  background-color: #fff
}

.pixelstrap .clothing-menu li img {
  padding: 20px 0 10px 0
}

.pixelstrap .clothing-menu .link-section h5 {
  margin-top: 15px
}

.pixelstrap .clothing-menu .link-section ul {
  background: none
}

.pixelstrap .mega-menu ul {
  -webkit-box-shadow: none;
  box-shadow: none
}

.pixelstrap li a {
  position: relative
}

.pixelstrap li .lable-nav {
  position: absolute;
  background-color: var(--theme-color);
  padding: 2px 7px;
  color: #fff;
  top: 20px;
  text-transform: uppercase;
  font-size: 9px;
  left: 28px;
  line-height: 1.3
}

.pixelstrap li .lable-nav:before {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid var(--theme-color);
  top: 15px;
  left: 22px
}

.pixelstrap li .lable-nav.lable-red {
  background-color: #ff4c3b
}

.pixelstrap li .lable-nav.lable-red:before {
  border-top: 5px solid #ff4c3b
}

.sm-vertical {
  background-color: #fff
}

.sidenav {
  height: 100vh;
  width: 300px;
  position: fixed;
  z-index: 9999;
  top: 0;
  left: -300px;
  background-color: #fff;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease
}

.sidenav .sidebar-back {
  padding: 20px;
  font-size: 18px;
  color: #222;
  font-weight: 700;
  text-transform: uppercase;
  border-bottom: 1px solid #efefef;
  margin-bottom: 20px;
  cursor: pointer
}

.sidenav nav {
  background-color: #fff;
  z-index: 9;
  position: relative;
  height: 100vh;
  top: 0;
  overflow-y: auto
}

.sidenav.open-side {
  left: 0;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease
}

.sidenav.open-side .sidebar-overlay {
  opacity: 0.8;
  visibility: visible
}

.sidenav .sidebar-overlay {
  visibility: hidden;
  opacity: 0;
  background-color: #212331;
  position: fixed;
  z-index: 6;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  -webkit-transition: all 1s;
  transition: all 1s
}

.sidenav .closebtn {
  position: absolute;
  top: 0;
  font-size: 36px;
  margin-left: 50px;
  margin-top: 50px;
  color: #938d8c
}

.sidenav .pixelstrap li a {
  padding: 10px 50px 20px 40px
}

.sidenav .pixelstrap li ul li a {
  padding: 5px 35px
}

.sidenav .pixelstrap li ul li a:active,
.sidenav .pixelstrap li ul li a:focus,
.sidenav .pixelstrap li ul li a.highlighted {
  padding: 5px 35px
}

.sidenav .pixelstrap li .clothing-menu li a {
  padding: 5px 0 !important
}

.sidenav .pixelstrap li .clothing-menu li a:focus {
  font-size: 14px
}

.sidenav .pixelstrap li .clothing-menu li a:hover {
  font-size: 14px;
  padding: 5px 0
}

.sidenav .pixelstrap li .clothing-menu li a:focus,
.sidenav .pixelstrap li .clothing-menu li a:hover {
  padding: 5px 0 !important
}

.mega-box .link-section .demo ul li a {
  position: relative
}

.mega-box .link-section .demo ul li a:hover,
.mega-box .link-section .demo ul li a:focus {
  padding-top: 3px;
  padding-bottom: 3px
}

.mega-box .link-section .demo .new {
  position: absolute;
  top: -8px;
  right: 0px;
  color: white;
  background-color: var(--theme-color);
  padding: 2px 7px;
  text-transform: uppercase;
  font-size: 9px;
  line-height: 1.3
}

.mega-box .link-section .demo .title {
  border-bottom: 2px solid red !important
}


.ribbon-1 {
  width: 24px;
  height: 45px;
  background: var(--theme-color);
  top: -6px;
  left: 25px;
  position: absolute;
  -webkit-box-shadow: 4px 5px 15px 2px rgba(90, 90, 90, 0.35);
  box-shadow: 4px 5px 15px 2px rgba(90, 90, 90, 0.35)
}

.ribbon-1:before {
  height: 0;
  width: 0;
  border-bottom: 6px solid var(--theme-color);
  border-right: 6px solid transparent;
  right: -6px;
  content: "";
  position: absolute
}

.ribbon-1:after {
  height: 0;
  width: 0;
  border-left: 12px solid var(--theme-color);
  border-right: 12px solid var(--theme-color);
  border-bottom: 12px solid transparent;
  bottom: -11px;
  left: 0;
  content: "";
  position: absolute
}

.ribbon-1 span {
  display: block;
  color: #fff;
  height: 12px;
  text-transform: lowercase
}

.theme-modal .modal-dialog .modal-content .modal-body {
  background-image: linear-gradient(135deg, var(--theme-color) 5.77%, #fff 5.77%, #fff 25%, #111 25%, #111 30.77%, #fff 30.77%, #fff 50%, var(--theme-color) 50%, var(--theme-color) 55.77%, #fff 55.77%, #fff 75%, #111 75%, #111 80.77%, #fff 80.77%, #fff 100%);
  background-size: 36.77px 36.77px;
  background-color: white
}

.theme-modal .modal-dialog .modal-content .modal-body .modal-bg {
  background-color: #fff;
  padding: 45px
}

.theme-modal .modal-dialog .modal-content .modal-body .modal-bg .btn-close {
  padding-right: 10px;
  padding-top: 5px;
  position: absolute;
  right: 17px;
  top: 0
}

.theme-modal .modal-dialog .modal-content .modal-body .modal-bg .btn-close span {
  color: #000;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1
}

.theme-modal .modal-dialog .modal-content .modal-body .modal-bg .age-content h2 {
  border-bottom: 1px dashed #777;
  text-align: center;
  margin-bottom: 30px;
  padding-bottom: 30px;
  font-weight: 700
}

.theme-modal .modal-dialog .modal-content .modal-body .modal-bg .age-content h4 {
  margin-bottom: 30px;
  text-align: center;
  color: #272727;
  font-size: 18px;
  font-weight: 400;
  text-transform: capitalize
}

.theme-modal .modal-dialog .modal-content .modal-body .modal-bg .age-content form {
  margin: 0 auto;
  text-align: center
}

.theme-modal .modal-dialog .modal-content .modal-body .modal-bg .age-content form>div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 10px
}

.theme-modal .modal-dialog .modal-content .modal-body .modal-bg .age-content form .form-control {
  padding-top: 15px;
  padding-bottom: 15px;
  text-align: center;
  font-size: 12px;
  height: 50px;
  border-radius: 0;
  width: 100%;
  letter-spacing: 0.05em
}

.theme-modal .modal-dialog .modal-content .modal-body .modal-bg .offer-content img {
  margin-bottom: 40px
}

.theme-modal .modal-dialog .modal-content .modal-body .modal-bg .offer-content h2 {
  margin-bottom: 30px;
  text-align: center;
  color: #222
}

.theme-modal .modal-dialog .modal-content .modal-body .modal-bg .offer-content form .form-group {
  margin: 0 auto;
  text-align: center
}

.theme-modal .modal-dialog .modal-content .modal-body .modal-bg .offer-content form .form-group .form-control {
  padding-top: 15px;
  padding-bottom: 15px;
  text-align: center;
  font-size: 12px;
  max-width: 640px;
  left: 0;
  right: 0;
  margin: 0 auto;
  letter-spacing: 0.05em
}

.theme-modal .modal-dialog .modal-content .modal-body .modal-bg .offer-content form .form-group .btn-solid,
.theme-modal .modal-dialog .modal-content .modal-body .modal-bg .offer-content form .form-group .btn-outline {
  margin-top: 10px;
  text-align: center
}

.theme-modal .modal-dialog .quick-view-modal button.btn-close,
.theme-modal .modal-dialog .demo_modal button.btn-close {
  position: absolute;
  z-index: 1;
  right: 10px;
  top: 0;
  font-size: 30px;
  padding: 0
}

.theme-modal .modal-dialog .quick-view-modal .view-detail,
.theme-modal .modal-dialog .demo_modal .view-detail {
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase
}

.theme-modal.demo-modal .modal-dialog .modal-content .modal-body {
  height: 75vh;
  overflow-y: scroll;
  padding: 25px;
  background-image: none !important
}

.theme-modal.demo-modal .modal-dialog .modal-content .modal-body::-webkit-scrollbar {
  width: 5px
}

.theme-modal.demo-modal .modal-dialog .modal-content .modal-body::-webkit-scrollbar-track {
  background: #f1f1f1
}

.theme-modal.demo-modal .modal-dialog .modal-content .modal-body::-webkit-scrollbar-thumb {
  background: var(--theme-color)
}

.theme-modal.demo-modal .modal-dialog .modal-content .modal-body::-webkit-scrollbar-thumb:hover {
  background: #555
}

.theme-modal.cart-modal .modal-dialog .modal-content .modal-body .modal-bg {
  padding: 25px
}

.theme-modal.cart-modal .modal-dialog .modal-content .modal-body .modal-bg.addtocart .media a img {
  max-width: 150px
}

.theme-modal.cart-modal .modal-dialog .modal-content .modal-body .modal-bg.addtocart .media .media-body {
  padding: 0 15px
}

.theme-modal.cart-modal .modal-dialog .modal-content .modal-body .modal-bg.addtocart .media .media-body a h6 {
  font-size: 16px
}

.theme-modal.cart-modal .modal-dialog .modal-content .modal-body .modal-bg.addtocart .media .media-body a h6 i {
  margin-right: 8px;
  color: #fff;
  background: #19a340;
  font-size: 14px;
  width: 18px;
  height: 18px;
  line-height: 18px;
  border-radius: 50%
}

.theme-modal.cart-modal .modal-dialog .modal-content .modal-body .modal-bg.addtocart .media .media-body .buttons a {
  display: inline-block;
  font-size: 16px;
  padding: 6px 10px;
  margin: 0 6px
}

.theme-modal.cart-modal .modal-dialog .modal-content .modal-body .modal-bg.addtocart #upsell_product .product-box {
  padding-left: 10px;
  padding-right: 10px
}

.theme-modal.cart-modal .modal-dialog .modal-content .modal-body .modal-bg.addtocart #upsell_product .product-box .product-detail {
  text-align: center
}

.theme-modal.cart-modal .modal-dialog .modal-content .modal-body .modal-bg.addtocart #upsell_product .product-box .product-detail h6 {
  font-weight: 700;
  text-transform: capitalize
}

.theme-modal.cart-modal .modal-dialog .modal-content .modal-body .modal-bg.addtocart #upsell_product .product-box .product-detail h6 a {
  color: var(--theme-color)
}

.theme-modal.cart-modal .modal-dialog .modal-content .modal-body .upsell_payment {
  padding-top: 15px
}

.theme-modal.cart-modal .modal-dialog .modal-content .modal-body .product-upsell {
  margin: 20px 0 10px
}

.theme-modal.cart-modal .modal-dialog .modal-content .modal-body .product-upsell h4 {
  font-weight: 600
}

.theme-modal.exit-modal .media .media-body h4 {
  font-size: 20px;
  line-height: 1.2;
  color: var(--theme-color);
  font-weight: 500
}

.theme-modal.exit-modal .media .media-body h5 {
  font-size: 18px;
  color: #777;
  margin-bottom: 0
}

#quick-view .modal-dialog .modal-content .modal-body {
  background-image: none
}

.modal .btn-close {
  padding-right: 10px;
  padding-top: 5px;
  position: absolute;
  right: 17px;
  top: 0
}

.modal .btn-close span {
  color: #000;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1
}

@-webkit-keyframes Gradient {
  50% {
    background-position: 140% 50%;
    -webkit-transform: skew(-2deg);
    transform: skew(-2deg)
  }
}

@keyframes Gradient {
  50% {
    background-position: 140% 50%;
    -webkit-transform: skew(-2deg);
    transform: skew(-2deg)
  }
}


.logo-block img {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  opacity: 0.5;
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
  margin: 0 auto
}

.logo-block img:focus {
  outline: none
}

.logo-block img:hover {
  -webkit-filter: grayscale(0);
  filter: grayscale(0);
  opacity: 1;
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease
}

.lookbook-section {
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 25px
}

.lookbook-section .lookbook-img>div+div img {
  margin-top: 25px
}

.lookbook .lookbook-block {
  position: relative
}

.lookbook .lookbook-block .lookbook-dot {
  cursor: pointer;
  position: absolute;
  z-index: 2;
  width: 29px;
  height: 29px;
  line-height: 29px;
  border-radius: 50%;
  -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  top: 50%;
  left: 12%;
  background-color: #fff
}

.lookbook .lookbook-block .lookbook-dot.dot2 {
  top: 70%;
  left: 27%
}

.lookbook .lookbook-block .lookbook-dot.dot3 {
  top: 28%;
  left: 30%
}

.lookbook .lookbook-block .lookbook-dot.dot4 {
  top: 67%;
  left: 8%
}

.lookbook .lookbook-block .lookbook-dot.dot5 {
  top: 40%;
  left: 35%
}

.lookbook .lookbook-block .lookbook-dot.dot6 {
  top: 80%;
  left: 58%
}

.lookbook .lookbook-block .lookbook-dot.dot7 {
  top: 67%;
  left: 45%
}

.lookbook .lookbook-block .lookbook-dot.dot8 {
  top: 43%;
  left: 39%
}

.lookbook .lookbook-block .lookbook-dot.dot9 {
  top: 32%;
  left: 50%
}

.lookbook .lookbook-block .lookbook-dot.dot10 {
  top: 60%;
  left: 50%
}

.lookbook .lookbook-block .lookbook-dot.dot11 {
  top: 50%;
  left: 45%
}

.lookbook .lookbook-block .lookbook-dot.dot12 {
  top: 75%;
  left: 45%
}

.lookbook .lookbook-block .lookbook-dot.dot13 {
  top: 40%;
  left: 45%
}

.lookbook .lookbook-block .lookbook-dot.dot14 {
  top: 76%;
  left: 45%
}

.lookbook .lookbook-block .lookbook-dot.dot15 {
  top: 50%;
  left: 15%
}

.lookbook .lookbook-block .lookbook-dot.dot16 {
  left: 30%
}

.lookbook .lookbook-block .lookbook-dot.dot17 {
  top: 78%;
  left: 30%
}

.lookbook .lookbook-block .lookbook-dot.dot18 {
  top: 56%;
  left: 62%
}

.lookbook .lookbook-block .lookbook-dot.dot19 {
  top: 74%;
  left: 75%
}

.lookbook .lookbook-block .lookbook-dot:before {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  content: "";
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.5);
  zoom: 1;
  background-color: transparent\9;
  z-index: -1;
  border-radius: 50%;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease
}

.lookbook .lookbook-block .lookbook-dot span {
  text-align: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex
}

.lookbook .lookbook-block .lookbook-dot .dot-showbox {
  visibility: hidden;
  top: -98px;
  left: 150%;
  position: absolute;
  width: 130px;
  background-color: #fff;
  -webkit-box-shadow: -3px -3px 13px rgba(48, 54, 61, 0.1);
  box-shadow: -3px -3px 13px rgba(48, 54, 61, 0.1)
}

.lookbook .lookbook-block .lookbook-dot .dot-showbox img {
  margin-top: 0
}

.lookbook .lookbook-block .lookbook-dot .dot-showbox .dot-info {
  padding: 5px;
  text-align: center
}

.lookbook .lookbook-block .lookbook-dot .dot-showbox .dot-info h5 {
  margin-bottom: 0;
  text-transform: capitalize;
  line-height: 20px
}

.lookbook .lookbook-block .lookbook-dot .dot-showbox .dot-info h5.title {
  font-weight: 700
}

.lookbook .lookbook-block .lookbook-dot .dot-showbox .dot-info h6 {
  text-transform: uppercase;
  color: var(--theme-color);
  margin-bottom: 0
}

.lookbook .lookbook-block .lookbook-dot .dot-showbox:before {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-right: 8px solid #ffffff;
  border-bottom: 8px solid transparent;
  top: 41%;
  left: -8px
}

.lookbook .lookbook-block .lookbook-dot:hover {
  background-color: var(--theme-color);
  -webkit-box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.5);
  box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.5);
  z-index: 10
}

.lookbook .lookbook-block .lookbook-dot:hover .dot-showbox {
  -webkit-animation: fadeInUp 400ms ease-in-out;
  animation: fadeInUp 400ms ease-in-out;
  visibility: visible;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease
}

.lookbook .lookbook-block .lookbook-dot:hover span {
  color: #fff
}

.lookbook .lookbook-block .lookbook-dot:hover:before {
  -webkit-transform: scale(1.3);
  transform: scale(1.3)
}

.lookbook.lookbook-layout .lookbook-block .lookbook-dot {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center
}

.product-left-title h3 {
  font-weight: 600;
  color: #222;
  text-transform: capitalize;
  max-width: 200px;
  line-height: 36px
}

.product-left-title p {
  line-height: 27px;
  font-size: 18px
}

.product-left-title .slick-custom-arrow {
  margin-top: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex
}

.product-left-title .slick-custom-arrow li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border: 1px solid #ddd;
  border-radius: 100%;
  width: 38px;
  height: 38px;
  cursor: pointer
}

.product-left-title .slick-custom-arrow li.left-arrow {
  margin-right: 16px
}

.product-left-title .slick-custom-arrow i {
  font-size: 32px
}

.product-left-title.right-content {
  text-align: right
}

.product-left-title.right-content h3 {
  margin-left: auto
}

.product-left-title.right-content .slick-custom-arrow {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end
}

.added-notification {
  position: fixed;
  top: -60px;
  right: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #3b4156;
  border-radius: 3px;
  padding: 6px;
  overflow: hidden;
  -webkit-box-shadow: 0 0 2px 1px #e0e0e0;
  box-shadow: 0 0 2px 1px #e0e0e0;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease
}

.added-notification img {
  width: 25px
}

.added-notification h3 {
  color: #ffffff;
  text-transform: capitalize;
  font-size: 16px;
  margin-bottom: 0;
  margin-left: 10px;
  margin-right: 10px;
  font-weight: 600
}

.added-notification.show {
  top: 30px;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease
}

.alert .btn-close {
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat
}



.absolute-product .theme-tab .tab-title .current a {
  font-weight: 700
}

.absolute-product .product-box {
  width: 100%;
  display: inline-block;
  padding-bottom: 10px;
  -webkit-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
  border-radius: 5px
}

.absolute-product .product-box .img-wrapper {
  border-radius: 5px 5px 0 0
}

.absolute-product .product-box .product-detail {
  text-align: center;
  margin-top: 0;
  padding: 0 15px
}

.absolute-product .product-box .product-detail .color-variant {
  padding-top: 5px
}

.absolute-product .product-box .product-detail .color-variant li {
  height: 16px;
  width: 16px
}

.absolute-product .product-box .product-detail .cart-bottom {
  border-top: 1px solid #ddd;
  padding-top: 10px;
  margin-top: 10px;
  padding-bottom: 10px
}

.absolute-product .product-box .product-detail .cart-bottom button {
  border: none;
  background-color: transparent;
  padding: 0
}

.absolute-product .product-box .product-detail .cart-bottom i {
  color: #828282;
  font-size: 18px;
  padding-right: 7px;
  padding-left: 7px;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease
}

.absolute-product .product-box .product-detail .cart-bottom i:hover {
  color: var(--theme-color);
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease
}

.absolute-product .product-box .product-detail .rating {
  margin-top: 10px
}

.absolute-product .slick-slider .product-box {
  margin-bottom: 3px
}

.product-m .slick-list {
  margin-left: -12px;
  margin-right: -12px
}

.product-m .slick-slide>div {
  margin: 0 12px
}

.color-variant li {
  display: inline-block;
  height: 20px;
  width: 20px;
  border-radius: 100%;
  cursor: pointer;
  border: 1px solid #aaa;
  margin-right: 5px;
  -webkit-transition: all 0.1s ease;
  transition: all 0.1s ease;
  vertical-align: middle
}

.quantity-variant li {
  padding: 3px 5px;
  font-size: 12px;
  background-color: #f1f1f1 !important;
  height: auto !important;
  width: auto !important;
  border-radius: 5px !important
}

.quantity-variant.box-l li {
  padding: 6px 8px
}

.image-swatch-demo li {
  width: 22px !important;
  height: 22px !important;
  overflow: hidden
}

.image-swatch {
  margin-bottom: 10px
}

.image-swatch li img {
  width: 33px;
  height: 33px;
  padding: 2px;
  -o-object-fit: cover;
  object-fit: cover;
  border: 1px solid #ddd;
  margin-right: 5px;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease
}

.image-swatch li:last-child {
  margin-right: 0
}

.image-swatch li.active img {
  border: 1px solid var(--theme-color)
}

.image-swatch li:hover img {
  border: 1px solid var(--theme-color);
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease
}

.product-box .image-swatch {
  margin-top: 15px
}

.product-box .image-swatch li img {
  width: 28px;
  height: 28px
}

.no-slider.five-product .product-box {
  width: 100%;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
  max-width: calc(20% - 30px);
  margin: 0 15px 30px
}

.no-slider.five-product .product-box:nth-last-child(-n+5) {
  margin: 0 15px 0
}

.no-slider.five-product-row .product-box {
  width: 100%;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
  max-width: calc(20% - 30px)
}

.no-slider.six-product .product-box {
  width: 100%;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
  max-width: calc(16.66% - 30px);
  margin: 0 15px 30px !important
}

.product-para p {
  margin-bottom: 0;
  padding-bottom: 30px;
  line-height: 24px;
  letter-spacing: 0.05em
}

.product-box,
.product-wrap {
  position: relative;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  vertical-align: middle
}

.product-box .img-block,
.product-wrap .img-block {
  background-color: #f9f9f9;
  position: relative;
  overflow: hidden
}

.product-box .img-block .front,
.product-wrap .img-block .front {
  opacity: 1;
  top: 0;
  left: 0;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease
}

.product-box .img-block .front a,
.product-wrap .img-block .front a {
  display: block;
  width: 100%
}

.product-box .img-block .back,
.product-wrap .img-block .back {
  opacity: 0;
  position: absolute;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  top: 0;
  left: 0;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  -webkit-transform: translateX(-100px);
  transform: translateX(-100px);
  width: 100%
}

.product-box .img-block .back a,
.product-wrap .img-block .back a {
  display: block;
  width: 100%
}

.product-box .img-block .lable-wrapper,
.product-wrap .img-block .lable-wrapper {
  margin: 0 auto;
  top: 40px;
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  z-index: 2
}

.product-box .img-block .lable-wrapper .lable1,
.product-box .img-block .lable-wrapper .lable2,
.product-wrap .img-block .lable-wrapper .lable1,
.product-wrap .img-block .lable-wrapper .lable2 {
  font-size: 14px;
  padding: 10px 14px 10px 20px;
  display: inline-block;
  text-transform: uppercase;
  text-align: center
}

.product-box .img-block .lable-wrapper .lable1,
.product-wrap .img-block .lable-wrapper .lable1 {
  background-color: var(--theme-color);
  color: #fff;
  border-bottom-left-radius: 25px;
  border-top-left-radius: 25px
}

.product-box .img-block .lable-wrapper .lable2,
.product-wrap .img-block .lable-wrapper .lable2 {
  background-color: #fff;
  color: #000;
  border-bottom-right-radius: 25px;
  border-top-right-radius: 25px
}

.product-box .img-wrapper,
.product-wrap .img-wrapper {
  position: relative;
  overflow: hidden;
  z-index: 0
}

.product-box .img-wrapper .front,
.product-wrap .img-wrapper .front {
  opacity: 1;
  top: 0;
  left: 0;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease
}

.product-box .img-wrapper .back,
.product-wrap .img-wrapper .back {
  opacity: 0;
  position: absolute;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  top: 0;
  left: 0;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  -webkit-transform: translateX(-100px);
  transform: translateX(-100px);
  width: 100%
}

.product-box .img-wrapper .cart-box,
.product-wrap .img-wrapper .cart-box {
  position: absolute;
  margin: 0 auto;
  display: inline-block;
  right: 0;
  left: 0;
  border-radius: 50px;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  padding: 12px 15px;
  -webkit-box-shadow: 0 0 12px 0 #ddd;
  box-shadow: 0 0 12px 0 #ddd;
  bottom: 30px;
  background-color: #fff;
  opacity: 0;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease
}

.product-box .img-wrapper .cart-box button,
.product-wrap .img-wrapper .cart-box button {
  background: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: none;
  padding: 0
}

.product-box .img-wrapper .cart-box i,
.product-wrap .img-wrapper .cart-box i {
  color: #6f6f6f;
  font-size: 18px;
  padding-left: 8px;
  padding-right: 8px;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  display: inline-block
}

.product-box .img-wrapper .cart-box i:hover,
.product-wrap .img-wrapper .cart-box i:hover {
  color: var(--theme-color);
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease
}

.product-box .img-wrapper .cart-box.cart-box-bottom,
.product-wrap .img-wrapper .cart-box.cart-box-bottom {
  bottom: 0;
  border-radius: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  right: unset
}

.product-box .img-wrapper .lable-block .lable3,
.product-wrap .img-wrapper .lable-block .lable3 {
  border-radius: 100%;
  background-color: var(--theme-color);
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  position: absolute;
  padding: 12px 6px;
  text-transform: uppercase;
  color: #fff;
  top: 7px;
  left: 7px;
  z-index: 1
}

.product-box .img-wrapper .lable-block .lable4,
.product-wrap .img-wrapper .lable-block .lable4 {
  position: absolute;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  -webkit-writing-mode: vertical-rl;
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
  top: 7px;
  right: 7px;
  letter-spacing: 0.1em;
  z-index: 1;
  color: #212529
}

.product-box .cart-info,
.product-box .cart-wrap,
.product-wrap .cart-info,
.product-wrap .cart-wrap {
  position: absolute;
  bottom: 40px;
  text-align: center;
  margin: 0 auto;
  display: inline-block;
  right: 0;
  left: 0;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  opacity: 0;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease
}

.product-box .cart-info a [class^="ti-"],
.product-box .cart-info a [class*=" ti-"],
.product-box .cart-wrap a [class^="ti-"],
.product-box .cart-wrap a [class*=" ti-"],
.product-wrap .cart-info a [class^="ti-"],
.product-wrap .cart-info a [class*=" ti-"],
.product-wrap .cart-wrap a [class^="ti-"],
.product-wrap .cart-wrap a [class*=" ti-"] {
  display: inline-block
}

.product-box .cart-info.cart-wrap,
.product-box .cart-wrap.cart-wrap,
.product-wrap .cart-info.cart-wrap,
.product-wrap .cart-wrap.cart-wrap {
  bottom: 0;
  text-align: right;
  left: unset;
  display: flex;
  flex-direction: column;
}

.product-box .cart-info.cart-wrap i,
.product-box .cart-wrap.cart-wrap i,
.product-wrap .cart-info.cart-wrap i,
.product-wrap .cart-wrap.cart-wrap i {
  display: block;
  padding-bottom: 10px;
  padding-top: 10px
}

.product-box .cart-info.cart-wrap.cart-effect-left,
.product-box .cart-wrap.cart-wrap.cart-effect-left,
.product-wrap .cart-info.cart-wrap.cart-effect-left,
.product-wrap .cart-wrap.cart-wrap.cart-effect-left {
  left: 0;
  right: unset
}

.product-box .cart-info button,
.product-box .cart-wrap button,
.product-wrap .cart-info button,
.product-wrap .cart-wrap button {
  background: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: none;
  padding: 0
}

.product-box .cart-info i,
.product-box .cart-wrap i,
.product-wrap .cart-info i,
.product-wrap .cart-wrap i {
  color: #6f6f6f;
  font-size: 18px;
  padding-right: 10px;
  padding-left: 10px
}

.product-box .cart-info i:hover,
.product-box .cart-wrap i:hover,
.product-wrap .cart-info i:hover,
.product-wrap .cart-wrap i:hover {
  color: var(--theme-color)
}

.product-box .cart-detail,
.product-wrap .cart-detail {
  position: absolute;
  top: 15px;
  right: 20px;
  opacity: 0
}

.product-box .cart-detail i,
.product-wrap .cart-detail i {
  color: #6f6f6f;
  font-size: 18px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-top: 8px;
  padding-bottom: 8px
}

.product-box .cart-detail button,
.product-wrap .cart-detail button {
  background: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: none;
  padding: 0
}

.product-box .product-detail,
.product-box .product-info,
.product-wrap .product-detail,
.product-wrap .product-info {
  padding-left: 5px;
  margin-top: 15px
}

.product-box .product-detail .rating i,
.product-box .product-info .rating i,
.product-wrap .product-detail .rating i,
.product-wrap .product-info .rating i {
  padding-right: 5px
}

.product-box .product-detail .rating i:nth-child(-n+4),
.product-box .product-info .rating i:nth-child(-n+4),
.product-wrap .product-detail .rating i:nth-child(-n+4),
.product-wrap .product-info .rating i:nth-child(-n+4) {
  color: #ffa200
}

.product-box .product-detail .rating i:last-child,
.product-box .product-info .rating i:last-child,
.product-wrap .product-detail .rating i:last-child,
.product-wrap .product-info .rating i:last-child {
  color: #ddd
}

.product-box .product-detail h6,
.product-box .product-info h6,
.product-wrap .product-detail h6,
.product-wrap .product-info h6 {
  line-height: 1;
  margin-bottom: 0;
  padding-top: 2px;
  padding-bottom: 5px;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  font-size: 16px;
  text-transform: capitalize;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden
}

.product-box .product-detail h4,
.product-box .product-info h4,
.product-wrap .product-detail h4,
.product-wrap .product-info h4 {
  font-size: 18px;
  color: #222;
  font-weight: 700;
  margin-bottom: 0;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease
}

.product-box .product-detail .color-variant,
.product-box .product-info .color-variant,
.product-wrap .product-detail .color-variant,
.product-wrap .product-info .color-variant {
  padding-top: 15px
}

.product-box .product-detail .color-variant li,
.product-box .product-info .color-variant li,
.product-wrap .product-detail .color-variant li,
.product-wrap .product-info .color-variant li {
  display: inline-block;
  height: 20px;
  width: 20px;
  border-radius: 100%;
  margin-right: 5px;
  -webkit-transition: all 0.1s ease;
  transition: all 0.1s ease;
  cursor: pointer
}

.product-box .product-info,
.product-wrap .product-info {
  padding: 0;
  text-align: center;
  position: relative
}

.product-box .product-info .add-btn,
.product-wrap .product-info .add-btn {
  position: absolute;
  bottom: 110px;
  margin: 0 auto;
  left: 0;
  right: 0;
  opacity: 0;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  z-index: 1
}

.product-box .product-info .add-btn i,
.product-wrap .product-info .add-btn i {
  color: var(--theme-color)
}

.product-box .product-info .add-btn .btn-outline,
.product-wrap .product-info .add-btn .btn-outline {
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  color: var(--theme-color)
}

.product-box .product-info .add-btn .btn-outline:hover,
.product-wrap .product-info .add-btn .btn-outline:hover {
  color: #fff
}

.product-box .product-info .add-btn .btn-outline:hover i,
.product-wrap .product-info .add-btn .btn-outline:hover i {
  color: #fff
}

.product-box.effect-center .front img,
.product-wrap.effect-center .front img {
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease
}

.product-box.effect-center .img-wrapper .cart-box,
.product-wrap.effect-center .img-wrapper .cart-box {
  bottom: 20%;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease
}

.product-box:hover.effect-center .front img,
.product-wrap:hover.effect-center .front img {
  opacity: 0.3;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease
}

.product-box:hover.effect-center .img-wrapper .cart-box,
.product-wrap:hover.effect-center .img-wrapper .cart-box {
  bottom: 35%;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease
}

.product-box:hover .img-block .first,
.product-box:hover .img-wrapper .first,
.product-wrap:hover .img-block .first,
.product-wrap:hover .img-wrapper .first {
  opacity: 0;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease
}

.product-box:hover .img-block .back,
.product-box:hover .img-wrapper .back,
.product-wrap:hover .img-block .back,
.product-wrap:hover .img-wrapper .back {
  opacity: 1;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  -webkit-transform: translateX(0);
  transform: translateX(0)
}

.product-box:hover .cart-info,
.product-wrap:hover .cart-info {
  opacity: 1;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease
}

.product-box:hover .cart-info button,
.product-wrap:hover .cart-info button {
  -webkit-animation: fadeInUp 300ms ease-in-out;
  animation: fadeInUp 300ms ease-in-out
}

.product-box:hover .cart-info a:nth-child(2) i,
.product-wrap:hover .cart-info a:nth-child(2) i {
  -webkit-animation: fadeInUp 500ms ease-in-out;
  animation: fadeInUp 500ms ease-in-out
}

.product-box:hover .cart-info a:nth-child(3) i,
.product-wrap:hover .cart-info a:nth-child(3) i {
  -webkit-animation: fadeInUp 700ms ease-in-out;
  animation: fadeInUp 700ms ease-in-out
}

.product-box:hover .cart-info a:nth-child(4) i,
.product-wrap:hover .cart-info a:nth-child(4) i {
  -webkit-animation: fadeInUp 1000ms ease-in-out;
  animation: fadeInUp 1000ms ease-in-out
}

.product-box:hover .cart-wrap button,
.product-wrap:hover .cart-wrap button {
  -webkit-animation: fadeInRight 300ms ease-in-out;
  animation: fadeInRight 300ms ease-in-out
}

.product-box:hover .cart-wrap a:nth-child(2) i,
.product-wrap:hover .cart-wrap a:nth-child(2) i {
  -webkit-animation: fadeInRight 500ms ease-in-out;
  animation: fadeInRight 500ms ease-in-out
}

.product-box:hover .cart-wrap a:nth-child(3) i,
.product-wrap:hover .cart-wrap a:nth-child(3) i {
  -webkit-animation: fadeInRight 700ms ease-in-out;
  animation: fadeInRight 700ms ease-in-out
}

.product-box:hover .cart-wrap a:nth-child(4) i,
.product-wrap:hover .cart-wrap a:nth-child(4) i {
  -webkit-animation: fadeInRight 1000ms ease-in-out;
  animation: fadeInRight 1000ms ease-in-out
}

.product-box:hover .cart-wrap.cart-effect-left button,
.product-wrap:hover .cart-wrap.cart-effect-left button {
  -webkit-animation: fadeInLeft 300ms ease-in-out;
  animation: fadeInLeft 300ms ease-in-out
}

.product-box:hover .cart-wrap.cart-effect-left a:nth-child(2) i,
.product-wrap:hover .cart-wrap.cart-effect-left a:nth-child(2) i {
  -webkit-animation: fadeInLeft 500ms ease-in-out;
  animation: fadeInLeft 500ms ease-in-out
}

.product-box:hover .cart-wrap.cart-effect-left a:nth-child(3) i,
.product-wrap:hover .cart-wrap.cart-effect-left a:nth-child(3) i {
  -webkit-animation: fadeInLeft 700ms ease-in-out;
  animation: fadeInLeft 700ms ease-in-out
}

.product-box:hover .cart-wrap.cart-effect-left a:nth-child(4) i,
.product-wrap:hover .cart-wrap.cart-effect-left a:nth-child(4) i {
  -webkit-animation: fadeInLeft 1000ms ease-in-out;
  animation: fadeInLeft 1000ms ease-in-out
}

.product-box:hover .cart-detail,
.product-wrap:hover .cart-detail {
  opacity: 1;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease
}

.product-box:hover .cart-detail button,
.product-wrap:hover .cart-detail button {
  -webkit-animation: fadeInRight 300ms ease-in-out;
  animation: fadeInRight 300ms ease-in-out
}

.product-box:hover .cart-detail a:nth-child(2) i,
.product-wrap:hover .cart-detail a:nth-child(2) i {
  -webkit-animation: fadeInRight 500ms ease-in-out;
  animation: fadeInRight 500ms ease-in-out
}

.product-box:hover .cart-detail a:nth-child(3) i,
.product-wrap:hover .cart-detail a:nth-child(3) i {
  -webkit-animation: fadeInRight 700ms ease-in-out;
  animation: fadeInRight 700ms ease-in-out
}

.product-box:hover .cart-detail a:nth-child(4) i,
.product-wrap:hover .cart-detail a:nth-child(4) i {
  -webkit-animation: fadeInRight 1000ms ease-in-out;
  animation: fadeInRight 1000ms ease-in-out
}

.product-box:hover .product-info .add-btn,
.product-wrap:hover .product-info .add-btn {
  opacity: 1;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  -webkit-animation: fadeInUp 500ms ease-in-out;
  animation: fadeInUp 500ms ease-in-out
}

.product-box:hover .img-wrapper .cart-box,
.product-wrap:hover .img-wrapper .cart-box {
  opacity: 1;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  -webkit-animation: fadeInUp 400ms ease-in-out;
  animation: fadeInUp 400ms ease-in-out
}

.lable-gradient {
  border-radius: 0;
  background-color: var(--theme-color);
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  position: absolute;
  padding: 4px 12px;
  text-transform: uppercase;
  color: #fff;
  top: 7px;
  left: 7px;
  background: -webkit-gradient(linear, left top, right top, from(var(--theme-color)), to(var(--theme-color2)));
  background: linear-gradient(to right, var(--theme-color), var(--theme-color2));
  z-index: 1
}

.product-box .cart-info.bg-color-cls {
  right: -40px
}

.product-box .cart-info.bg-color-cls button {
  background-color: white;
  -webkit-animation: none !important;
  animation: none !important
}

.product-box .cart-info.bg-color-cls a i {
  background-color: white;
  -webkit-animation: none !important;
  animation: none !important
}

.product-box .cart-info.bg-color-cls.sm-box i {
  padding-bottom: 7px;
  padding-top: 7px;
  font-size: 16px
}

.product-box:hover .cart-info.bg-color-cls {
  right: 0
}

.bg-style i {
  background-color: white;
  padding: 7px !important;
  border-radius: 100%;
  margin: 0 3px;
  font-size: 15px !important
}

.addtocart_count {
  position: relative
}

.addtocart_count .product-box .product-detail {
  margin-top: 8px;
  text-align: center
}

.addtocart_count .product-box .cart-info {
  bottom: 40px;
  right: 10px
}

.addtocart_count .product-box .cart-info a i {
  background-color: #e2e2e2;
  border-radius: 100%;
  margin: 10px 0;
  padding: 8px;
  font-size: 16px;
  color: #313131
}

.addtocart_count .product-box .add-button {
  background-color: #f5f5f5;
  color: black;
  text-align: center;
  font-size: 18px;
  width: 100%;
  padding: 5px 0;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  border: none;
  cursor: pointer
}

.addtocart_count .product-box:hover .cart-info a:nth-child(1) i {
  -webkit-animation: fadeInRight 300ms ease-in-out;
  animation: fadeInRight 300ms ease-in-out
}

.addtocart_count .product-box:hover .add-button {
  bottom: 0;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease
}

.addtocart_count .addtocart_btn {
  position: relative
}

.addtocart_count .addtocart_btn .cart_qty {
  width: 100%
}

.addtocart_count .addtocart_btn .cart_qty.qty-box {
  position: absolute;
  bottom: 0;
  display: none
}

.addtocart_count .addtocart_btn .cart_qty.qty-box .input-group .form-control {
  width: 100%;
  border-color: #efefef
}

.addtocart_count .addtocart_btn .cart_qty.qty-box .input-group .form-control:focus {
  border-color: #efefef;
  -webkit-box-shadow: none;
  box-shadow: none
}

.addtocart_count .addtocart_btn .cart_qty.qty-box .input-group button {
  background: #efefef !important;
  position: absolute;
  height: 100%;
  z-index: 9
}

.addtocart_count .addtocart_btn .cart_qty.qty-box .input-group button.quantity-left-minus {
  left: 0
}

.addtocart_count .addtocart_btn .cart_qty.qty-box .input-group button.quantity-right-plus {
  right: 0
}

.addtocart_count .addtocart_btn .cart_qty.qty-box .input-group button i {
  color: #000
}

.addtocart_count .addtocart_btn .cart_qty.open {
  display: block
}

.grid-products {
  margin-bottom: -30px
}

.grid-products .product-box {
  margin-bottom: 30px
}

.bg-title .theme-card h5.title-border {
  padding: 10px;
  color: white;
  background-color: var(--theme-color);
  border-radius: 5px
}

.bg-title .theme-card .slick-prev {
  right: 30px
}

.bg-title .theme-card .slick-prev:before {
  color: white;
  opacity: 1;
  font-size: 25px
}

.bg-title .theme-card .slick-next {
  right: 6px
}

.bg-title .theme-card .slick-next:before {
  color: white;
  opacity: 1;
  font-size: 25px
}

.bg-title .theme-tab .bg-title-part {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 30px;
  margin-top: -6px;
  background-color: var(--theme-color);
  padding: 10px;
  border-radius: 5px
}

.bg-title .theme-tab .bg-title-part .title-border {
  margin-bottom: 0;
  color: white;
  text-transform: capitalize
}

.bg-title .theme-tab .bg-title-part .tab-title {
  margin-bottom: 0;
  margin-top: 0;
  margin-left: auto;
  text-align: right
}

.bg-title .theme-tab .bg-title-part .tab-title li {
  font-size: 16px;
  padding-right: 0
}

.bg-title .theme-tab .bg-title-part .tab-title li:first-child {
  padding-left: 0
}

.bg-title .theme-tab .bg-title-part .tab-title a {
  color: rgba(255, 255, 255, 0.7)
}

.bg-title .theme-tab .bg-title-part .tab-title .current a {
  color: white
}

.bg-title .theme-tab .bg-title-part .tab-title.w-bg li {
  padding: 4px 18px !important
}

.bg-title .theme-tab .bg-title-part .tab-title.w-bg li.current {
  background-color: var(--theme-color)
}

.bg-title .theme-tab .bg-title-part .tab-title.w-bg li.current a {
  color: white
}

.bg-title .theme-tab .bg-title-part.wo-bg {
  background-color: #f8f9fa
}

.bg-title .theme-tab .bg-title-part.wo-bg .tab-title a {
  color: #2d2a25
}

.bg-title .theme-tab .bg-title-part.wo-bg .tab-title .current a {
  color: var(--theme-color)
}

.bg-title .theme-tab .bg-title-part.wo-bg .title-border {
  font-size: 18px;
  font-weight: 600;
  color: #222222
}

.bg-title .theme-tab .bg-title-part.wo-bg .title-border.small-title {
  font-size: 16px
}

.bg-title.wo-bg .theme-tab .bg-title-part {
  background-color: transparent;
  padding: 0 0 14px 0;
  border-radius: 0;
  border-bottom: 1px solid #ddd
}

.bg-title.wo-bg .theme-tab .bg-title-part .title-border {
  color: #000000;
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase
}

.bg-title.wo-bg .theme-tab .tab-title a {
  color: #2d2a25
}

.bg-title.wo-bg .theme-tab .tab-title .current a {
  color: var(--theme-color)
}

.tools-grey .product-box {
  width: 100%;
  display: inline-block;
  overflow: hidden;
  background-color: white;
  -webkit-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.05)
}

.tools-grey .product-box .img-wrapper .lable-block {
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease
}

.tools-grey .product-box .cart-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  left: 0;
  bottom: -30px;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease
}

.tools-grey .product-box .cart-info .mobile-quick-view {
  display: none
}

.tools-grey .product-box .cart-info a {
  background-color: #eef0f1;
  border-radius: 5px
}

.tools-grey .product-box .cart-info a i {
  color: #555
}

.tools-grey .product-box .cart-info a:hover {
  background-color: var(--theme-color);
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease
}

.tools-grey .product-box .cart-info a:hover i {
  color: #fff;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease
}

.tools-grey .product-box .cart-info button {
  width: 55%;
  background-color: #eef0f1;
  color: #555;
  border-radius: 5px;
  margin: 0 5px;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease
}

.tools-grey .product-box .cart-info button i {
  display: none
}

.tools-grey .product-box .cart-info button:hover {
  background-color: var(--theme-color);
  color: #fff;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease
}

.tools-grey .product-box .quick-view-part {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -20px;
  background-color: var(--theme-color);
  border-radius: 100%;
  width: 40px;
  height: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  opacity: 0;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease
}

.tools-grey .product-box .quick-view-part i {
  font-size: 18px;
  color: #fff
}

.tools-grey .product-box .product-info {
  padding: 15px;
  background-color: #fff;
  border-top: 1px solid rgba(221, 221, 221, 0.2);
  padding-top: 0;
  margin-top: 0
}

.tools-grey .product-box .product-info .rating {
  margin-top: 0;
  padding-top: 0
}

.tools-grey .product-box:hover .img-wrapper .front img,
.tools-grey .product-box:hover .img-wrapper .front .bg-size {
  opacity: 0.05;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease
}

.tools-grey .product-box:hover .img-wrapper .cart-info {
  opacity: 1;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  bottom: 10px
}

.tools-grey .product-box:hover .img-wrapper .cart-info button {
  -webkit-animation: none;
  animation: none
}

.tools-grey .product-box:hover .img-wrapper .cart-info a:nth-child(2) i {
  -webkit-animation: none;
  animation: none
}

.tools-grey .product-box:hover .img-wrapper .cart-info a:nth-child(3) i {
  -webkit-animation: none;
  animation: none
}

.tools-grey .product-box:hover .img-wrapper .cart-info a:nth-child(4) i {
  -webkit-animation: none;
  animation: none
}

.tools-grey .product-box:hover .img-wrapper .lable-block {
  display: none;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease
}

.tools-grey .product-box:hover .quick-view-part {
  opacity: 1;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease
}

.tools-grey .product-box:hover .ribbon {
  display: none;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease
}

.tools-grey .ribbon {
  position: absolute;
  left: -5px;
  top: -5px;
  z-index: 1;
  overflow: hidden;
  width: 75px;
  height: 75px;
  text-align: right;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease
}

.tools-grey .ribbon span {
  font-size: 10px;
  font-weight: bold;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  line-height: 20px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  width: 100px;
  display: block;
  background: var(--theme-color);
  -webkit-box-shadow: 0 3px 10px -5px #000;
  box-shadow: 0 3px 10px -5px #000;
  position: absolute;
  top: 19px;
  left: -21px
}

.tools-grey .ribbon span:before {
  content: "";
  position: absolute;
  left: 0px;
  top: 100%;
  z-index: -1;
  border-left: 3px solid var(--theme-color);
  border-right: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-top: 3px solid var(--theme-color)
}

.tools-grey .ribbon span:after {
  content: "";
  position: absolute;
  right: 0px;
  top: 100%;
  z-index: -1;
  border-left: 3px solid transparent;
  border-right: 3px solid var(--theme-color);
  border-bottom: 3px solid transparent;
  border-top: 3px solid var(--theme-color)
}

.j-box .product-box {
  width: 100%;
  display: inline-block;
  border: 1px solid #ddd;
  border-radius: 12px;
  overflow: hidden
}

.j-box .product-box .cart-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  left: 0;
  background-color: rgba(250, 250, 250, 0.84);
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  bottom: -30px;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease
}

.j-box .product-box .cart-info a {
  border-left: 1px solid #ddd
}

.j-box .product-box .cart-info a i {
  padding: 12px
}

.j-box .product-box .product-detail {
  padding-left: 15px;
  padding-bottom: 15px;
  background-color: #fafafa;
  padding-top: 15px;
  margin-top: 0
}

.j-box .product-box .product-detail .rating {
  margin-top: 0
}

.j-box .product-box:hover .img-wrapper .cart-info {
  opacity: 1;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  bottom: 0
}

.j-box .product-box:hover .img-wrapper .cart-info button {
  -webkit-animation: none;
  animation: none
}

.j-box .product-box:hover .img-wrapper .cart-info a:nth-child(2) i {
  -webkit-animation: none;
  animation: none
}

.j-box .product-box:hover .img-wrapper .cart-info a:nth-child(3) i {
  -webkit-animation: none;
  animation: none
}

.j-box .product-box:hover .img-wrapper .cart-info a:nth-child(4) i {
  -webkit-animation: none;
  animation: none
}

.j-box.style-box .product-box {
  padding: 10px
}

.j-box.style-box .product-box .product-detail {
  background-color: white
}

.j-box.style-box .product-box .cart-info {
  background-color: transparent;
  border: none
}

.j-box.style-box .product-box .cart-info a {
  border: none
}

.bakery-box .product-box .img-wrapper {
  border: 5px solid white;
  -webkit-box-shadow: 0 0 8px rgba(0, 0, 0, 0.12);
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.12)
}

.bakery-box .product-box .img-wrapper .lable-block .lable3 {
  background-color: #ffffff;
  color: var(--theme-color);
  padding: 4px 22px;
  border-radius: 0
}

.bakery-box .product-box .product-detail {
  text-align: center;
  padding-left: 0
}

.bakery-box .product-box .product-detail h6 {
  padding-top: 7px;
  padding-bottom: 12px
}

.bakery-box .product-box .cart-info {
  top: 0;
  right: 10px
}

.bakery-box .product-box .cart-info a i {
  background-color: white;
  border-radius: 100%;
  margin: 10px 0;
  padding: 8px;
  font-size: 16px
}

.bakery-box .product-box .add-button {
  background-color: var(--theme-color);
  color: white;
  text-align: center;
  font-size: 18px;
  text-transform: capitalize;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 80%;
  bottom: -40px;
  padding: 5px 0;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  cursor: pointer
}

.bakery-box .product-box:hover .cart-info a:nth-child(1) i {
  -webkit-animation: fadeInRight 300ms ease-in-out;
  animation: fadeInRight 300ms ease-in-out
}

.bakery-box .product-box:hover .add-button {
  bottom: 20px;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease
}

.bakery-box .img-wrapper img {
  width: 100%
}

.product-box.single-product {
  text-align: center
}

.product-box.single-product .product-detail h6 {
  font-size: calc(16px + (25 - 16) * ((100vw - 320px) / (1920 - 320)));
  margin-bottom: calc(4px + (10 - 4) * ((100vw - 320px) / (1920 - 320)))
}

.product-box.single-product .product-detail h4 {
  font-size: calc(22px + (28 - 22) * ((100vw - 320px) / (1920 - 320)))
}

.product-box.single-product .product-detail .btn-solid {
  padding: 12px 56px;
  margin-top: 20px
}

.product-box.single-product .color-variant {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-bottom: none
}

.product-box.single-product .color-variant .nav-item {
  width: 30px !important;
  height: 30px !important;
  margin: 0 10px
}

.product-box.single-product .color-variant .nav-item.color1 {
  background-color: #19627e
}

.product-box.single-product .color-variant .nav-item.color2 {
  background-color: #e7e3e2
}

.product-box.single-product .color-variant .nav-item.color3 {
  background-color: #c560a4
}

.product-box.single-product .color-variant .nav-link {
  padding: 0;
  background-color: transparent;
  border: none;
  width: 30px !important;
  height: 30px !important
}

.product-box.single-product .color-variant .nav-link.active {
  padding: 0;
  background-color: transparent;
  border: none
}

.product-style-1.product-box {
  border: 1px solid #f5f5f5;
  padding: 10px
}

.product-style-1.product-box .product-detail h6 {
  padding-bottom: 10px
}

.product-style-2.product-box .product-info .color-variant,
.product-style-2 .product-wrap .product-info .color-variant {
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease
}

.product-style-2.product-box .product-info .add-btn,
.product-style-2 .product-wrap .product-info .add-btn {
  bottom: 0
}

.product-style-2.product-box .product-info .add-btn a,
.product-style-2 .product-wrap .product-info .add-btn a {
  color: var(--theme-color);
  text-transform: capitalize;
  font-size: calc(13px + (17 - 13) * ((100vw - 320px) / (1920 - 320)))
}

.product-style-2.product-box:hover .product-info .color-variant,
.product-style-2 .product-wrap:hover .product-info .color-variant {
  opacity: 0;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease
}

.product-style-3.product-box .product-info,
.product-style-3 .product-wrap .product-info {
  margin-top: 8px
}

.product-style-3.product-box .product-info .add-btn,
.product-style-3 .product-wrap .product-info .add-btn {
  bottom: 0;
  position: relative;
  opacity: 1;
  margin-top: 8px;
  -webkit-animation: none;
  animation: none
}

.product-style-3.product-box .product-info .add-btn a,
.product-style-3 .product-wrap .product-info .add-btn a {
  color: var(--theme-color);
  text-transform: capitalize;
  font-size: calc(13px + (17 - 13) * ((100vw - 320px) / (1920 - 320)))
}

.product-style-4.product-box,
.product-style-4.product-wrap {
  padding: 10px;
  border-radius: 7px;
  border: 1px solid #f1f1f1
}

.product-style-4.product-box .cart-detail,
.product-style-4.product-wrap .cart-detail {
  top: 5px;
  right: 5px
}

.product-style-4.product-box .cart-detail a,
.product-style-4.product-wrap .cart-detail a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center
}

.product-style-4.product-box .cart-detail a i,
.product-style-4.product-wrap .cart-detail a i {
  color: var(--theme-color);
  font-size: 14px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-top: 8px;
  padding-bottom: 8px;
  background-color: #fff;
  padding: 6px;
  border-radius: 100%;
  margin-bottom: 5px;
  border: 1px solid #ddd
}

.product-style-4.product-box .product-info,
.product-style-4.product-wrap .product-info {
  text-align: left;
  margin-top: 10px
}

.product-style-4.product-box .product-info h5,
.product-style-4.product-wrap .product-info h5 {
  margin-top: 6px;
  margin-bottom: 3px;
  font-size: 14px
}

.product-style-4.product-box .product-info h6,
.product-style-4.product-wrap .product-info h6 {
  font-weight: 500
}

.product-style-4.product-box .addtocart_btn .add_cart,
.product-style-4.product-wrap .addtocart_btn .add_cart {
  position: absolute;
  right: 0;
  bottom: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: none;
  background-color: var(--theme-color);
  color: white;
  width: 26px;
  height: 26px;
  font-size: 14px;
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 3px
}

.product-style-4.product-box .addtocart_btn .cart_qty,
.product-style-4.product-wrap .addtocart_btn .cart_qty {
  width: 100%
}

.product-style-4.product-box .addtocart_btn .cart_qty.qty-box,
.product-style-4.product-wrap .addtocart_btn .cart_qty.qty-box {
  position: absolute;
  bottom: 0;
  display: none;
  right: -12px
}

.product-style-4.product-box .addtocart_btn .cart_qty.qty-box .input-group,
.product-style-4.product-wrap .addtocart_btn .cart_qty.qty-box .input-group {
  width: 52%;
  margin-left: auto;
  margin-right: 12px;
  border-radius: 3px;
  overflow: hidden
}

.product-style-4.product-box .addtocart_btn .cart_qty.qty-box .input-group .form-control,
.product-style-4.product-wrap .addtocart_btn .cart_qty.qty-box .input-group .form-control {
  width: 100%;
  border-color: var(--theme-color);
  color: #fff;
  padding: 0;
  background-color: var(--theme-color)
}

.product-style-4.product-box .addtocart_btn .cart_qty.qty-box .input-group .form-control:focus,
.product-style-4.product-wrap .addtocart_btn .cart_qty.qty-box .input-group .form-control:focus {
  -webkit-box-shadow: none;
  box-shadow: none
}

.product-style-4.product-box .addtocart_btn .cart_qty.qty-box .input-group button,
.product-style-4.product-wrap .addtocart_btn .cart_qty.qty-box .input-group button {
  background: var(--theme-color) !important;
  position: absolute;
  height: 100%;
  z-index: 9
}

.product-style-4.product-box .addtocart_btn .cart_qty.qty-box .input-group button i,
.product-style-4.product-wrap .addtocart_btn .cart_qty.qty-box .input-group button i {
  color: #fff
}

.product-style-4.product-box .addtocart_btn .cart_qty.qty-box .input-group button.quantity-left-minus,
.product-style-4.product-wrap .addtocart_btn .cart_qty.qty-box .input-group button.quantity-left-minus {
  left: 0
}

.product-style-4.product-box .addtocart_btn .cart_qty.qty-box .input-group button.quantity-right-plus,
.product-style-4.product-wrap .addtocart_btn .cart_qty.qty-box .input-group button.quantity-right-plus {
  right: 0
}

.product-style-4.product-box .addtocart_btn .cart_qty.open,
.product-style-4.product-wrap .addtocart_btn .cart_qty.open {
  display: block
}

.product-style-5.product-box {
  background-color: #f8f8f8;
  text-align: left;
  border-radius: 15px;
  padding: 15px
}

.product-style-5.product-box .img-wrapper {
  width: 70%;
  margin-top: 20px
}

.product-style-5.product-box h5 {
  margin-top: calc(3px + (6 - 3) * ((100vw - 320px) / (1920 - 320)));
  margin-bottom: 3px;
  font-size: 16px;
  color: #777
}

.product-style-5.product-box h4 {
  margin-bottom: 0;
  font-weight: 600
}

.product-style-5.product-box h6 {
  font-weight: 500;
  font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
  color: #222;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis
}

.product-style-5.product-box .addtocart_btn .add_cart {
  position: absolute;
  right: 15px;
  bottom: 15px;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: none;
  background-color: var(--theme-color);
  color: #fff;
  width: 36px;
  height: 36px;
  font-size: 16px;
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 10px;
  z-index: 1
}

.product-style-5.product-box .addtocart_btn .cart_qty {
  width: 100%
}

.product-style-5.product-box .addtocart_btn .cart_qty.qty-box {
  position: absolute;
  bottom: 0;
  display: none;
  right: -12px
}

.product-style-5.product-box .addtocart_btn .cart_qty.qty-box .input-group {
  width: 52%;
  margin-left: auto;
  margin-right: 12px;
  border-radius: 3px;
  overflow: hidden;
  height: 36px;
  right: -12px;
  z-index: 1;
  right: 15px;
  bottom: 15px
}

.product-style-5.product-box .addtocart_btn .cart_qty.qty-box .input-group .form-control {
  width: 100%;
  border-color: var(--theme-color);
  color: #fff;
  padding: 0;
  background-color: var(--theme-color);
  border-radius: 20px
}

.product-style-5.product-box .addtocart_btn .cart_qty.qty-box .input-group .form-control:focus {
  -webkit-box-shadow: none;
  box-shadow: none
}

.product-style-5.product-box .addtocart_btn .cart_qty.qty-box .input-group button {
  background: var(--theme-color) !important;
  position: absolute;
  height: 100%;
  z-index: 9;
  border-radius: 10px;
  font-size: 16px
}

.product-style-5.product-box .addtocart_btn .cart_qty.qty-box .input-group button i {
  color: #fff
}

.product-style-5.product-box .addtocart_btn .cart_qty.qty-box .input-group button.quantity-left-minus {
  left: 0
}

.product-style-5.product-box .addtocart_btn .cart_qty.qty-box .input-group button.quantity-right-plus {
  right: 0
}

.product-style-5.product-box .addtocart_btn .cart_qty.open {
  display: block
}

.addtocart_count .product-box.product-style-1 .add-button {
  background-color: #f8f8f8;
  margin-top: 10px
}

.dark-box {
  margin-right: -5px;
  margin-left: -5px
}

.dark-box>div {
  -webkit-box-flex: 0 !important;
  -ms-flex: 0 0 20% !important;
  flex: 0 0 20% !important;
  max-width: 20% !important;
  padding: 0 5px
}

.dark-box .product-box {
  border: 1px solid transparent;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: -52px
}

.dark-box .product-box .img-wrapper {
  overflow: unset
}

.dark-box .product-box .cart-detail i {
  padding: 8px;
  border-radius: 100%;
  background-color: var(--theme-color);
  font-size: 14px;
  color: white;
  margin-bottom: 10px
}

.dark-box .product-box .product-info {
  padding-bottom: 52px
}

.dark-box .product-box .product-info h6 {
  padding-top: 5px;
  padding-bottom: 10px
}

.dark-box .product-box .product-info .add-btn {
  position: relative;
  bottom: 0;
  margin-top: 14px;
  opacity: 1;
  display: none;
  -webkit-animation: none;
  animation: none
}

.dark-box .product-box .product-info .add-btn .btn {
  padding: 7px 33px
}

.dark-box .product-box:hover {
  border: 1px solid #404040;
  z-index: 1;
  background-color: #2b2b2b
}

.dark-box .product-box:hover .product-info {
  padding-bottom: 0
}

.dark-box .product-box:hover .product-info .add-btn {
  display: block
}

.dark-box.four-product {
  margin-bottom: -20px
}

.dark-box.four-product>div {
  max-width: 25% !important;
  -webkit-box-flex: 0 !important;
  -ms-flex: 0 0 25% !important;
  flex: 0 0 25% !important;
  margin-bottom: 20px
}

.product-box .product-arrow .slick-prev,
.product-box .product-arrow .slick-next {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  margin-top: -10px
}

.product-box .product-arrow .slick-prev:before,
.product-box .product-arrow .slick-next:before {
  font: normal normal normal 14px/1 FontAwesome;
  opacity: 1;
  color: black;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center
}

.product-box .product-arrow .slick-next {
  right: 1px
}

.product-box .product-arrow .slick-next:before {
  content: "\f105"
}

.product-box .product-arrow .slick-prev {
  left: 1px;
  z-index: 1
}

.product-box .product-arrow .slick-prev:before {
  content: "\f104"
}

.product-box .product-arrow:hover .slick-prev,
.product-box .product-arrow:hover .slick-next {
  opacity: 1;
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease
}

.product-box .product-arrow:hover .slick-prev:before,
.product-box .product-arrow:hover .slick-next:before {
  opacity: 1
}

.product-box .product-arrow:hover .slick-next {
  right: 30px
}

.product-box .product-arrow:hover .slick-prev {
  left: 30px
}

.detail-inline {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex
}

.detail-inline h4 {
  margin-left: auto
}

.infinite-product .product-box {
  display: none
}

.load-more-button .btn {
  -webkit-transition: width 0.1s ease, height 0.4s ease, border-width 0.4s ease, border-radius 0.4s ease, border-color 0.4s ease, padding 0.4s ease;
  transition: width 0.1s ease, height 0.4s ease, border-width 0.4s ease, border-radius 0.4s ease, border-color 0.4s ease, padding 0.4s ease
}

.load-more-button .btn:focus {
  outline: none
}

.load-more-button .btn.loading {
  height: 24px;
  width: 24px;
  padding: 0;
  border-width: 2px;
  border-radius: 17px;
  border-color: #eee #eee #eee #999;
  background: #fff;
  font-size: 0;
  color: transparent;
  pointer-events: none;
  -webkit-animation: spin 1.2s infinite linear;
  animation: spin 1.2s infinite linear
}

.load-more-button .btn.loading:before {
  opacity: 0
}

.flash-sale-sec .row {
  border: 5px solid rgba(32, 102, 100, 0.8);
  padding: 20px;
  border-radius: 10px
}

.flash-sale-sec .row>div {
  padding: 0
}

@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg)
  }

  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg)
  }
}

.service-block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center
}

.service-block img {
  margin-right: 12px
}

.service-block .media {
  padding-top: 23px;
  padding-bottom: 23px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center
}

.service-block svg {
  margin-right: 12px;
  width: 60px;
  height: 60px
}

.service-block svg path {
  fill: var(--theme-color)
}

.service-block h4 {
  font-weight: 700;
  text-transform: capitalize;
  letter-spacing: 0.03em
}

.service-block p {
  text-transform: capitalize;
  margin-bottom: 0
}

.service-block+.service-block {
  border-left: 1px solid #ddd
}

.service-block:hover svg {
  -webkit-animation: pulse 1000ms ease-in-out;
  animation: pulse 1000ms ease-in-out;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease
}

.service-block:hover h4 {
  color: var(--theme-color);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease
}

.service-block1 {
  text-align: center
}

.service-block1 img {
  margin-bottom: 20px;
  width: 60px
}

.service-block1 svg {
  margin-bottom: 20px;
  width: 60px;
  height: 60px
}

.service-block1 svg path {
  fill: var(--theme-color)
}

.service-block1 h4 {
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 15px
}

.service-block1 p {
  letter-spacing: 0.03em;
  margin-bottom: -3px;
  line-height: 1.5
}

.service-block1:hover svg {
  -webkit-animation: pulse 1000ms ease-in-out;
  animation: pulse 1000ms ease-in-out;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease
}

.service-block1:hover h4 {
  color: var(--theme-color);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease
}

.service-w-bg .service {
  margin-bottom: -30px
}

.service-w-bg .service-block {
  margin-top: 0 !important;
  margin-bottom: 30px
}

.service-w-bg .service-block p {
  line-height: 1.3
}

.service-w-bg .service-block+.service-block {
  border: none;
  margin-top: 0 !important
}

.service-w-bg .service-block .media {
  background-color: whitesmoke;
  width: 100%;
  padding: 20px
}


.theme-bg-service.service-w-bg .service-block .media {
  border-radius: 10px
}

.theme-bg-service.service-w-bg .service-block h4 {
  color: #fff
}

.theme-bg-service.service-w-bg .service-block p {
  color: rgba(255, 255, 255, 0.7)
}

.theme-bg-service.service-w-bg .service-block .media {
  background-color: var(--theme-color)
}

.theme-bg-service.service-w-bg .service-block svg path {
  fill: #fff
}

.layout7-product .product-box {
  padding: 2px
}

.layout7-product .product-box img {
  width: 100%
}

.layout7-product .product-box .cart-info {
  right: 7px;
  bottom: 7px
}

.layout7-product .product-box .details-product {
  position: absolute;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  opacity: 0;
  bottom: 15px;
  left: 15px
}

.layout7-product .product-box .details-product h6,
.layout7-product .product-box .details-product h4 {
  margin-bottom: 0;
  color: #000
}

.layout7-product .product-box:hover .details-product {
  opacity: 1;
  -webkit-animation: fadeIn 500ms ease-in-out;
  animation: fadeIn 500ms ease-in-out;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease
}

.theme-card {
  position: relative
}

.theme-card.center-align {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center
}

.theme-card.center-align .offer-slider .product-box2 .media .media-body {
  margin-top: 0
}

.theme-card.card-border {
  border: 1px solid #ddd
}

.theme-card.card-border h5 {
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 30px;
  margin-top: 0;
  margin-bottom: 0
}

.theme-card.card-border .slick-prev,
.theme-card.card-border .slick-next {
  top: -40px
}

.theme-card.card-border .slick-next {
  right: 30px
}

.theme-card.card-border .slick-prev {
  right: 50px
}

.theme-card.card-border .offer-slider {
  padding-top: 0;
  padding-bottom: 10px
}

.theme-card.card-border .offer-slider img {
  padding: 15px 15px 15px 30px
}

.theme-card.card-border .offer-slider .media .media-body a h6 {
  margin-right: 25px
}

.theme-card h5 {
  font-weight: 700;
  text-transform: uppercase;
  padding-bottom: 10px;
  margin-top: -5px
}

.theme-card h5.title-border {
  border-bottom: 1px solid #ddd
}

.theme-card .slick-prev,
.theme-card .slick-next {
  top: -35px
}

.theme-card .slick-prev:before,
.theme-card .slick-next:before {
  font: normal normal normal 30px/1 FontAwesome;
  color: #222;
  opacity: 0.6
}

.theme-card .slick-next {
  right: 0
}

.theme-card .slick-next:before {
  content: "\f105"
}

.theme-card .slick-prev {
  left: unset;
  right: 25px
}

.theme-card .slick-prev:before {
  content: "\f104"
}

.theme-card .offer-slider img {
  height: 160px;
  padding: 15px
}

.theme-card .offer-slider>div .media:last-child .media-body {
  margin-top: 15px
}

.theme-card .offer-slider>div .media:last-child img {
  margin-bottom: -15px
}

.theme-card .offer-slider .media .media-body .rating {
  margin-top: 0
}

.theme-card .offer-slider .media .media-body .rating i {
  padding-right: 5px
}

.theme-card .offer-slider .media .media-body .rating i:nth-child(-n+4) {
  color: #ffa200
}

.theme-card .offer-slider .media .media-body .rating i:last-child {
  color: #ddd
}

.theme-card .offer-slider .media .media-body a h6 {
  margin-right: 61px;
  line-height: 17px;
  margin-top: 5px;
  margin-bottom: 0
}

.theme-card .offer-slider .media .media-body h4 {
  margin-top: 10px;
  font-weight: 700;
  color: #222;
  margin-bottom: 0
}

.offer-slider .slick-slide {
  line-height: 1
}

.center-slider .product-box {
  -webkit-box-shadow: none;
  box-shadow: none
}

.center-slider .product-box .img-wrapper img {
  padding: 15px
}

.center-slider .slick-next:before,
.center-slider .slick-prev:before {
  -webkit-box-shadow: 0 0 8px 0 #ddd;
  box-shadow: 0 0 8px 0 #ddd
}

.center-slider .title2 .title-inner2 {
  margin-bottom: 0
}

.center-slider:hover .product-box {
  -webkit-box-shadow: none;
  box-shadow: none
}

.center-slider:hover .slick-next {
  right: 20px;
  -webkit-transform: scale(1.8);
  transform: scale(1.8)
}

.center-slider:hover .slick-prev {
  left: 20px;
  -webkit-transform: scale(1.8);
  transform: scale(1.8)
}

.full-box .theme-card .offer-slider .product-box2 {
  overflow: hidden
}

.full-box .theme-card .offer-slider .product-box2 .title {
  text-align: center;
  color: black;
  text-transform: capitalize;
  font-weight: 600;
  margin-bottom: 0
}

.full-box .theme-card .offer-slider .product-box2 .media img {
  height: 300px;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease
}

.full-box .theme-card .offer-slider .product-box2 .media img.h-cls {
  height: 250px
}

.full-box .theme-card .offer-slider .product-box2 .media .media-body a h6 {
  margin-right: 20px
}

.full-box .theme-card .offer-slider .product-box2 .media .media-body h4 {
  font-size: 20px;
  margin-top: 5px
}

.full-box .theme-card .offer-slider .product-box2 .media .media-body .color-variant li {
  display: inline-block;
  height: 20px;
  width: 20px;
  border-radius: 100%;
  margin-right: 5px;
  margin-top: 15px;
  -webkit-transition: all 0.1s ease;
  transition: all 0.1s ease;
  margin-bottom: 0;
  cursor: pointer
}

.full-box .theme-card .offer-slider .product-box2+.product-box2 {
  border-top: 1px solid #f1f5f4
}

.full-box .theme-card .offer-slider .product-box2:hover .media img {
  -webkit-transform: scale(1.03);
  transform: scale(1.03);
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease
}

.box-product .full-box .theme-card .offer-slider .product-box2 {
  -webkit-box-shadow: 0 2px 5px 0 rgba(145, 145, 145, 0.16), 0 2px 10px 0 rgba(145, 145, 145, 0.12);
  box-shadow: 0 2px 5px 0 rgba(145, 145, 145, 0.16), 0 2px 10px 0 rgba(145, 145, 145, 0.12)
}

.box-product .full-box .theme-card .offer-slider .product-box2 .media {
  padding-right: 15px
}

.box-product .full-box .theme-card .offer-slider .product-box2 .media img {
  height: 192px;
  padding: 0 15px 0 0
}

.box-product .full-box .theme-card .offer-slider .product-box2 .media .media-body {
  z-index: 1
}

.box-product .full-box .theme-card .offer-slider .product-box2 .media .media-body h4 {
  font-size: 18px;
  margin-top: 7px
}

.box-product .full-box .theme-card .offer-slider .product-box2+.product-box2 {
  border-top: none;
  margin-top: 30px
}

.box-product .full-box .theme-card .offer-slider .product-box2 .cart-bottom {
  border-top: 1px solid #ddd;
  padding-top: 10px;
  margin-top: 12px
}

.box-product .full-box .theme-card .offer-slider .product-box2 .cart-bottom button {
  border: none;
  background-color: transparent;
  padding: 0
}

.box-product .full-box .theme-card .offer-slider .product-box2 .cart-bottom i {
  color: #aaa;
  font-size: 16px;
  padding-right: 7px;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease
}

.box-product .full-box .theme-card .offer-slider .product-box2 .cart-bottom i:hover {
  color: var(--theme-color);
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease
}

.box-product .theme-card .offer-slider>div .media:last-child .media-body {
  margin-top: 0
}

.product-parallax .theme-card .offer-slider>div .media {
  background-color: #fff
}

.product-parallax .theme-card .offer-slider>div .media:first-child {
  margin-bottom: 20px
}

.product-parallax .theme-card .offer-slider>div .media:last-child img {
  margin-bottom: 0
}

.product-parallax .theme-card.card-border {
  border: none
}

.product-parallax .theme-card.card-border .offer-slider img {
  padding: 15px 15px 15px 15px
}

.multiple-slider {
  margin-bottom: -30px
}

.multiple-slider>div {
  margin-bottom: 30px
}

.product-vertical .full-banner {
  padding-top: 75px !important;
  padding-bottom: 75px !important
}

.product-vertical .full-box .theme-card .offer-slider {
  background-color: white
}

.product-vertical .full-box .theme-card .offer-slider .product-box2 .media img {
  height: 240px
}

.product-vertical .theme-card .offer-slider>div .media:last-child img {
  margin-bottom: 0
}

.product-vertical .theme-card.center-align .offer-slider .product-box2 .media .media-body {
  text-align: left
}

.demo-card.theme-card {
  background-color: #f8f8f8;
  padding: 20px;
  margin-top: 30px
}

.tap-top {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  position: fixed;
  bottom: 50px;
  right: 30px;
  z-index: 8;
  display: none;
  color: #fff;
  text-align: center;
  background: var(--theme-color);
  border: none;
  font-size: 22px;
  padding: 7px;
  cursor: pointer
}

.tap-top.top-cls {
  bottom: 130px
}

.tap-top.green-top {
  background-image: -webkit-gradient(linear, left top, right top, from(var(--theme-color)), to(var(--theme-color2)));
  background-image: linear-gradient(to right, var(--theme-color), var(--theme-color2));
  border: 1px solid rgba(255, 255, 255, 0.5)
}

.tap-top.gradient-top {
  background: -webkit-gradient(linear, left top, left bottom, from(var(--theme-color)), color-stop(99%, var(--theme-color2)));
  background: linear-gradient(180deg, var(--theme-color) 0%, var(--theme-color2) 99%)
}

.select_input select {
  padding: 15px 20px;
  margin-bottom: 30px
}

.template-password {
  /* background: url("../images/coming-soon.jpg"); */
  background-size: cover;
  height: 100vh
}

.template-password #container {
  height: 100vh;
  max-width: 600px;
  margin: 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center
}

.template-password #container>div {
  width: 100%
}

.template-password #container #login {
  width: 100%;
  margin-bottom: 120px
}

.template-password #container #login h2 {
  color: var(--theme-color);
  font-size: 30px;
  font-weight: 400
}

.template-password #container #login .theme-form h3 {
  color: #999;
  font-size: 20px
}

.template-password #container #login .theme-form input {
  border-color: #ddd;
  font-size: 12px;
  padding: 17px 25px;
  margin-bottom: 15px;
  height: inherit
}

.template-password #container #login .theme-form input:focus {
  -webkit-box-shadow: none;
  box-shadow: none
}

.template-password #container #powered {
  position: absolute;
  bottom: 5px;
  left: 0;
  right: 0
}

.template-password #container #owner a {
  color: var(--theme-color)
}

.review-page .comment-section {
  border-bottom: none !important
}

.review-page .comment-section li:first-child {
  border-top: none;
  padding-top: 0
}

.review-page .comment-section li:last-child {
  padding-bottom: 0
}

.review-page .comnt-sec {
  margin-top: 10px
}

.review-page .comnt-sec li {
  margin-right: 15px;
  padding-bottom: 0 !important;
  padding-top: 0 !important;
  border-top: none !important
}

.review-page .comnt-sec li a {
  color: #777;
  font-size: 16px
}

.review-page .comnt-sec li a i {
  margin-right: 5px
}

.error-section {
  padding: 130px 0 140px;
  text-align: center
}

.error-section h1 {
  font-size: 200px;
  font-weight: 900;
  margin: 0;
  line-height: 0.8;
  color: #aaa;
  margin-top: -11px
}

.error-section h2 {
  color: #aaa;
  font-weight: 500;
  letter-spacing: 3px;
  margin: 40px 0
}

.breadcrumb-section {
  background-color: #f8f8f8;
  padding: 30px 0
}

.breadcrumb-section .page-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center
}

.breadcrumb-section .page-title h2 {
  font-size: 14px;
  margin-bottom: 0
}

.breadcrumb-section .breadcrumb {
  background-color: transparent;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  text-transform: uppercase;
  font-size: 14px;
  color: #555;
  font-weight: 600;
  margin: 0;
  padding-right: 0
}

.breadcrumb-section .breadcrumb a {
  color: #555;
  font-weight: 600
}

.about-head {
  margin-bottom: 20px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%
}

.about-head h2 {
  color: var(--theme-color);
  margin-bottom: 0
}

.about-page p {
  margin-bottom: -7px
}

.about-page .banner-section {
  border-radius: 5px;
  overflow: hidden
}

.about-page h4 {
  margin-bottom: 10px;
  margin-top: 30px;
  text-transform: capitalize;
  font-weight: 600;
  line-height: 1.3
}

.about-page p {
  line-height: 2;
  letter-spacing: 0.03em
}

.testimonial {
  background-color: #f7f7f7
}

.testimonial .testimonial-slider .media img {
  border-radius: 100%;
  border: 1px solid #ddd;
  padding: 5px;
  height: 130px;
  width: 130px
}

.testimonial .testimonial-slider .media h5 {
  font-weight: 700;
  color: var(--theme-color);
  margin-bottom: 0;
  margin-top: 7px
}

.testimonial .testimonial-slider .media h6 {
  margin-bottom: -4px
}

.testimonial .testimonial-slider .media .media-body {
  padding: 30px 75px 30px 30px
}

.testimonial .testimonial-slider .media .media-body p {
  line-height: 2;
  letter-spacing: 0.03em
}

.testimonial .testimonial-slider .slick-track .slick-slide:nth-child(even) .media {
  border-left: 1px solid #ddd;
  padding-left: 50px
}

.team {
  position: relative
}

.team img {
  margin: 0 auto;
  padding: 0 10px
}

.team .slick-prev,
.team .slick-next {
  position: absolute;
  top: -45px
}

.team .slick-prev:before,
.team .slick-next:before {
  font: normal normal normal 30px/1 FontAwesome;
  color: #222;
  opacity: 0.6
}

.team .slick-prev {
  left: unset;
  right: 20px
}

.team .slick-prev:before {
  content: "\f104"
}

.team .slick-next {
  right: 0
}

.team .slick-next:before {
  content: "\f105"
}

.team h2 {
  color: #222;
  padding-bottom: 15px;
  border-bottom: 1px solid #dddada;
  margin-bottom: 10px
}

.team h4,
.team h6 {
  text-align: center
}

.team h4 {
  color: var(--theme-color);
  font-weight: 700;
  margin-top: 15px;
  margin-bottom: 5px
}

.team h6 {
  margin-bottom: 0
}

.collection-filter-block {
  margin-bottom: 30px;
  padding: 0 30px;
  border: 1px solid #fff;
  background-color: #f9f9f9
}

.collection-filter-block .product-service {
  padding: 30px 0
}

.collection-filter-block .product-service .media {
  padding-bottom: 20px;
  margin-bottom: 20px
}

.collection-filter-block .product-service .media svg {
  height: 40px;
  width: 40px;
  fill: var(--theme-color)
}

.collection-filter-block .product-service .media svg path {
  fill: var(--theme-color)
}

.collection-filter-block .product-service .media .media-body {
  padding-left: 10px
}

.collection-filter-block .product-service .media .media-body h4 {
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: 600
}

.collection-filter-block .product-service .media .media-body p {
  margin-bottom: 0;
  text-transform: capitalize
}

.collection-filter-block .product-service .media:last-child {
  padding-bottom: 0
}

.collection-collapse-block {
  padding-bottom: 24px
}

.collection-collapse-block.open {
  margin-bottom: 12px
}

.collection-collapse-block .collapse-block-title {
  position: relative;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 16px;
  color: #444;
  font-weight: 600;
  margin: 30px 0 0 0
}

.collection-collapse-block .collapse-block-title:after {
  position: absolute;
  display: block;
  top: 0;
  right: -3px;
  content: "\f0d7";
  font-family: FontAwesome;
  color: #444
}

.collection-collapse-block .collection-collapse-block-content .color-selector {
  margin-top: 30px
}

.collection-collapse-block .collection-collapse-block-content .collection-brand-filter .category-list li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 16px;
  text-transform: uppercase;
  line-height: 18px
}

.collection-collapse-block .collection-collapse-block-content .collection-brand-filter .category-list li a {
  color: #777;
  padding-bottom: 10px
}

.collection-collapse-block .collection-collapse-block-content .collection-brand-filter .category-list li:last-child a {
  padding-bottom: 0
}

.collection-collapse-block .collection-collapse-block-content .collection-brand-filter .category-list li:first-child {
  margin-top: 30px
}

.collection-collapse-block .collection-collapse-block-content .collection-brand-filter .collection-filter-checkbox {
  margin-bottom: 12px
}

.collection-collapse-block .collection-collapse-block-content .collection-brand-filter .collection-filter-checkbox:first-child {
  margin-top: 30px
}

.collection-collapse-block .collection-collapse-block-content .collection-brand-filter .collection-filter-checkbox:last-child {
  margin-bottom: 0
}

.collection-collapse-block .collection-collapse-block-content .collection-brand-filter .collection-filter-checkbox label {
  text-transform: uppercase;
  cursor: pointer;
  font-size: 12px;
  color: #777;
  font-weight: 600;
  letter-spacing: 0.5px;
  padding-left: 10px
}

.collection-collapse-block .collection-collapse-block-content .collection-brand-filter .collection-filter-checkbox label:before {
  top: 1px;
  height: 15px;
  width: 15px;
  background: #fff;
  border: 1px solid #777
}

.collection-collapse-block .collection-collapse-block-content .collection-brand-filter .collection-filter-checkbox label:after {
  top: 1px;
  height: 15px;
  width: 15px;
  background-size: 65%
}

.collection-collapse-block .collection-collapse-block-content .collection-brand-filter .collection-filter-checkbox .form-check-input:checked~.form-check-label:after {
  background-image: url("data:image/svg+xml;charset=utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'><path fill='%23000' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/></svg>")
}

.collection-filter .theme-card .offer-slider img {
  padding: 15px 15px 15px 0
}

.color-selector ul li {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  margin-right: 5px;
  margin-bottom: 10px;
  cursor: pointer;
  border: 1px solid #444
}

.color-selector ul .color-1 {
  background-color: white
}

.color-selector ul .color-2 {
  background-color: grey
}

.color-selector ul .color-3 {
  background-color: #ff4c3b
}

.color-selector ul .color-4 {
  background-color: purple
}

.color-selector ul .color-5 {
  background-color: blue
}

.color-selector ul .color-6 {
  background-color: yellow
}

.color-selector ul .color-7 {
  background-color: green
}

.color-selector ul li.active {
  border: 1px solid #000;
  position: relative
}

.color-selector ul li.active:after {
  content: "";
  background-image: url("data:image/svg+xml;charset=utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'><path fill='%23000' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/></svg>");
  top: 9px;
  right: 4px;
  height: 15px;
  width: 15px;
  background-size: 70%;
  background-repeat: no-repeat;
  position: absolute;
  opacity: 0.4
}

.color-w-name {
  margin-top: 30px
}

.color-w-name ul li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 10px;
  text-transform: uppercase;
  font-size: 12px;
  color: #777;
  font-weight: 600;
  letter-spacing: 0.5px;
  cursor: pointer
}

.color-w-name ul li span {
  display: block;
  height: 26px;
  width: 26px;
  border-radius: 3px;
  margin-right: 5px;
  margin-bottom: 0;
  border: 1px solid #777;
  margin-right: 10px
}

.color-w-name ul .color-1 {
  background-color: white
}

.color-w-name ul .color-2 {
  background-color: grey
}

.color-w-name ul .color-3 {
  background-color: red
}

.color-w-name ul .color-4 {
  background-color: purple
}

.color-w-name ul .color-5 {
  background-color: blue
}

.color-w-name ul .color-6 {
  background-color: yellow
}

.color-w-name ul .color-7 {
  background-color: green
}

.color-w-name ul li.active span {
  border: 1px solid #777;
  position: relative
}

.color-w-name ul li.active span:after {
  content: "";
  background-image: url("data:image/svg+xml;charset=utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'><path fill='%23000' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/></svg>");
  top: 7px;
  right: 3px;
  height: 15px;
  width: 15px;
  background-size: 70%;
  background-repeat: no-repeat;
  position: absolute
}

.collection-sidebar-banner {
  margin-top: 30px
}

.collection-sidebar-banner img {
  width: 100%
}

.top-banner-wrapper .top-banner-content h4 {
  font-weight: 600;
  color: #444;
  margin-bottom: 15px
}

.top-banner-wrapper .top-banner-content h5 {
  font-size: 16px;
  font-weight: 600;
  color: #444;
  letter-spacing: 1px;
  line-height: 1.2;
  margin-bottom: 10px
}

.top-banner-wrapper .top-banner-content p {
  line-height: 1.5;
  color: #777;
  margin-bottom: 0;
  font-size: 16px;
  letter-spacing: 0.05em
}

.product-wrapper-grid.list-view .product-wrap .product-info {
  text-align: left;
  -ms-flex-item-align: center;
  align-self: center;
  padding-left: 15px
}

.product-wrapper-grid.list-view .product-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-bottom: 0
}

.product-wrapper-grid.list-view .product-box .img-wrapper,
.product-wrapper-grid.list-view .product-box .img-block {
  width: 25%
}

.product-wrapper-grid.list-view .product-box .product-detail {
  padding-left: 15px;
  -ms-flex-item-align: center;
  align-self: center;
  text-align: left !important
}

.product-wrapper-grid.list-view .product-box .product-detail .rating {
  margin-top: 0
}

.product-wrapper-grid.list-view .product-box .product-detail p {
  display: block !important;
  margin-bottom: 5px;
  line-height: 23px;
  font-size: 16px
}

.product-wrapper-grid.list-view .product-box .product-detail .color-variant {
  padding-top: 10px
}

.product-wrapper-grid.list-view .product-box .product-detail h6 {
  font-weight: 700
}

.collection-product-wrapper .product-top-filter {
  border-bottom: 1px solid #ddd;
  border-top: 1px solid #ddd
}

.collection-product-wrapper .product-top-filter .product-filter-content,
.collection-product-wrapper .product-top-filter .popup-filter {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between
}

.collection-product-wrapper .product-top-filter .product-filter-content .search-count,
.collection-product-wrapper .product-top-filter .product-filter-content .sidebar-popup,
.collection-product-wrapper .product-top-filter .popup-filter .search-count,
.collection-product-wrapper .product-top-filter .popup-filter .sidebar-popup {
  border-right: 1px solid #ddd;
  padding: 20px;
  text-transform: capitalize;
  width: 35%
}

.collection-product-wrapper .product-top-filter .product-filter-content .search-count h5,
.collection-product-wrapper .product-top-filter .product-filter-content .sidebar-popup h5,
.collection-product-wrapper .product-top-filter .popup-filter .search-count h5,
.collection-product-wrapper .product-top-filter .popup-filter .sidebar-popup h5 {
  margin-bottom: 0
}

.collection-product-wrapper .product-top-filter .product-filter-content .collection-view,
.collection-product-wrapper .product-top-filter .popup-filter .collection-view {
  padding: 20px;
  width: 10%
}

.collection-product-wrapper .product-top-filter .product-filter-content .collection-view ul li,
.collection-product-wrapper .product-top-filter .popup-filter .collection-view ul li {
  height: 100%;
  vertical-align: middle;
  cursor: pointer
}

.collection-product-wrapper .product-top-filter .product-filter-content .collection-view ul li:first-child,
.collection-product-wrapper .product-top-filter .popup-filter .collection-view ul li:first-child {
  margin-right: 14px
}

.collection-product-wrapper .product-top-filter .product-filter-content .collection-grid-view,
.collection-product-wrapper .product-top-filter .popup-filter .collection-grid-view {
  padding: 20px;
  width: 20%;
  border-right: 1px solid #ddd
}

.collection-product-wrapper .product-top-filter .product-filter-content .collection-grid-view ul,
.collection-product-wrapper .product-top-filter .popup-filter .collection-grid-view ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end
}

.collection-product-wrapper .product-top-filter .product-filter-content .collection-grid-view ul li,
.collection-product-wrapper .product-top-filter .popup-filter .collection-grid-view ul li {
  margin-left: 10px
}

.collection-product-wrapper .product-top-filter .product-filter-content .collection-grid-view ul li img,
.collection-product-wrapper .product-top-filter .popup-filter .collection-grid-view ul li img {
  height: 18px;
  cursor: pointer
}

.collection-product-wrapper .product-top-filter .product-filter-content .product-page-per-view,
.collection-product-wrapper .product-top-filter .product-filter-content .product-page-filter,
.collection-product-wrapper .product-top-filter .popup-filter .product-page-per-view,
.collection-product-wrapper .product-top-filter .popup-filter .product-page-filter {
  position: relative;
  width: 20%
}

.collection-product-wrapper .product-top-filter .product-filter-content .product-page-per-view select,
.collection-product-wrapper .product-top-filter .product-filter-content .product-page-filter select,
.collection-product-wrapper .product-top-filter .popup-filter .product-page-per-view select,
.collection-product-wrapper .product-top-filter .popup-filter .product-page-filter select {
  border: 0;
  padding: 20px;
  border-right: 1px solid #ddd;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: pointer;
  /* background: url(../images/dropdown.png) no-repeat 95% */
}

.collection-product-wrapper .product-top-filter .product-filter-content .product-page-per-view select:focus,
.collection-product-wrapper .product-top-filter .product-filter-content .product-page-filter select:focus,
.collection-product-wrapper .product-top-filter .popup-filter .product-page-per-view select:focus,
.collection-product-wrapper .product-top-filter .popup-filter .product-page-filter select:focus {
  outline: unset
}

.collection-product-wrapper .product-top-filter .product-filter-content .product-page-per-view select option,
.collection-product-wrapper .product-top-filter .product-filter-content .product-page-filter select option,
.collection-product-wrapper .product-top-filter .popup-filter .product-page-per-view select option,
.collection-product-wrapper .product-top-filter .popup-filter .product-page-filter select option {
  padding: 20px 0
}

.collection-product-wrapper .product-top-filter .product-filter-content .product-page-filter,
.collection-product-wrapper .product-top-filter .popup-filter .product-page-filter {
  width: 15%
}

.collection-product-wrapper .product-top-filter .product-filter-content .product-page-filter:before,
.collection-product-wrapper .product-top-filter .popup-filter .product-page-filter:before {
  right: 10px
}

.collection-product-wrapper .product-top-filter .product-filter-content .product-page-filter select,
.collection-product-wrapper .product-top-filter .popup-filter .product-page-filter select {
  border-right: none
}

.collection-product-wrapper .product-top-filter .popup-filter {
  -webkit-box-pack: unset;
  -ms-flex-pack: unset;
  justify-content: unset
}

.collection-product-wrapper .product-top-filter .popup-filter ::-webkit-scrollbar {
  width: 3px
}

.collection-product-wrapper .product-top-filter .popup-filter ::-webkit-scrollbar-track {
  background: #ddd
}

.collection-product-wrapper .product-top-filter .popup-filter ::-webkit-scrollbar-thumb {
  background: #888
}

.collection-product-wrapper .product-top-filter .popup-filter ::-webkit-scrollbar-thumb:hover {
  background: #555
}

.collection-product-wrapper .product-top-filter .popup-filter .sidebar-popup {
  position: relative;
  cursor: pointer;
  width: 20%;
  /* background: url(../images/dropdown.png) no-repeat 95% */
}

.collection-product-wrapper .product-top-filter .popup-filter .sidebar-popup a {
  font-size: 16px;
  color: #222
}

.collection-product-wrapper .product-top-filter .popup-filter .sidebar-popup .collection-filter {
  background-color: #f8f8f8;
  width: 270px;
  height: 600px;
  overflow-y: scroll
}

.collection-product-wrapper .product-top-filter .popup-filter .sidebar-popup .collection-filter .collection-filter-block {
  border: none
}

.collection-product-wrapper .product-top-filter .popup-filter .sidebar-popup .collection-filter .theme-card {
  padding: 0 30px
}

.collection-product-wrapper .product-top-filter .popup-filter .sidebar-popup .collection-filter .collection-sidebar-banner {
  padding: 0 30px
}

.collection-product-wrapper .product-top-filter .popup-filter .sidebar-popup .collection-filter .collection-sidebar-banner img {
  width: 100%
}

.collection-product-wrapper .product-top-filter .popup-filter .open-popup {
  display: none;
  position: absolute;
  z-index: 9;
  top: 75px;
  left: 0;
  border: 1px solid #f1f5f4;
  -webkit-box-shadow: 0 0 5px #ddd;
  box-shadow: 0 0 5px #ddd;
  min-width: 330px
}

.collection-product-wrapper .product-top-filter .popup-filter .open-popup.open {
  display: block
}

.collection-product-wrapper .product-top-filter .popup-filter .open-popup .collection-filter-block {
  margin-bottom: 0
}

.collection-product-wrapper .product-top-filter .popup-filter .product-page-per-view,
.collection-product-wrapper .product-top-filter .popup-filter .product-page-filter {
  width: 20%
}

.collection-product-wrapper .product-top-filter .popup-filter .product-page-per-view select,
.collection-product-wrapper .product-top-filter .popup-filter .product-page-filter select {
  padding-right: 45px;
  font-size: 16px
}

.collection-product-wrapper .product-top-filter .popup-filter .product-page-per-view:before,
.collection-product-wrapper .product-top-filter .popup-filter .product-page-filter:before {
  right: 35px
}

.collection-product-wrapper .product-top-filter .popup-filter .collection-grid-view {
  padding: 20px
}

.collection-product-wrapper .product-top-filter .popup-filter .collection-view {
  width: 20%
}

.collection-product-wrapper .product-top-filter .popup-filter .product-page-filter:before {
  right: 25px !important
}

.collection-product-wrapper .product-wrapper-grid .product-box {
  margin-top: 50px
}

.collection-product-wrapper .product-wrapper-grid .product-box .product-detail p {
  display: none
}

.collection-product-wrapper .product-wrapper-grid .product-five {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
  max-width: 20%;
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px
}

.collection-product-wrapper .product-wrapper-grid .col-lg-2 .product-box .product-detail h6 {
  font-size: 13px
}

.collection-product-wrapper .product-wrapper-grid .col-lg-2 .product-box .product-detail h4 {
  font-size: 18px
}

.collection-product-wrapper .product-wrapper-grid .col-lg-2 .product-box .product-detail .rating i {
  padding-right: 0
}

.collection-product-wrapper .product-wrapper-grid .col-lg-2 .product-box .img-wrapper .lable-block .lable3 {
  padding: 8px 4px;
  font-size: 12px
}

.filter-main-btn {
  display: none;
  margin-bottom: 20px;
  cursor: pointer
}

.filter-main-btn span {
  width: auto;
  border-radius: 0;
  font-size: 18px;
  background-color: var(--theme-color);
  padding: 5px 16px;
  color: #fff;
  margin-bottom: 20px;
  display: inline-block;
  text-transform: uppercase
}

.collection-mobile-back {
  padding: 15px 0;
  border-bottom: 1px solid #ddd;
  display: none
}

.collection-mobile-back span {
  text-transform: uppercase;
  font-size: 14px;
  color: #000;
  cursor: pointer
}

.collection-mobile-back span i {
  font-size: 20px;
  font-weight: bold;
  margin-right: 5px
}

.product-pagination {
  border-bottom: 1px solid #ddd;
  border-top: 1px solid #ddd;
  margin: 50px 0
}

.product-pagination .pagination {
  border-radius: 0
}

.product-pagination .pagination .page-item {
  border-radius: 0
}

.product-pagination .pagination .page-item a {
  padding: 18px;
  border-bottom: 0;
  border-top: 0;
  color: #777;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0
}

.product-pagination .pagination .page-item:last-child .page-link {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0
}

.product-pagination .pagination .page-item.active a {
  color: var(--theme-color);
  background-color: #fff;
  border-color: #ddd
}

.product-pagination .product-search-count-bottom {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 100%;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  padding-right: 15px
}

.product-pagination .product-search-count-bottom h5 {
  color: #777;
  font-size: 14px;
  margin-bottom: 0;
  padding: 5px 0
}

.bundle {
  margin-top: 10px
}

.bundle .bundle_img {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex
}

.bundle .bundle_img .img-box img {
  max-width: 115px;
  border: 1px solid #ddd;
  padding: 2px
}

.bundle .bundle_img .plus {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 7px;
  font-size: 22px
}

.bundle .bundle_detail {
  margin-top: 15px
}

.bundle .bundle_detail .price_product {
  color: #000;
  padding-left: 3px;
  font-weight: 700
}

.theme_checkbox label {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-transform: capitalize;
  color: #333
}

.theme_checkbox label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0
}

.theme_checkbox label input:checked~.checkmark:after {
  display: block
}

.theme_checkbox label .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #fff;
  border: 1px solid #ddd
}

.theme_checkbox label .checkmark:after {
  content: "";
  position: absolute;
  display: none;
  left: 7px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid #000;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg)
}

.sticky-top-cls {
  position: sticky;
  top: 20px;
  z-index: 1
}

.mordern-box-margin {
  margin-bottom: -40px
}

.mordern-box {
  padding: 25px 25px;
  background-color: #f8f8f8;
  margin-top: 94px;
  margin-bottom: 40px
}

.mordern-box .absolute-img img {
  margin-left: 30px;
  margin-top: -120px;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease
}

.mordern-box .mordern-content {
  margin-top: 14px
}

.mordern-box .mordern-content h6 {
  font-size: 16px;
  margin-bottom: 2px;
  font-weight: 600;
  color: #222
}

.mordern-box .mordern-content p {
  line-height: 20px;
  letter-spacing: 0.05em;
  margin-bottom: 14px
}

.mordern-box .mordern-content .mordern-bottom {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex
}

.mordern-box .mordern-content .mordern-bottom .right {
  margin-left: auto
}

.mordern-box .mordern-content .mordern-bottom .right .add-extent {
  position: relative
}

.mordern-box .mordern-content .mordern-bottom .right .add-extent .animated-btn {
  cursor: pointer;
  width: 30px;
  height: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 100%;
  background-color: #e8e8e8;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  color: #222
}

.mordern-box .mordern-content .mordern-bottom .right .add-extent .options {
  position: absolute;
  right: -6px;
  bottom: 50%;
  background-color: #fff;
  padding: 10px 5px;
  border-radius: 20px;
  -webkit-box-shadow: 0px 5px 7px 1px rgba(0, 0, 0, 0.09);
  box-shadow: 0px 5px 7px 1px rgba(0, 0, 0, 0.09);
  height: 0;
  opacity: 0;
  visibility: hidden
}

.mordern-box .mordern-content .mordern-bottom .right .add-extent .options li {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  padding: 8px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  cursor: pointer
}

.mordern-box .mordern-content .mordern-bottom .right .add-extent .options li button {
  border: none;
  background-color: transparent
}

.mordern-box .mordern-content .mordern-bottom .right .add-extent .options li i {
  color: #6f6f6f;
  font-size: 18px
}

.mordern-box .mordern-content .mordern-bottom .right .add-extent .options li+li {
  margin-top: 6px
}

.mordern-box .mordern-content .mordern-bottom .right .add-extent.show i {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease
}

.mordern-box .mordern-content .mordern-bottom .right .add-extent.show .options {
  opacity: 1;
  visibility: visible;
  height: auto;
  bottom: 38px;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease
}

.collection .collection-block {
  text-align: center
}

.collection .collection-block img,
.collection .collection-block .bg-size {
  margin-bottom: 20px
}

.collection .collection-block .collection-content {
  text-align: center
}

.collection .collection-block .collection-content h3 {
  margin-bottom: 10px;
  color: #222;
  text-transform: uppercase;
  font-weight: 900;
  letter-spacing: 0.05em
}

.collection .collection-block .collection-content h4 {
  color: #222;
  margin-bottom: 10px;
  font-size: 16px
}

.collection .collection-block .collection-content p {
  line-height: 1.5
}

.collection .collection-block .collection-content .btn-solid,
.collection .collection-block .collection-content .btn-outline {
  padding: 10px 25px
}

.search-product {
  margin-bottom: -30px
}

.search-product>div {
  margin-bottom: 30px
}

.category-shop-section .nav {
  height: 100%;
  background-color: #f8f8f8
}

.category-shop-section .nav .nav-link {
  font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  color: #495057;
  border-right: 2px solid transparent;
  cursor: pointer;
  padding: 12px 20px;
  font-weight: 700;
  text-transform: capitalize
}

.category-shop-section .nav .nav-link.active {
  color: var(--theme-color2);
  border-right: 2px solid var(--theme-color2)
}

.faq-section .accordion.theme-accordion .card {
  margin-top: 10px;
  border-radius: 0;
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, 0.07)
}

.faq-section .accordion.theme-accordion .card :not(:first-of-type):not(:last-of-type),
.faq-section .accordion.theme-accordion .card .theme-accordion .card:first-of-type {
  border-bottom: 1px solid #ddd
}

.faq-section .accordion.theme-accordion .card:first-child {
  margin-top: 0
}

.faq-section .accordion.theme-accordion .card .card-header {
  padding: 0;
  border-bottom: 0;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  background-color: #f9f9f9
}

.faq-section .accordion.theme-accordion .card .card-header button {
  color: #333;
  text-align: left;
  font-size: 17px;
  padding: 18px;
  font-weight: 600
}

.faq-section .accordion.theme-accordion .card .card-header button:before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 6px solid #000;
  right: 20px;
  top: 25px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease
}

.faq-section .accordion.theme-accordion .card .card-header button[aria-expanded="true"]:before {
  border-top: 0;
  border-bottom: 6px solid #000;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease
}

.faq-section .accordion.theme-accordion .card .card-header button:hover {
  text-decoration: none;
  color: var(--theme-color);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease
}

.faq-section .accordion.theme-accordion .card .card-body {
  padding: 15px 18px
}

.faq-section .accordion.theme-accordion .card .card-body p {
  margin-bottom: 0;
  font-size: 14px;
  color: #555;
  line-height: 1.8;
  letter-spacing: 0.06em
}

.pwd-page {
  text-align: center
}

.pwd-page h2 {
  margin-bottom: 25px;
  color: #222;
  margin-top: -5px
}

.pwd-page .theme-form input {
  border-color: #ddd;
  font-size: 15px;
  padding: 15px 25px;
  margin-bottom: 15px;
  height: inherit;
  text-align: center
}

.pwd-page .theme-form .btn-solid,
.pwd-page .theme-form .btn-outline {
  margin: 0 auto
}

.login-page h3 {
  margin-bottom: 20px;
  text-transform: uppercase;
  color: #222;
  font-weight: 700;
  margin-top: -5px
}

.login-page .theme-card {
  padding: 30px;
  border: none;
  background-color: #f8f8f8;
  -webkit-box-shadow: 0 0 2px 2px #f1f1f1;
  box-shadow: 0 0 2px 2px #f1f1f1;
  line-height: 1
}

.login-page .theme-card .theme-form label {
  text-transform: capitalize;
  color: #333;
  font-size: 14px;
  font-weight: 600
}

.login-page .theme-card .theme-form input {
  border-color: #eaeaea;
  font-size: 12px;
  padding: 17px 25px;
  margin-bottom: 30px;
  height: inherit
}

.login-page .authentication-right {
  height: calc(100% - 48px)
}

.login-page .authentication-right h6 {
  text-transform: uppercase;
  color: #333;
  font-weight: 600;
  margin-bottom: 20px
}

.login-page .authentication-right p {
  font-size: 14px;
  color: #555;
  line-height: 2;
  margin-bottom: 1rem
}

.register-page h3 {
  margin-bottom: 20px;
  text-transform: uppercase;
  color: #222;
  font-weight: 700;
  margin-top: -5px
}

.register-page .theme-card {
  padding: 30px;
  border: none;
  background-color: #f8f8f8;
  -webkit-box-shadow: 0 0 2px 2px #f1f1f1;
  box-shadow: 0 0 2px 2px #f1f1f1;
  line-height: 1
}

.register-page .theme-card .theme-form label {
  text-transform: capitalize;
  color: #333;
  font-size: 14px;
  font-weight: 600
}

.register-page .theme-card .theme-form input {
  border-color: #eaeaea;
  font-size: 12px;
  padding: 17px 25px;
  margin-bottom: 30px;
  height: inherit
}

.search-block {
  padding: 0
}

.search-block .form-header .input-group input {
  border-radius: 0 5px 5px 0;
  padding: 15px 20px
}

.search-block .form-header .input-group i {
  padding-right: 10px
}

.search-block .form-header .input-group .btn {
  z-index: 0
}

.contact-page .map iframe {
  width: 100%;
  height: 100%;
  border: none
}

.contact-page .contact-right {
  padding-top: 30px;
  padding-bottom: 30px
}

.contact-page .contact-right ul li {
  position: relative;
  padding-left: 150px;
  margin-top: 45px;
  display: block
}

.contact-page .contact-right ul li:first-child {
  margin-top: 0
}

.contact-page .contact-right ul li .contact-icon {
  position: absolute;
  left: 0;
  text-align: center;
  width: 130px;
  border-right: 1px solid #ddd
}

.contact-page .contact-right ul li .contact-icon i {
  font-size: 20px;
  color: var(--theme-color)
}

.contact-page .contact-right ul li .contact-icon h6 {
  text-transform: uppercase;
  padding-top: 5px;
  font-weight: 600;
  color: var(--theme-color)
}

.contact-page .contact-right ul li p {
  font-weight: 600;
  margin-bottom: 0;
  line-height: 30px
}

.contact-page .theme-form label {
  text-transform: capitalize;
  color: #333;
  font-size: 14px;
  font-weight: 600
}

.contact-page .theme-form input {
  border-color: #ddd;
  font-size: 12px;
  padding: 17px 25px;
  margin-bottom: 30px;
  height: inherit
}

.contact-page .theme-form textarea {
  border-color: #ddd;
  font-size: 12px;
  padding: 17px 25px;
  margin-bottom: 30px;
  height: inherit
}

.contact-page .form-control {
  border-radius: 0
}

.qty-box .input-group {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center
}

.qty-box .input-group span button {
  background: #fff !important;
  border: 1px solid #ced4da
}

.qty-box .input-group .form-control {
  text-align: center;
  width: 80px;
  -webkit-box-flex: unset;
  -ms-flex: unset;
  flex: unset
}

.qty-box .input-group button {
  background-color: transparent;
  border: 0;
  color: #777;
  cursor: pointer;
  padding-left: 12px;
  font-size: 12px;
  font-weight: 900;
  line-height: 1
}

.qty-box .input-group button i {
  font-weight: 900;
  color: #222
}

.qty-box .input-group .icon {
  padding-right: 0
}

.order-history .responsive-data {
  display: none
}

.order-history .responsive-data .price {
  margin-bottom: 2px
}

.order-history .dark-data {
  color: black;
  font-weight: 600
}

.cart-section .table tbody+tbody,
.wishlist-section .table tbody+tbody {
  border-top: none
}

.cart-section .cart-buttons,
.wishlist-section .cart-buttons {
  padding-top: 35px
}

.cart-section .cart-buttons>div:last-child,
.wishlist-section .cart-buttons>div:last-child {
  text-align: right;
  padding-right: 59px
}

.cart-section .wishlist-buttons,
.wishlist-section .wishlist-buttons {
  padding-top: 35px;
  text-align: right
}

.cart-section .wishlist-buttons:last-child a,
.wishlist-section .wishlist-buttons:last-child a {
  margin-left: 15px
}

.cart-section .cart-table,
.wishlist-section .cart-table {
  overflow: hidden;
  margin-bottom: 0
}

.cart-section .cart-table thead th,
.wishlist-section .cart-table thead th {
  border-bottom-width: 1px;
  font-weight: 700;
  color: #222;
  text-transform: uppercase;
  font-size: 14px;
  border-top: 0;
  text-align: center;
  padding: 0 0.75rem 0.75rem 0.75rem
}

.cart-section tbody h2,
.wishlist-section tbody h2 {
  margin-bottom: 0
}

.cart-section tbody tr td,
.wishlist-section tbody tr td {
  min-width: 210px;
  vertical-align: middle;
  color: #777;
  border-top: 0;
  border-bottom: 1px solid #eee !important;
  text-align: center
}

.cart-section tbody tr td a,
.cart-section tbody tr td p,
.wishlist-section tbody tr td a,
.wishlist-section tbody tr td p {
  color: #777;
  font-weight: 400;
  font-size: 14px;
  text-transform: capitalize;
  margin-bottom: 0
}

.cart-section tbody tr td a img,
.cart-section tbody tr td p img,
.wishlist-section tbody tr td a img,
.wishlist-section tbody tr td p img {
  height: 90px
}

.cart-section tbody tr td .td-color,
.wishlist-section tbody tr td .td-color {
  color: var(--theme-color)
}

.cart-section tbody tr td h2,
.wishlist-section tbody tr td h2 {
  font-size: 22px;
  color: #222;
  font-weight: 400
}

.cart-section tbody tr td .mobile-cart-content,
.wishlist-section tbody tr td .mobile-cart-content {
  display: none;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-top: 10px
}

.cart-section tbody tr td .mobile-cart-content .qty-box,
.wishlist-section tbody tr td .mobile-cart-content .qty-box {
  border-radius: 5px
}

.cart-section tbody tr td .mobile-cart-content .qty-box .input-group .form-control,
.wishlist-section tbody tr td .mobile-cart-content .qty-box .input-group .form-control {
  width: 48px;
  padding: 6px
}

.cart-section tbody tr td .mobile-cart-content .col,
.wishlist-section tbody tr td .mobile-cart-content .col {
  -ms-flex-item-align: center;
  align-self: center;
  margin-left: 10px;
  margin-right: 10px
}

.cart-section tbody tr td .mobile-cart-content h2,
.wishlist-section tbody tr td .mobile-cart-content h2 {
  font-size: 20px
}

.cart-section tfoot tr th,
.wishlist-section tfoot tr th {
  padding-top: 35px;
  text-align: right
}

.cart-section tfoot tr td,
.wishlist-section tfoot tr td {
  padding-top: 35px;
  padding-bottom: 0;
  text-align: right;
  border: none;
  padding-right: 63px
}

.cart-section tfoot tr td h2,
.wishlist-section tfoot tr td h2 {
  font-size: 24px;
  margin-bottom: 0
}

.cart-section tfoot tr td:first-child,
.wishlist-section tfoot tr td:first-child {
  width: 85%;
  font-size: 15px;
  padding-right: 10px;
  text-transform: capitalize;
  font-weight: 700
}

.cart_counter {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #f8f8f8;
  padding: 15px;
  font-size: 14px;
  color: #2c2c2c;
  font-weight: bold;
  border-radius: 0;
  text-align: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-bottom: 20px;
  -webkit-box-shadow: 0 0 4px 0 #e4e4e4;
  box-shadow: 0 0 4px 0 #e4e4e4
}

.cart_counter .countdownholder span {
  padding-left: 8px;
  color: var(--theme-color);
  padding-right: 6px
}

.cart_counter .cart_checkout {
  padding: 3px 8px;
  margin-left: 10px
}

.blog-detail-page .blog-detail p:last-child {
  margin-bottom: 0
}

.blog-detail-page .blog-detail img {
  margin-bottom: 40px
}

.blog-detail-page .blog-detail h3 {
  color: #222;
  font-weight: 700;
  margin-bottom: 20px;
  text-transform: uppercase
}

.blog-detail-page .blog-detail p {
  color: #777;
  line-height: 1.8
}

.blog-detail-page .blog-detail .post-social {
  color: #777;
  text-align: left;
  padding-bottom: 15px;
  border-bottom: 1px solid #ddd;
  margin-bottom: 15px
}

.blog-detail-page .blog-detail .post-social li+li {
  padding-left: 15px;
  margin-left: 15px;
  border-left: 1px solid #ddd
}

.blog-detail-page .blog-advance>div:last-child p:last-child {
  margin-bottom: 0
}

.blog-detail-page .blog-advance img,
.blog-detail-page .blog-advance .bg-size {
  margin-bottom: 25px;
  margin-top: 25px
}

.blog-detail-page .blog-advance ul {
  list-style-type: decimal;
  list-style-position: inside;
  line-height: 2.5;
  font-size: 15px
}

.blog-detail-page .blog-advance ul li {
  display: list-item;
  font-weight: 700
}

.blog-detail-page .blog-advance p {
  line-height: 1.8
}

.blog-detail-page .comment-section {
  border-bottom: 1px solid #ddd
}

.blog-detail-page .comment-section li {
  padding-top: 60px;
  padding-bottom: 60px;
  border-top: 1px solid #ddd
}

.blog-detail-page .comment-section li img {
  height: 65px;
  width: 65px;
  margin-right: 20px;
  border-radius: 100%
}

.blog-detail-page .comment-section li h6 {
  color: #444;
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: 5px
}

.blog-detail-page .comment-section li h6 span {
  font-size: 13px;
  font-weight: 500;
  text-transform: capitalize;
  color: #777;
  margin-left: 20px
}

.blog-detail-page .comment-section li p {
  line-height: 1.8;
  margin-bottom: 0
}

.blog-detail-page .blog-contact h2 {
  margin-bottom: 30px;
  margin-top: -5px
}

.blog-detail-page .blog-contact .theme-form label {
  text-transform: capitalize;
  color: #333;
  font-size: 16px;
  font-weight: 700
}

.blog-detail-page .blog-contact .theme-form input,
.blog-detail-page .blog-contact .theme-form textarea {
  border-color: #ddd;
  font-size: 14px;
  padding: 17px 25px;
  margin-bottom: 30px
}

.blog-page .blog-sidebar h6 {
  margin-bottom: 0;
  line-height: 20px;
  color: #333;
  font-weight: 700
}

.blog-page .blog-sidebar p {
  line-height: 20px;
  margin-bottom: 0;
  color: #555
}

.blog-page .blog-sidebar h4 {
  color: #333;
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 20px;
  line-height: 20px;
  letter-spacing: 1px
}

.blog-page .blog-sidebar .theme-card {
  padding: 30px;
  background-color: #f9f9f9
}

.blog-page .blog-sidebar .theme-card .recent-blog li .media img {
  width: 100px;
  margin-right: 10px
}

.blog-page .blog-sidebar .theme-card .recent-blog li+li {
  margin-top: 30px
}

.blog-page .blog-sidebar .theme-card .popular-blog li .blog-date {
  background-color: var(--theme-color);
  height: 70px;
  width: 70px;
  margin: 0 15px 0 0;
  padding: 15px
}

.blog-page .blog-sidebar .theme-card .popular-blog li .blog-date span {
  display: block;
  text-align: center;
  color: #fff;
  text-transform: uppercase
}

.blog-page .blog-sidebar .theme-card .popular-blog li+li {
  margin-top: 30px
}

.blog-page .blog-sidebar .theme-card .popular-blog li p {
  margin-top: 10px
}

.blog-page .blog-sidebar .theme-card+.theme-card {
  margin-top: 30px
}

.blog-page .blog-media {
  margin-bottom: 30px
}

.blog-page .blog-media:last-child {
  margin-bottom: 0
}

.blog-page .blog-media .blog-right {
  height: 100%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex
}

.blog-page .blog-media .blog-right h6 {
  color: var(--theme-color);
  text-transform: uppercase;
  margin-bottom: 10px
}

.blog-page .blog-media .blog-right h4 {
  color: #333;
  font-weight: 700;
  line-height: 1.5;
  margin-bottom: 15px
}

.blog-page .blog-media .blog-right p {
  line-height: 1.8;
  margin-bottom: 0;
  letter-spacing: 0.04em
}

.blog-page .blog-media .blog-right ul {
  color: #777;
  margin-bottom: 15px
}

.blog-page .blog-media .blog-right ul li {
  font-size: 14px;
  line-height: 1.5
}

.blog-page .blog-media .blog-right ul li i {
  padding-right: 3px
}

.blog-page .blog-media .blog-right ul li+li {
  padding-left: 15px;
  margin-left: 15px;
  border-left: 1px solid #ddd
}


.slider-right-nav.border-nav .slick-slide>div {
  padding: 4px
}


.pro_sticky_info {
  border: 1px solid #ddd;
  padding: 20px
}

.is_stuck {
  margin-top: 80px
}

.image-scroll div>div {
  padding-bottom: 20px
}

.image-scroll div>div:last-child {
  padding: 0
}

.sale-label {
  display: inline-block;
  background-color: var(--theme-color2);
  padding: 6px 17px;
  border-radius: 8px;
  color: #fff
}

.product-right .product-count {
  background-color: #f8f8f8;
  padding: 10px 12px;
  margin-bottom: 12px
}

.product-right .product-count img {
  margin-right: 6px
}

.product-right .product-count ul {
  margin-bottom: -4px
}

.product-right .product-count ul li {
  margin-bottom: 4px
}

.product-right .product-count ul li:first-child {
  margin-right: 14px
}

.product-right p {
  margin-bottom: 0;
  line-height: 1.5em
}

.product-right .product-title {
  color: #222;
  text-transform: capitalize;
  font-weight: 700;
  margin-bottom: 3px;
  font-size: 16px
}

.product-right .shipping-info li {
  display: block;
  font-size: 16px;
  color: #777;
  line-height: 1.8
}

.product-right .border-product {
  padding-top: 15px;
  padding-bottom: 20px;
  border-top: 1px dashed #ddd
}

.product-right h2 {
  text-transform: uppercase;
  margin-bottom: 15px;
  font-size: 25px;
  line-height: 1.2em
}

.product-right .price-detail span {
  font-size: 16px;
  color: var(--theme-color);
  padding-left: 10px
}

.product-right h3 {
  font-size: 26px;
  color: #222;
  margin-bottom: 15px
}

.product-right h4 {
  font-size: 16px;
  margin-bottom: 7px
}

.product-right h4 del {
  color: #777
}

.product-right h4 span {
  padding-left: 5px;
  color: var(--theme-color)
}

.product-right .color-variant {
  margin-bottom: 10px
}

.product-right .color-variant li {
  height: 30px;
  width: 30px;
  cursor: pointer
}

.product-right .color-variant li.active {
  position: relative
}

.product-right .color-variant li.active:after {
  content: "";
  background-image: url("data:image/svg+xml;charset=utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'><path fill='%23000' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/></svg>");
  top: 10px;
  right: 4px;
  height: 15px;
  width: 15px;
  background-size: 70%;
  background-repeat: no-repeat;
  position: absolute;
  opacity: 0.8
}

.product-right .product-buttons {
  margin-bottom: 20px
}

.product-right .product-buttons .btn-solid,
.product-right .product-buttons .btn-outline {
  padding: 7px 25px
}

.product-right .product-buttons a:last-child {
  margin-left: 10px
}

.product-right .product-description h6 span {
  float: right
}

.product-right .product-description .qty-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 10px
}

.product-right .product-description .qty-box .input-group {
  -webkit-box-pack: unset;
  -ms-flex-pack: unset;
  justify-content: unset;
  width: unset
}

/* .product-right .product-description .qty-box .input-group .form-control {
  border-right: none
} */

.product-right .size-box {
  margin-top: 10px;
  margin-bottom: 10px
}

.product-right .size-box ul li {
  height: 35px;
  width: 35px;
  border-radius: 50%;
  margin-right: 10px;
  cursor: pointer;
  border: 1px solid #efefef;
  text-align: center
}

.product-right .size-box ul li a {
  color: #222;
  font-size: 18px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 100%
}

.product-right .size-box ul li.active {
  background-color: #efefef
}

.product-right .product-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex
}

.product-right .product-icon .product-social {
  margin-top: 5px
}

.product-right .product-icon .product-social li {
  padding-right: 30px
}

.product-right .product-icon .product-social li a {
  color: #333;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease
}

.product-right .product-icon .product-social li a i {
  font-size: 18px
}

.product-right .product-icon .product-social li a:hover {
  color: var(--theme-color)
}

.product-right .product-icon .product-social li:last-child {
  padding-right: 0
}

.product-right .product-icon .wishlist-btn {
  background-color: transparent;
  border: none
}

.product-right .product-icon .wishlist-btn i {
  border-left: 1px solid #ddd;
  font-size: 18px;
  padding-left: 10px;
  margin-left: 5px;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease
}

.product-right .product-icon .wishlist-btn span {
  padding-left: 10px;
  font-size: 18px
}

.product-right .product-icon .wishlist-btn:hover i {
  color: var(--theme-color);
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease
}

.product-right .payment-card-bottom {
  margin-top: 10px
}

.product-right .payment-card-bottom ul li {
  padding-right: 10px
}

.product-right .timer {
  margin-top: 10px;
  background-color: #f7f7f7
}

.product-right .timer p {
  color: #222
}

.product-right .rating-section {
  margin-bottom: 8px;
  margin-top: -6px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center
}

.product-right .rating-section h6 {
  margin-bottom: 0;
  margin-left: 10px;
  color: #323232;
  font-weight: 700;
  font-size: 15px
}

.product-right .rating-section .rating i {
  padding-right: 2px;
  font-size: 18px
}

.product-right .label-section {
  margin-bottom: 15px
}

.product-right .label-section .badge {
  padding: 6px 11px;
  font-size: 12px
}

.product-right .label-section .label-text {
  text-transform: capitalize;
  padding-left: 5px
}

.product-right.product-form-box {
  text-align: center;
  border: 1px solid #ddd;
  padding: 20px
}

.product-right.product-form-box .product-description .qty-box {
  margin-bottom: 5px
}

.product-right.product-form-box .product-description .qty-box .input-group {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%
}

.product-right.product-form-box .product-buttons {
  margin-bottom: 0
}

.product-right.product-form-box .timer {
  margin-bottom: 10px;
  text-align: left
}

.product-accordion .btn-link {
  color: #222
}

.product-accordion .btn-link:hover {
  text-decoration: none
}

.product-accordion .card {
  border: none
}

.product-accordion .card-body {
  width: 100%;
  height: auto
}

.product-accordion .card-header {
  padding: 5px 8px;
  border-bottom: 1px dashed rgba(0, 0, 0, 0.125);
  background-color: rgba(127, 127, 127, 0.03)
}

.product-related h2 {
  text-transform: capitalize;
  font-size: calc(18px + (25 - 18) * ((100vw - 320px) / (1920 - 320)));
  color: #222;
  padding-bottom: 20px;
  border-bottom: 1px solid #dddada;
  margin-bottom: 20px
}

.rating {
  margin-top: 0
}

.rating i {
  padding-right: 5px
}

.rating i:nth-child(-n+4) {
  color: #ffa200
}

.rating i:last-child {
  color: #ddd
}

.rating .three-star {
  padding-bottom: 5px
}

.rating .three-star i {
  color: #acacac
}

.rating .three-star i:nth-child(-n+3) {
  color: #ffd200
}

.tab-border {
  border: 1px solid #ddd;
  margin: 0;
  padding: 8px 0
}

.tab-border .nav-border {
  border-bottom: 0;
  border-right: 1px solid #ddd
}

.vertical-tab.tab-product .nav-material.nav-tabs .nav-item .nav-link,
.vertical-tab .product-full-tab .nav-material.nav-tabs .nav-item .nav-link {
  padding: 15px 20px
}

.tab-product,
.product-full-tab {
  padding-top: 30px
}

.tab-product .nav-material.nav-tabs,
.product-full-tab .nav-material.nav-tabs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap
}

.tab-product .nav-material.nav-tabs.tab-style .nav-link,
.product-full-tab .nav-material.nav-tabs.tab-style .nav-link {
  padding: 8px 10px
}

.tab-product .nav-material.nav-tabs .nav-item .nav-link,
.product-full-tab .nav-material.nav-tabs .nav-item .nav-link {
  color: #212121;
  text-align: center;
  padding: 0 15px 20px 15px;
  text-transform: uppercase;
  border: 0;
  font-weight: 600;
  font-size: 16px
}

.tab-product .nav-material.nav-tabs .nav-item .material-border,
.product-full-tab .nav-material.nav-tabs .nav-item .material-border {
  border-bottom: 2px solid var(--theme-color);
  opacity: 0
}

.tab-product .nav-material.nav-tabs .nav-link.active,
.product-full-tab .nav-material.nav-tabs .nav-link.active {
  color: var(--theme-color)
}

.tab-product .nav-material.nav-tabs .nav-link.active~.material-border,
.product-full-tab .nav-material.nav-tabs .nav-link.active~.material-border {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  opacity: 1
}

.tab-product .theme-form input,
.product-full-tab .theme-form input {
  border-color: #ddd;
  font-size: 15px;
  padding: 15px 25px;
  margin-bottom: 15px;
  height: inherit;
  text-align: left
}

.tab-product .theme-form .btn-solid,
.tab-product .theme-form .btn-outline,
.product-full-tab .theme-form .btn-solid,
.product-full-tab .theme-form .btn-outline {
  margin: 0 auto
}

.tab-product .theme-form textarea,
.product-full-tab .theme-form textarea {
  border-color: #ddd;
  font-size: 15px;
  padding: 17px 25px;
  margin-bottom: 15px;
  height: inherit
}

.tab-product .tab-content.nav-material,
.product-full-tab .tab-content.nav-material {
  padding-top: 20px
}

.tab-product .tab-content.nav-material p,
.product-full-tab .tab-content.nav-material p {
  margin-bottom: 0;
  line-height: 1.8;
  letter-spacing: 0.05em;
  font-size: 16px
}

.tab-product .product-tab-discription .part,
.product-full-tab .product-tab-discription .part {
  margin-bottom: 20px
}

.tab-product .product-tab-discription .part .inner-title,
.product-full-tab .product-tab-discription .part .inner-title {
  font-weight: 600;
  text-transform: capitalize
}

.tab-product .product-tab-discription .part:last-child,
.product-full-tab .product-tab-discription .part:last-child {
  margin-bottom: 0
}

.tab-product .title,
.product-full-tab .title {
  padding-right: 45px;
  color: var(--theme-color);
  padding-bottom: 20px
}

.tab-product .theme-slider .slick-arrow,
.product-full-tab .theme-slider .slick-arrow {
  top: -45px;
  height: auto
}

.tab-product .theme-slider .slick-arrow :before,
.product-full-tab .theme-slider .slick-arrow :before {
  color: #000;
  font-size: 18px
}

.tab-product .product-box,
.product-full-tab .product-box {
  position: relative;
  margin: 5px
}

.tab-product .product-box:hover,
.product-full-tab .product-box:hover {
  -webkit-box-shadow: 0 0 12px 0 #ddd;
  box-shadow: 0 0 12px 0 #ddd
}

.tab-product .product-box:hover .lbl-1,
.product-full-tab .product-box:hover .lbl-1 {
  opacity: 1;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease
}

.tab-product .product-box:hover .lbl-2,
.product-full-tab .product-box:hover .lbl-2 {
  opacity: 1;
  -webkit-animation: flipInY 1000ms ease-in-out;
  animation: flipInY 1000ms ease-in-out
}

.tab-product .product-box:hover .color-variant li,
.product-full-tab .product-box:hover .color-variant li {
  opacity: 1 !important;
  -webkit-animation: fadeInUp 500ms ease-in-out;
  animation: fadeInUp 500ms ease-in-out
}

.tab-product .product-box .img-block,
.product-full-tab .product-box .img-block {
  min-height: unset
}

.tab-product .product-box .cart-info,
.product-full-tab .product-box .cart-info {
  position: absolute;
  padding: 10px 0;
  top: 25%;
  right: 15px;
  width: 40px;
  margin-right: 0
}

.tab-product .product-box .cart-info i,
.product-full-tab .product-box .cart-info i {
  padding-right: 0
}

.tab-product .product-box .cart-info a,
.tab-product .product-box .cart-info button,
.product-full-tab .product-box .cart-info a,
.product-full-tab .product-box .cart-info button {
  color: #333;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  background-color: #fff;
  height: 35px;
  width: 35px;
  margin: 7px 0;
  border-radius: 100%;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border: 0;
  -webkit-box-shadow: 0 0 12px 0 #ddd;
  box-shadow: 0 0 12px 0 #ddd
}

.tab-product .product-box .cart-info a :hover,
.tab-product .product-box .cart-info button :hover,
.product-full-tab .product-box .cart-info a :hover,
.product-full-tab .product-box .cart-info button :hover {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  color: var(--theme-color)
}

.tab-product .product-box .lbl-1,
.product-full-tab .product-box .lbl-1 {
  background-color: var(--theme-color);
  padding: 2px 20px 2px 10px;
  display: inline-block;
  text-align: center;
  color: #fff;
  position: absolute;
  left: 0;
  top: 15px;
  font-size: 14px;
  line-height: 1.5;
  opacity: 0
}

.tab-product .product-box .lbl-1:before,
.product-full-tab .product-box .lbl-1:before {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  width: 0;
  height: 0;
  border-top: 12px solid var(--theme-color);
  border-bottom: 13px solid var(--theme-color);
  border-right: 7px solid #fff
}

.tab-product .product-box .lbl-2,
.product-full-tab .product-box .lbl-2 {
  font-size: 14px;
  top: 15px;
  position: absolute;
  right: 10px;
  color: #333333;
  font-weight: 600;
  text-transform: capitalize;
  opacity: 0
}

.tab-product .product-box a,
.product-full-tab .product-box a {
  color: #0072bb;
  font-size: 15px;
  font-weight: 700;
  letter-spacing: 1px
}

.tab-product .product-box .color-variant,
.product-full-tab .product-box .color-variant {
  position: absolute;
  top: -35px;
  width: 100%
}

.tab-product .product-box .slick-slide img,
.product-full-tab .product-box .slick-slide img {
  display: block
}

.tab-product .product-box .product-details,
.product-full-tab .product-box .product-details {
  position: relative
}

.tab-product .product-box .product-details .color-variant,
.product-full-tab .product-box .product-details .color-variant {
  position: absolute;
  top: -35px;
  width: 100%
}

.tab-product .product-box .product-details .color-variant li,
.product-full-tab .product-box .product-details .color-variant li {
  opacity: 0;
  display: inline-block;
  height: 15px;
  width: 15px;
  border-radius: 100%;
  margin: 0 3px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  cursor: pointer
}

.tab-product .product-box .product-details h6,
.product-full-tab .product-box .product-details h6 {
  color: #333333;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: capitalize
}

.tab-product .product-box .product-details .price,
.product-full-tab .product-box .product-details .price {
  padding-bottom: 10px;
  font-size: 16px;
  color: var(--theme-color)
}

.product-full-tab {
  padding-top: 70px
}

.product-load-more .col-grid-box {
  display: none
}

.load-more-sec {
  margin-top: 40px;
  text-align: center
}

.load-more-sec a {
  font-size: 18px;
  text-transform: uppercase;
  display: block;
  padding: 10px 0;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  width: 100%;
  color: var(--theme-color)
}

.product_image_4>div:nth-last-child(-n+2) {
  margin-top: 25px
}

.video-icon {
  position: relative
}

.video-icon img {
  opacity: 0.8
}

.video-icon i {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-size: 24px
}

.video-product iframe {
  width: 100%;
  height: 44vh;
  margin-top: 90px
}

.top-filter {
  position: absolute;
  z-index: 1;
  top: 80px;
  left: 0;
  width: 100%;
  display: none
}

.top-filter ::-webkit-scrollbar {
  width: 4px
}

.top-filter ::-webkit-scrollbar-track {
  background: #f1f1f1
}

.top-filter ::-webkit-scrollbar-thumb {
  background: #888
}

.top-filter ::-webkit-scrollbar-thumb:hover {
  background: #555
}

.top-filter .collection-collapse-block {
  padding-bottom: 0
}

.top-filter .collection-collapse-block .collapse-block-title:after {
  display: none
}

.top-filter .button_bottom {
  padding-bottom: 20px
}

.top-filter .collection-collapse-block-content {
  max-height: 210px;
  overflow-y: auto;
  overflow-x: hidden
}

.filter-bottom-title {
  position: relative;
  cursor: pointer
}

.filter-bottom-title:after {
  position: absolute;
  display: block;
  top: 0;
  right: -3px;
  content: "\f0d7";
  font-family: FontAwesome;
  font-weight: 900;
  color: #969696
}

.sticky-bottom-cart {
  position: fixed;
  bottom: -100px;
  width: 100vw;
  z-index: 9;
  background-color: white;
  padding: 12px 0;
  -webkit-box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.16);
  box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.16);
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease
}

.sticky-bottom-cart .cart-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center
}

.sticky-bottom-cart .product-image {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center
}

.sticky-bottom-cart .product-image img {
  width: 60px;
  height: 70px;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: top;
  object-position: top
}

.sticky-bottom-cart .product-image .content {
  margin-left: 12px
}

.sticky-bottom-cart .product-image .content h5,
.sticky-bottom-cart .product-image .content h6 {
  margin-bottom: 0
}

.sticky-bottom-cart .product-image .content h5 {
  font-size: 18px;
  font-weight: 600
}

.sticky-bottom-cart .product-image .content h6 {
  font-size: 15px;
  color: #222
}

.sticky-bottom-cart .product-image .content h6 del {
  margin-left: 7px
}

.sticky-bottom-cart .product-image .content h6 span {
  color: var(--theme-color);
  margin-left: 5px
}

.sticky-bottom-cart .selection-section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0 auto
}

.sticky-bottom-cart .selection-section .form-control {
  background-color: #f8f8f8;
  border: 1px solid #f8f8f8;
  text-transform: uppercase
}

.sticky-bottom-cart .selection-section .form-control:focus {
  -webkit-box-shadow: none;
  box-shadow: none
}

.sticky-bottom-cart .selection-section .form-group {
  width: 250px
}

.sticky-bottom-cart .selection-section .form-group:nth-child(2) {
  margin-left: 40px
}

.stickyCart {
  padding-bottom: 94px
}

.stickyCart .sticky-bottom-cart {
  bottom: 0;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease
}

.product-center-page .product-right.right-side {
  text-align: right
}

.product-center-page .product-right.right-side .timer {
  padding-right: 40px
}

.product-center-page .product-description .qty-box {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end
}

.addeffect-section .error-message {
  display: none;
  color: red
}

.addeffect-section.cartMove .error-message {
  display: block
}

.addeffect-section.cartMove .size-box {
  -webkit-animation: buttons-shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  animation: buttons-shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both
}

.custom-variations ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-left: -10px;
  margin-right: -10px
}

.custom-variations ul li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid #ddd;
  margin: 0 10px;
  padding: 5px;
  text-align: center;
  cursor: pointer
}

.custom-variations ul li img {
  width: 50px;
  height: 70px;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center
}

.custom-variations ul li .product-content {
  padding-left: 15px;
  padding-right: 25px
}

.custom-variations ul li .product-content h6 {
  margin-bottom: 0;
  color: #222;
  font-weight: 600;
  font-size: 15px
}

.custom-variations ul li .product-content span {
  color: #888;
  font-size: 15px
}

.custom-variations ul li.active {
  border-color: var(--theme-color)
}

@-webkit-keyframes buttons-shake {

  10%,
  90% {
    -webkit-transform: translate3d(-1px, 0, 0);
    transform: translate3d(-1px, 0, 0)
  }

  20%,
  80% {
    -webkit-transform: translate3d(2px, 0, 0);
    transform: translate3d(2px, 0, 0)
  }

  30%,
  50%,
  70% {
    -webkit-transform: translate3d(-2px, 0, 0);
    transform: translate3d(-2px, 0, 0)
  }

  40%,
  60% {
    -webkit-transform: translate3d(2px, 0, 0);
    transform: translate3d(2px, 0, 0)
  }
}

@keyframes buttons-shake {

  10%,
  90% {
    -webkit-transform: translate3d(-1px, 0, 0);
    transform: translate3d(-1px, 0, 0)
  }

  20%,
  80% {
    -webkit-transform: translate3d(2px, 0, 0);
    transform: translate3d(2px, 0, 0)
  }

  30%,
  50%,
  70% {
    -webkit-transform: translate3d(-2px, 0, 0);
    transform: translate3d(-2px, 0, 0)
  }

  40%,
  60% {
    -webkit-transform: translate3d(2px, 0, 0);
    transform: translate3d(2px, 0, 0)
  }
}

.show-360 #product360_view {
  opacity: 1;
  visibility: visible;
  pointer-events: all
}

.image-360 {
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 20px;
  z-index: 1
}

.product-image-360 {
  position: relative;
  overflow: hidden;
  margin: 0 auto;
  cursor: pointer
}

.product-image-360 .nav_bar {
  position: absolute;
  bottom: 40px;
  left: 50%;
  margin-left: -67.5px;
  z-index: 11;
  background-color: #fff;
  -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15)
}

.product-image-360 .nav_bar a {
  display: inline-block;
  width: 45px;
  height: 45px;
  line-height: 48px;
  text-align: center;
  text-decoration: none;
  color: #aaa
}

.product-image-360 .product-images-item {
  display: none;
  list-style: none;
  margin: 0;
  padding: 0
}

.product-image-360 .product-images-item img.previous-image {
  visibility: hidden
}

.product-image-360 .product-images-item img.current-image {
  visibility: visible
}

.product-image-360 .product-images-item img {
  position: absolute;
  top: 50%;
  vertical-align: middle;
  height: auto;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  max-width: 100%
}

.product-image-360 .spinner {
  background: #fff;
  position: relative;
  z-index: 999;
  width: 800px;
  height: 90px;
  margin-top: 0 !important;
  display: inline-block
}

.product-image-360 .spinner span {
  width: 60px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  display: block;
  margin: 0 auto;
  color: #000;
  font-weight: bolder;
  background: rgba(255, 255, 255, 0.7);
  -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
  border-radius: 50%;
  margin-top: 20px
}

.custom_stop {
  display: none !important
}

.play-video .custom_play {
  display: none !important
}

.play-video .custom_stop {
  display: inline-block !important
}

.quick-view {
  width: 100%;
  max-width: 1080px;
  max-height: 600px;
  position: relative
}

.checkout-page .checkout-title {
  margin-bottom: 25px
}

.checkout-page .checkout-title h3 {
  color: #444;
  font-weight: 700
}

.checkout-page .checkout-form .checkout-details {
  position: relative;
  background-color: #f9f9f9;
  border: none;
  padding: 40px
}

.checkout-page .checkout-form .check-out .form-group:last-child {
  margin-bottom: -5px
}

.checkout-page .checkout-form .check-out .form-group:last-child label {
  margin-bottom: -5px
}

.checkout-page .checkout-form .form-group {
  position: relative;
  margin-bottom: 25px
}

.checkout-page .checkout-form .form-group h3 {
  color: #444;
  font-weight: 700;
  margin-bottom: 30px;
  margin-top: 30px
}

.checkout-page .checkout-form .form-group .field-label {
  line-height: 24px;
  text-transform: capitalize;
  color: #333333;
  margin-bottom: 10px;
  font-weight: 700
}

.checkout-page .checkout-form .form-group .field-label span {
  font-size: 16px;
  color: #222;
  font-weight: 600
}

.checkout-page .checkout-form .form-group label {
  color: #777
}

.checkout-page .checkout-form select {
  cursor: pointer;
  -webkit-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  -moz-appearance: none;
  /* background: url(../images/dropdown.png) no-repeat 95% */
}

.checkout-page .checkout-form input[type="text"],
.checkout-page .checkout-form input[type="email"],
.checkout-page .checkout-form input[type="password"],
.checkout-page .checkout-form input[type="tel"],
.checkout-page .checkout-form input[type="number"],
.checkout-page .checkout-form input[type="url"] {
  width: 100%;
  padding: 0 22px;
  height: 45px;
  border: 1px solid #ddd
}

.checkout-page .checkout-form select,
.checkout-page .checkout-form textarea {
  width: 100%;
  padding: 0 22px;
  height: 45px;
  border: 1px solid #ddd
}

.checkout-page .check-box {
  line-height: 24px;
  font-size: 14px;
  font-weight: normal;
  padding-top: 5px
}

.checkout-page .check-box label {
  position: relative;
  top: -1px;
  font-weight: normal;
  padding: 0;
  font-size: 16px;
  cursor: pointer;
  color: #333
}

.checkout-page .lower-content {
  margin-top: 30px
}

.checkout-page .lower-content .order-column {
  margin-bottom: 40px
}

.order-box {
  position: relative;
  margin-bottom: 50px
}

.order-box .title-box {
  position: relative;
  padding-bottom: 25px;
  color: #444;
  font-weight: 600;
  font-size: 22px;
  border-bottom: 1px solid #ededed;
  margin-bottom: 20px
}

.order-box .title-box span {
  position: relative;
  width: 35%;
  float: right;
  line-height: 1.2em
}

.order-box .qty {
  position: relative;
  border-bottom: 1px solid #ededed;
  margin-bottom: 30px
}

.order-box .qty li {
  position: relative;
  display: block;
  font-size: 15px;
  color: #444;
  line-height: 20px;
  margin-bottom: 20px
}

.order-box .qty li span {
  float: right;
  font-size: 18px;
  line-height: 20px;
  color: #232323;
  font-weight: 400;
  width: 35%
}

.order-box .sub-total {
  position: relative;
  border-bottom: 1px solid #ddd;
  margin-bottom: 30px
}

.order-box .sub-total li {
  position: relative;
  display: inline-block;
  font-size: 16px;
  font-weight: 600;
  color: #333;
  line-height: 20px;
  margin-bottom: 20px;
  width: 100%
}

.order-box .sub-total li .count {
  position: relative;
  font-size: 18px;
  line-height: 20px;
  color: var(--theme-color);
  font-weight: 400;
  width: 35%;
  float: right
}

.order-box .sub-total .shopping-option label {
  position: relative;
  font-size: 16px;
  line-height: 32px;
  padding-left: 10px;
  color: #6f6f6f
}

.order-box .sub-total .shipping {
  width: 35%;
  float: right
}

.order-box .total {
  position: relative;
  margin-bottom: 40px
}

.order-box .total li {
  position: relative;
  display: block;
  font-weight: 400;
  color: #333;
  line-height: 20px;
  margin-bottom: 10px;
  font-size: 18px
}

.order-box .total li .count {
  position: relative;
  font-size: 18px;
  line-height: 20px;
  color: var(--theme-color);
  font-weight: 400
}

.order-box .total li span {
  float: right;
  font-size: 15px;
  line-height: 20px;
  color: #444;
  font-weight: 400;
  width: 35%;
  display: block
}

.payment-box {
  position: relative
}

.payment-box .upper-box {
  position: relative
}

.payment-box .payment-options {
  position: relative;
  margin-top: 20px;
  margin-bottom: 30px
}

.payment-box .payment-options li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 15px
}

.payment-box .payment-options li .radio-option {
  position: relative
}

.payment-box .payment-options li .radio-option label {
  position: relative;
  padding-left: 30px;
  text-transform: capitalize;
  color: #6f6f6f;
  cursor: pointer;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 0
}

.payment-box .payment-options li .radio-option input[type="radio"] {
  position: absolute;
  left: 0;
  top: 5px
}

.payment-box .payment-options li .radio-option label .small-text {
  position: relative;
  display: none;
  font-size: 15px;
  line-height: 25px;
  font-weight: 300;
  color: #666666;
  margin-top: 10px
}

.payment-box .payment-options li .radio-option label img {
  position: relative;
  display: block;
  max-width: 100%;
  margin-left: -30px;
  margin-top: 5px
}

.dashboard-left .block-title h2 {
  font-size: 24px;
  padding-bottom: 20px
}

.dashboard-left .block-content {
  padding: 10px 0;
  background-color: #f8f8f8
}

.dashboard-left .block-content ul li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  padding: 0.5rem 1rem;
  border-right: 2px solid transparent
}

.dashboard-left .block-content ul li.active {
  color: var(--theme-color);
  border-right: 2px solid var(--theme-color)
}

.dashboard-left .block-content ul li.active a {
  color: var(--theme-color)
}

.dashboard-left .block-content ul li:before {
  display: inline-block;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 30px;
  height: 30px;
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  margin-top: 3px
}

.dashboard-left .block-content ul li a {
  color: black
}

.dashboard-left .block-content ul li:hover {
  color: var(--theme-color);
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease
}

.dashboard-left .block-content ul li:hover a {
  color: var(--theme-color);
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease
}

.padding-cls {
  padding-left: 50px;
  padding-right: 50px
}

.dashboard-right p {
  color: #5f5f5f;
  line-height: 20px
}

.dashboard-right .dashboard {
  border: 1px solid #ddd;
  padding: 30px
}

.dashboard-right .dashboard .page-title h2 {
  font-size: 22px;
  margin-bottom: 15px
}

.dashboard-right .dashboard .welcome-msg p {
  margin-bottom: 0
}

.dashboard .box-head h2 {
  font-size: 22px;
  margin: 20px 0 0;
  text-transform: capitalize;
  color: #333
}

.dashboard .box a {
  color: var(--theme-color)
}

.dashboard .box h6 {
  margin-bottom: 0
}

.dashboard .box .box-title {
  border-bottom: 1px solid #ddd;
  display: inline-block;
  margin-bottom: 5px;
  padding: 12px 0;
  position: relative;
  width: 100%
}

.dashboard .box .box-title h3 {
  font-size: 16px;
  margin: 0;
  text-transform: capitalize;
  color: #333
}

.dashboard .box .box-title>a {
  position: absolute;
  right: 0;
  top: 12px;
  color: var(--theme-color)
}

.dashboard .box address {
  margin-bottom: 0
}

.box-content h6 {
  font-size: 14px;
  line-height: 25px !important;
  margin-bottom: 0
}

.box-content a {
  color: var(--theme-color)
}

.account-sidebar {
  display: none;
  cursor: pointer;
  background-color: var(--theme-color);
  color: #fff;
  width: 30%;
  font-size: 16px;
  text-transform: uppercase;
  text-align: center;
  font-weight: 700;
  margin-bottom: 20px;
  padding: 5px
}

.account-sidebar a {
  color: #fff
}

.custom-badge {
  padding: 6px 10px;
  font-size: 12px
}

.success-text {
  text-align: center
}

.success-text .star {
  position: absolute;
  -webkit-animation: grow 3s infinite;
  animation: grow 3s infinite;
  fill: var(--theme-color);
  opacity: 0
}

.success-text .star:nth-child(1) {
  width: 12px;
  height: 12px;
  left: 12px;
  top: 16px
}

.success-text .star:nth-child(2) {
  width: 18px;
  height: 18px;
  left: 168px;
  top: 84px
}

.success-text .star:nth-child(3) {
  width: 10px;
  height: 10px;
  left: 32px;
  top: 162px
}

.success-text .star:nth-child(4) {
  width: 20px;
  height: 20px;
  left: 82px;
  top: -12px
}

.success-text .star:nth-child(5) {
  width: 14px;
  height: 14px;
  left: 125px;
  top: 162px
}

.success-text .star:nth-child(6) {
  width: 10px;
  height: 10px;
  left: 16px;
  top: 16px
}

.success-text .star:nth-child(1) {
  -webkit-animation-delay: 1.5s;
  animation-delay: 1.5s
}

.success-text .star:nth-child(2) {
  -webkit-animation-delay: 3s;
  animation-delay: 3s
}

.success-text .star:nth-child(3) {
  -webkit-animation-delay: 4.5s;
  animation-delay: 4.5s
}

.success-text .star:nth-child(4) {
  -webkit-animation-delay: 6s;
  animation-delay: 6s
}

.success-text .star:nth-child(5) {
  -webkit-animation-delay: 7.5s;
  animation-delay: 7.5s
}

.success-text .star:nth-child(6) {
  -webkit-animation-delay: 9s;
  animation-delay: 9s
}

.success-text .checkmark {
  position: relative;
  padding: 30px;
  -webkit-animation: checkmark 5m cubic-bezier(0.42, 0, 0.275, 1.155) both;
  animation: checkmark 5m cubic-bezier(0.42, 0, 0.275, 1.155) both;
  display: inline-block;
  -webkit-transform: scale(0.8);
  transform: scale(0.8);
  margin: -20px
}

.success-text .checkmark__check {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 10;
  -webkit-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0);
  fill: white
}

.success-text .checkmark__background {
  fill: var(--theme-color);
  -webkit-animation: rotate 35s linear both infinite;
  animation: rotate 35s linear both infinite
}

.success-text i {
  font-size: 50px;
  color: #4ead4e
}

.success-text h2 {
  margin-top: 10px;
  margin-bottom: 15px
}

.success-text p {
  font-size: 18px;
  text-transform: capitalize
}

.success-text.order-fail i {
  color: var(--theme-color)
}

.order-success-sec {
  background-color: #f9f9f9;
  padding: 25px
}

.order-success-sec h4 {
  font-weight: 700;
  text-transform: capitalize;
  font-size: 16px
}

.order-success-sec .order-detail li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  text-transform: capitalize;
  line-height: 24px;
  font-size: 16px;
  margin-bottom: 3px;
  color: #777
}

.order-success-sec .payment-mode p {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 0;
  letter-spacing: 0.05em
}

.delivery-sec {
  margin-bottom: -5px;
  padding: 0;
  margin-top: 20px;
  background-color: transparent
}

.delivery-sec h3 {
  color: #000000;
  text-transform: capitalize;
  font-size: 18px;
  margin-bottom: 0
}

.delivery-sec h3 span {
  font-weight: 700;
  margin-left: 5px
}

.delivery-sec a {
  font-size: 16px;
  text-transform: capitalize;
  margin-top: 5px;
  display: block;
  font-weight: 600
}

.product-order {
  margin-top: -15px
}

.product-order h3 {
  font-weight: 700;
  text-transform: uppercase;
  color: #000
}

.product-order .product-order-detail {
  margin-top: 15px
}

.product-order .product-order-detail img {
  height: 100px
}

.product-order .product-order-detail .order_detail {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center
}

.product-order .product-order-detail .order_detail h4 {
  margin-bottom: 5px;
  text-transform: capitalize;
  font-weight: 400;
  color: #777;
  font-size: 14px
}

.product-order .product-order-detail .order_detail h5 {
  text-transform: capitalize;
  margin-bottom: 0;
  font-weight: 600;
  font-size: 16px
}

.product-order .total-sec {
  border-top: 1px dashed #ddd;
  border-bottom: 1px dashed #ddd;
  margin: 15px 0;
  padding: 15px 0
}

.product-order .total-sec ul li {
  font-size: 16px;
  display: block;
  text-transform: capitalize;
  margin-bottom: 8px;
  color: #777
}

.product-order .total-sec ul li span {
  float: right;
  color: #000
}

.product-order .final-total h3 {
  display: block;
  margin-bottom: 0;
  font-size: 18px
}

.product-order .final-total h3 span {
  float: right
}

.compare-section .compare-part {
  position: relative
}

.compare-section .compare-part .close-btn {
  background-color: transparent;
  border: none;
  font-size: 25px;
  position: absolute;
  top: 0;
  right: 0
}

.compare-section .compare-part .img-secton {
  padding: 25px 15px 15px;
  text-align: center
}

.compare-section .compare-part .img-secton img,
.compare-section .compare-part .img-secton .bg-size {
  width: 50%;
  margin: 0 auto
}

.compare-section .compare-part .img-secton a h5 {
  margin-bottom: 5px;
  text-transform: capitalize;
  margin-top: 10px;
  font-weight: 600;
  color: black
}

.compare-section .compare-part .img-secton h5 {
  margin-bottom: 0;
  line-height: 1.2;
  color: rgba(0, 0, 0, 0.7)
}

.compare-section .compare-part .detail-part .title-detail {
  background-color: #f7f8fa;
  text-align: center;
  padding: 8px 0;
  border-top: 1px solid #dbdbdb;
  border-bottom: 1px solid #dbdbdb
}

.compare-section .compare-part .detail-part .title-detail h5 {
  margin-bottom: 0;
  text-transform: uppercase;
  font-weight: 600
}

.compare-section .compare-part .detail-part .inner-detail {
  padding: 15px;
  text-align: center
}

.compare-section .compare-part .detail-part .inner-detail p {
  margin-bottom: 0;
  line-height: 1.2;
  letter-spacing: 0.05em
}

.compare-section .compare-part .btn-part {
  text-align: center;
  padding: 15px;
  border-top: 1px solid #ddd
}

.compare-section .compare-part .btn-part .btn-solid,
.compare-section .compare-part .btn-part .btn-outline {
  padding: 5px 10px
}

.compare-section .slick-slide>div {
  border: 1px solid #ddd;
  border-left: none
}

.compare-section .slick-slide:first-child {
  border-left: 1px solid #ddd
}

.compare-padding {
  padding-bottom: 50px
}

.compare-padding h2 {
  margin-bottom: 20px
}

.compare-lable {
  position: absolute;
  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase;
  -webkit-writing-mode: vertical-rl;
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
  top: 20px;
  left: 16px;
  letter-spacing: 0.1em;
  z-index: 1
}

.compare-page .table-wrapper {
  padding-bottom: 0
}

.compare-page .table-wrapper .table {
  border: 1px solid #eee;
  text-transform: capitalize;
  color: #222
}

.compare-page .table-wrapper .table .product-name {
  width: 15%
}

.compare-page .table-wrapper .table .featured-image {
  width: 185px
}

.compare-page .table-wrapper .table td {
  border-top: none
}

.compare-page .table-wrapper .table thead .th-compare td {
  font-size: 14px;
  font-weight: 700;
  background: #f8f8f8;
  border-right: 1px solid #eee;
  border-bottom-color: #ddd
}

.compare-page .table-wrapper .table thead .th-compare th {
  text-align: left;
  border-bottom: 1px solid #eee;
  border-right: 1px solid #eee
}

.compare-page .table-wrapper .table thead .th-compare th .remove-compare {
  float: left;
  border: none;
  background: transparent;
  padding: 0;
  font-size: 14px;
  font-weight: 500
}

.compare-page .table-wrapper .table tbody tr th {
  background: #f8f8f8
}

.compare-page .table-wrapper .table tbody tr td {
  border-right: 1px solid #eee;
  position: relative
}

.compare-page .table-wrapper .table tbody tr th,
.compare-page .table-wrapper .table tbody tr td {
  padding: 15px;
  vertical-align: top;
  border-bottom: 1px solid #eee;
  border-top: 1px solid #eee;
  border-right: 1px solid #eee
}

.compare-page .table-wrapper .table tbody tr .grid-link__title {
  font-size: 15px
}

.compare-page .table-wrapper .table tbody tr .product_price {
  margin: 15px 0
}

.compare-page .table-wrapper .table tbody tr .product_price span.money {
  padding-left: 5px
}

.compare-page .table-wrapper .table tbody tr p {
  color: #222;
  font-size: 13px;
  line-height: 20px;
  margin: 0
}

.compare-page .table-wrapper .table tbody tr .add-to-cart,
.compare-page .table-wrapper .table tbody tr .select-option-cls {
  padding: 6px 10px
}

.typography_section .typography-box {
  margin-bottom: 30px
}

.typography_section .typography-box p {
  line-height: 22px
}

.typography_section .typography-box .headings {
  border-bottom: 1px solid #f1f1f1;
  margin-bottom: 20px;
  background-color: rgba(247, 247, 247, 0.5);
  padding: 8px 12px
}

.typography_section .typography-box .headings h3 {
  text-transform: capitalize;
  color: #2d2d2d;
  margin-bottom: 0;
  font-weight: 400;
  font-size: 20px
}

.typography_section .typography-box .headings span {
  color: #777
}

.typography_section .typography-box .headings span code {
  color: #777
}

.typography_section .typography-box .typo-content.heading_content h1,
.typography_section .typography-box .typo-content.heading_content h2,
.typography_section .typography-box .typo-content.heading_content h3,
.typography_section .typography-box .typo-content.heading_content h4,
.typography_section .typography-box .typo-content.heading_content h5,
.typography_section .typography-box .typo-content.heading_content h6 {
  font-weight: 400;
  color: black;
  text-transform: uppercase;
  line-height: 1;
  margin-bottom: 10px
}

.typography_section .typography-box .typo-content .sub-title {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 5px;
  margin-bottom: 8px;
  font-size: 18px;
  color: #222;
  text-transform: capitalize
}

.typography_section .typography-box .typo-content.product-pagination {
  border: none;
  margin: 0
}

.typography_section .typography-box .typo-content.product-pagination .pagination .page-item {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd
}

.typography_section .typography-box .typo-content.product-pagination .pagination .page-item a {
  padding: 10px 14px
}

.typography_section .typography-box .typo-content.input_button input {
  margin-bottom: 10px
}

.typography_section .typography-box .typo-content.input_button label {
  font-size: 16px;
  text-transform: capitalize;
  padding-left: 5px
}

.typography_section .typography-box .typo-content.loader-typo {
  width: 100%;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex
}

.typography_section .typography-box .typo-content.loader-typo .pre-loader {
  position: relative;
  width: 50px;
  height: 50px;
  background-color: var(--theme-color);
  border-radius: 50%
}

.typography_section .typography-box .typo-content.loader-typo .pre-loader:after {
  content: "";
  position: absolute;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  border: 0 solid white;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-animation: loading 1000ms ease-out forwards infinite;
  animation: loading 1000ms ease-out forwards infinite;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease
}

.typography_section .typography-box .typo-content .footer-social {
  margin-top: 0;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center
}

.typography_section .typography-box:last-child {
  margin-bottom: 0
}

.typography_section code {
  background-color: #f7f7f7;
  padding: 3px;
  margin: 0 3px;
  border-radius: 2px
}

.profile-section {
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  padding: 15px
}

.profile-section .profile-detail .profile-name {
  font-weight: 700
}

.profile-section .profile-detail p {
  margin-bottom: 8px
}

.vendor-cover .bg-size {
  height: 350px
}

.start-selling h4 {
  margin-bottom: 10px;
  text-transform: capitalize;
  font-weight: 600;
  line-height: 1.3
}

.start-selling p {
  line-height: 2;
  letter-spacing: 0.03em
}

.start-selling .btn {
  margin-top: 20px
}

.vendor-dashboard .dashboard-box {
  padding: 20px 10px;
  background-color: #f9f9f9
}

.vendor-dashboard .dashboard-box .date-picker-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex
}

.vendor-dashboard .dashboard-box .date-picker-box .input-group {
  width: 50%;
  padding: 0 10px
}

.faq-tab .nav-tabs {
  display: block;
  border-bottom: none;
  background-color: #fff;
  padding: 10px 0
}

.faq-tab .nav-tabs .nav-item {
  display: block;
  margin-bottom: 0
}

.faq-tab .nav-tabs .nav-item .nav-link {
  text-transform: capitalize;
  color: #000;
  font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
  border: none;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  border-radius: 0;
  background-color: #f8f8f8;
  cursor: pointer
}

.faq-tab .nav-tabs .nav-item .nav-link.active {
  border: none;
  border-right: 2px solid var(--theme-color);
  border-radius: 0;
  color: var(--theme-color);
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease
}

.faq-tab .nav-tabs .nav-item .nav-link:hover {
  border: none;
  border-right: 2px solid var(--theme-color);
  color: var(--theme-color);
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease
}

.tracking-page h3 {
  margin-bottom: 20px;
  text-transform: uppercase;
  color: #222;
  font-weight: 700;
  margin-top: -5px;
  font-size: calc(16px + (24 - 16) * ((100vw - 320px) / (1920 - 320)))
}

.tracking-page .border-part {
  border-bottom: 1px dashed #ddd;
  padding-bottom: 25px;
  padding-top: 25px;
  border-top: 1px dashed #ddd
}

.tracking-page .tracking-detail {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 100%
}

.tracking-page .tracking-detail ul li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 16px;
  margin-bottom: 5px
}

.tracking-page .tracking-detail ul li .left {
  min-width: 220px;
  text-transform: capitalize;
  font-weight: 600
}

.tracking-page .tracking-detail ul li .right {
  color: #777
}

.tracking-page .order-map {
  height: 100%
}

.tracking-page .order-map iframe {
  height: 100%;
  width: 100%;
  border: 5px solid #fff;
  -webkit-box-shadow: 0px 5px 23px rgba(0, 0, 0, 0.09);
  box-shadow: 0px 5px 23px rgba(0, 0, 0, 0.09);
  border-radius: 8px
}

.tracking-page .wrapper {
  margin: 30px 0;
  margin-left: -5px
}

.tracking-page .wrapper .arrow-steps .step {
  font-size: 14px;
  text-align: center;
  color: #000;
  cursor: default;
  margin: 0 5px;
  padding: 12px 10px 12px 30px;
  min-width: 180px;
  float: left;
  position: relative;
  background-color: #f8f8f8;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transition: background-color 0.2s ease;
  transition: background-color 0.2s ease
}

.tracking-page .wrapper .arrow-steps .step span {
  position: relative;
  font-size: 16px;
  font-weight: 500;
  text-transform: capitalize
}

.tracking-page .wrapper .arrow-steps .step span:before {
  opacity: 0;
  content: "âœ”";
  position: absolute;
  top: -2px;
  left: -20px
}

.tracking-page .wrapper .arrow-steps .step:before,
.tracking-page .wrapper .arrow-steps .step:after {
  content: " ";
  position: absolute;
  top: 0;
  right: -17px;
  width: 0;
  height: 0;
  border-top: 25px solid transparent;
  border-bottom: 22px solid transparent;
  border-left: 17px solid #f8f8f8;
  z-index: 2;
  -webkit-transition: border-color 0.2s ease;
  transition: border-color 0.2s ease
}

.tracking-page .wrapper .arrow-steps .step:before {
  right: auto;
  left: 0;
  border-left: 17px solid #fff;
  z-index: 0
}

.tracking-page .wrapper .arrow-steps .step:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px
}

.tracking-page .wrapper .arrow-steps .step:first-child:before {
  border: none
}

.tracking-page .wrapper .arrow-steps .step.done span:before {
  opacity: 1;
  -webkit-transition: opacity 0.3s ease 0.5s;
  transition: opacity 0.3s ease 0.5s
}

.tracking-page .wrapper .arrow-steps .step.current {
  color: #fff;
  background-color: var(--theme-color)
}

.tracking-page .wrapper .arrow-steps .step.current:after {
  border-left: 17px solid var(--theme-color)
}

.tracking-page .table th {
  padding-top: 0
}

.tracking-page .table-striped tbody tr:nth-of-type(odd) {
  background-color: #f8f8f8
}

.tracking-page .table th,
.tracking-page .table td {
  font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
  text-transform: capitalize
}

.theme-invoice-1 .invoice-header {
  padding: calc(15px + (45 - 15) * ((100vw - 320px) / (1920 - 320)));
  background-color: #f8f8f8
}

.theme-invoice-1 .invoice-header h4 {
  font-size: 16px;
  color: #646464;
  margin-bottom: 5px
}

.theme-invoice-1 .invoice-header .upper-icon {
  position: absolute;
  width: calc(50px + (70 - 50) * ((100vw - 320px) / (1920 - 320)));
  height: calc(50px + (70 - 50) * ((100vw - 320px) / (1920 - 320)));
  top: 0;
  right: calc(30px + (60 - 30) * ((100vw - 320px) / (1920 - 320)));
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: var(--theme-color)
}

.theme-invoice-1 .invoice-header .upper-icon img {
  width: calc(22px + (30 - 22) * ((100vw - 320px) / (1920 - 320)))
}

.theme-invoice-1 .invoice-header .header-content {
  margin-top: 50px
}

.theme-invoice-1 .invoice-header .header-content h2 {
  font-size: 30px
}

.theme-invoice-1 .invoice-header .detail-bottom {
  padding-top: 24px;
  margin-top: 24px;
  border-top: 1px solid #dddddd
}

.theme-invoice-1 .invoice-header .detail-bottom ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between
}

.theme-invoice-1 .invoice-header .detail-bottom ul li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 16px
}

.theme-invoice-1 .invoice-header .detail-bottom ul li span {
  color: var(--theme-color);
  text-transform: capitalize
}

.theme-invoice-1 .invoice-header .detail-bottom ul li h4 {
  margin-bottom: 0;
  margin-left: 4px
}

.theme-invoice-2 h4 {
  font-size: 16px;
  color: #646464
}

.theme-invoice-2 .invoice-header {
  padding: calc(15px + (45 - 15) * ((100vw - 320px) / (1920 - 320))) calc(15px + (45 - 15) * ((100vw - 320px) / (1920 - 320))) 0
}

.theme-invoice-2 .invoice-header .top-bar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between
}


.theme-invoice-2 .invoice-body .date-detail {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-bottom: 30px;
  border-bottom: 2px solid var(--theme-color);
  margin-bottom: 20px
}

.theme-invoice-2 .invoice-body .date-detail li span {
  font-size: 18px;
  text-transform: capitalize;
  margin-bottom: 2px;
  display: block
}

.theme-invoice-2 .invoice-body .date-detail li.total-amount span {
  font-size: calc(18px + (22 - 18) * ((100vw - 320px) / (1920 - 320)));
  font-weight: 700
}

.theme-invoice-2 .background-img {
  border-radius: calc(5px + (20 - 5) * ((100vw - 320px) / (1920 - 320)));
  margin-top: calc(14px + (30 - 14) * ((100vw - 320px) / (1920 - 320)))
}

.theme-invoice-3 .invoice-header {
  padding: calc(15px + (55 - 15) * ((100vw - 320px) / (1920 - 320)));
  background-color: #f8f8f8;
  border-bottom: 1px solid #e6e6e6;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center
}

.theme-invoice-3 .invoice-header ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap
}

.theme-invoice-3 .invoice-header ul li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center
}

.theme-invoice-3 .invoice-header ul li i {
  border-radius: 8px;
  background-color: var(--theme-color);
  width: 34px;
  height: 34px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: white;
  font-size: 16px
}

.theme-invoice-3 .invoice-header ul li h4 {
  margin-bottom: 0;
  margin-left: 10px
}

.theme-invoice-3 .date-detail li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center
}

.theme-invoice-3 .date-detail li span {
  font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
  text-transform: capitalize;
  color: var(--theme-color);
  margin-right: 8px
}

.theme-invoice-3 .date-detail li h4 {
  margin-bottom: 0
}



.theme-invoice-4 .invoice-header {
  padding: calc(15px + (65 - 15) * ((100vw - 320px) / (1920 - 320)));
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  z-index: 1;
  overflow: hidden
}

.theme-invoice-4 .invoice-header .background-invoice {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: -1
}

.theme-invoice-4 .table-striped>tbody>tr:nth-of-type(even) {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color)
}

.theme-invoice-4 .table-striped>tbody>tr:nth-of-type(odd) {
  --bs-table-accent-bg: white;
  color: #646464
}


.theme-invoice-4 .top-sec {
  padding: 0 calc(15px + (65 - 15) * ((100vw - 320px) / (1920 - 320))) calc(15px + (45 - 15) * ((100vw - 320px) / (1920 - 320)))
}

.theme-invoice-4 .title-sec {
  padding: calc(15px + (30 - 15) * ((100vw - 320px) / (1920 - 320))) calc(15px + (65 - 15) * ((100vw - 320px) / (1920 - 320)));
  background-color: #f8f8f8;
  border-top: 1px solid #ececec;
  border-bottom: 1px solid #ececec;
  position: relative
}

.theme-invoice-4 .title-sec .title {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%)
}

.theme-invoice-4 .table-sec {
  padding: calc(15px + (40 - 15) * ((100vw - 320px) / (1920 - 320))) calc(15px + (65 - 15) * ((100vw - 320px) / (1920 - 320)))
}

.theme-invoice-4 .table-sec .table-footer {
  background-color: var(--theme-color);
  display: inline-block;
  padding: calc(10px + (15 - 10) * ((100vw - 320px) / (1920 - 320))) calc(15px + (30 - 15) * ((100vw - 320px) / (1920 - 320)));
  font-size: calc(14px + (20 - 14) * ((100vw - 320px) / (1920 - 320)));
  color: white;
  margin-top: 30px
}

.theme-invoice-4 .date-detail li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center
}

.theme-invoice-4 .date-detail li span {
  font-size: 18px;
  text-transform: capitalize;
  color: var(--theme-color);
  margin-right: 8px
}

.theme-invoice-4 .date-detail li h4 {
  margin-bottom: 0
}

.theme-invoice-5 .date-detail li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center
}

.theme-invoice-5 .date-detail li span {
  font-size: 18px;
  text-transform: capitalize;
  margin-right: 8px
}

.theme-invoice-5 .date-detail li h4 {
  margin-bottom: 0
}

.theme-invoice-5 .invoice-header {
  padding: calc(15px + (65 - 15) * ((100vw - 320px) / (1920 - 320))) calc(15px + (65 - 15) * ((100vw - 320px) / (1920 - 320))) calc(30px + (110 - 30) * ((100vw - 320px) / (1920 - 320)));
  position: relative;
  overflow: hidden;
  color: white
}

.theme-invoice-5 .invoice-header h4,
.theme-invoice-5 .invoice-header h2 {
  color: white;
  line-height: 21px
}

.theme-invoice-5 .invoice-header .background-img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%
}

.theme-invoice-5 .invoice-footer {
  padding: calc(15px + (30 - 15) * ((100vw - 320px) / (1920 - 320))) calc(15px + (65 - 15) * ((100vw - 320px) / (1920 - 320))) calc(15px + (65 - 15) * ((100vw - 320px) / (1920 - 320)))
}

.theme-invoice-5 .header-panel {
  padding: calc(15px + (30 - 15) * ((100vw - 320px) / (1920 - 320)));
  margin-top: calc(-75px + (-130 - -75) * ((100vw - 991px) / (1920 - 991)));
  position: relative;
  background-color: white;
  border-radius: 12px;
  -webkit-box-shadow: 0px 7px 8px 1px #f4f4f4;
  box-shadow: 0px 7px 8px 1px #f4f4f4
}

.theme-invoice-5 .header-panel ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between
}

.theme-invoice-5 .header-panel ul li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center
}

.theme-invoice-5 .header-panel ul li img {
  width: 150px
}

.theme-invoice-5 .header-panel ul li i {
  margin-right: 30px;
  color: var(--theme-color);
  font-size: 20px
}

body.dark {
  background-color: #2b2b2b;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  color: #cfd4da
}

.dark h1 {
  color: #fff
}

.dark h2 {
  color: #f1f3f5
}

.dark h3 {
  color: #eaedef
}

.dark h4 {
  color: #e7eaec
}

.dark h5 {
  color: #dee2e6
}

.dark h6 {
  color: #cfd4da
}

.dark p {
  color: #cfd4da
}

.dark li {
  color: #cfd4da
}

.dark a {
  color: #cbcbcb
}

.dark .modal .btn-close span {
  color: #fff
}

.dark .header-style-7 .main-menu .menu-left .navbar .bar-style img {
  -webkit-filter: invert(1);
  filter: invert(1)
}

.dark .form-select {
  background-color: #2b2b2b;
  border-color: #404040
}

.dark .shop-sidebar-demo .shop-sidebar {
  background-color: #232323;
  border-color: #404040
}

.dark .shop-sidebar-demo .shop-sidebar .pixelstrap.sm-vertical a img {
  -webkit-filter: invert(1);
  filter: invert(1)
}

.dark .bg_cls {
  background-color: #232323
}

.dark .white-bg {
  background-color: #2b2b2b !important
}

.dark .grey-bg {
  background-color: #232323
}

.dark .border-top {
  border-color: #404040 !important
}

.dark .border-top-grey {
  border-color: #404040
}

.dark .bg-light {
  background-color: #232323 !important
}

.dark .blog-section .blog-details p {
  color: #cfd4da
}

.dark .blog-section .review-box {
  background-color: #2b2b2b
}

.dark .blog-section .review-box .review-content h6 {
  color: #cfd4da
}

.dark .blog-section .review-box .slick-prev:after {
  background-color: #404040
}

.dark .page-link {
  background-color: #2b2b2b;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  border-color: #404040
}

.dark .breadcrumb-section {
  background-color: #232323;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease
}

.dark .light-layout {
  background-color: #232323;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease
}

.dark .form-control {
  color: #cfd4da;
  background-color: #2b2b2b;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  border: 1px solid #404040
}

.dark mark,
.dark .mark {
  background-color: #232323;
  color: #fff
}

.dark .product-style-1.product-box {
  border-color: #404040
}

.dark .addtocart_count .product-box.product-style-1 .add-button {
  background-color: #232323;
  color: #cbcbcb
}

.dark .addtocart_count .product-box .add-button {
  background-color: #232323;
  color: #fff
}

.dark .addtocart_count .addtocart_btn .cart_qty.qty-box .input-group button {
  background: #232323 !important
}

.dark .addtocart_count .addtocart_btn .cart_qty.qty-box .input-group button i {
  color: #fff
}

.dark .btn-solid {
  color: #fff !important
}

.dark .btn-solid:hover {
  color: #222 !important
}

.dark select {
  color: #cfd4da
}

.dark option {
  background-color: #2b2b2b;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease
}

.dark .header-compact .main-menu .menu-right .icon-nav li.mobile-cart>div img {
  -webkit-filter: invert(1);
  filter: invert(1)
}

.dark .header-options span {
  color: #dee2e6
}

.dark .header-style-1 .navbar .nav-link {
  color: #cbcbcb
}

.dark .header-style-7 {
  border-color: #404040
}

.dark .product-style-5.product-box h4 {
  color: #222
}

.dark .shop-sidebar-demo .shop-main .small-slider .home .slider-contain h4,
.dark .shop-sidebar-demo .shop-main .small-slider .home .slider-contain h2 {
  color: #222222
}

.dark .bg-theme {
  background-color: #232323
}

.dark.section-white section {
  background-color: #2b2b2b
}

.dark .ajax-search .typeahead {
  background-color: #2b2b2b !important
}

.dark .ajax-search .tt-menu {
  background-color: #2b2b2b
}

.dark .ajax-search .description-section h4 {
  color: #e7eaec
}

.dark .product-style-4.product-box,
.dark .product-style-4.product-wrap {
  border-color: #404040
}

.dark .deal-product-space {
  background-color: #232323
}

.dark .deal-product-space .product-style-4.product-box,
.dark .deal-product-space .product-style-4 .product-wrap {
  background-color: #2b2b2b
}

.dark .popular-search-section {
  border-color: #404040
}

.dark .popular-search-section ul li:after {
  background-color: #404040
}


.dark .product-vertical .full-box .theme-card .offer-slider {
  background-color: #2b2b2b
}

.dark .top-header {
  background-color: #232323;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease
}

.dark footer.footer-expand p {
  color: #777
}

.dark .sub-footer {
  background-color: #2b2b2b;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease
}

.dark .border-section {
  border-color: #404040
}

.dark .pixelstrap a,
.dark .pixelstrap:hover,
.dark .pixelstrap:active {
  color: #f1f3f5
}

.dark .sm-vertical {
  background-color: #232323;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease
}

.dark .sidenav nav {
  background-color: #232323;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease
}

.dark .sidenav .sidebar-back {
  color: #f1f3f5;
  border-color: #404040
}

.dark .pixelstrap ul {
  background: #232323;
  -webkit-box-shadow: none;
  box-shadow: none
}

.dark .pixelstrap ul a {
  color: #e7eaec
}

.dark .pixelstrap ul a:hover,
.dark .pixelstrap ul a:focus,
.dark .pixelstrap ul a:active,
.dark .pixelstrap ul a.highlighted {
  color: #e7eaec
}

.dark .pixelstrap .home-menu,
.dark .pixelstrap .feature-menu,
.dark .pixelstrap .category-menu,
.dark .pixelstrap .full-mega-menu,
.dark .pixelstrap .clothing-menu {
  -webkit-box-shadow: 0 0 1px 0 #2b2b2b;
  box-shadow: 0 0 1px 0 #2b2b2b
}

.dark .onhover-div>div img {
  -webkit-filter: brightness(100);
  filter: brightness(100)
}

.dark .onhover-div .show-div {
  background-color: #232323;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -webkit-box-shadow: none;
  box-shadow: none
}

.dark .error-section h1 {
  color: #e6e6e6
}

.dark .breadcrumb-item.active {
  color: #e7eaec
}

.dark .breadcrumb-section .breadcrumb a {
  color: #f7f7f7
}

.dark .main-menu .menu-left .navbar i {
  color: #f7f7f7
}

.dark .main-menu .menu-right .icon-nav .onhover-div .show-div.setting h6 {
  color: #cfd4da
}

.dark .main-menu .menu-right .icon-nav .onhover-div .show-div.setting ul li a {
  color: #cfd4da
}

.dark .main-menu .menu-right .icon-nav .onhover-div .show-div.shopping-cart li .media .media-body h4 {
  color: #e7eaec
}

.dark .main-menu .menu-right .icon-nav .onhover-div .show-div.shopping-cart li .media .media-body h4 span {
  color: #dee2e6
}

.dark .main-menu .menu-right .icon-nav .onhover-div .show-div.shopping-cart li .close-circle i:hover {
  color: #fff
}

.dark .main-menu .menu-right .icon-nav .onhover-div .show-div.shopping-cart li .total {
  border-color: #404040
}

.dark .main-menu .menu-right .icon-nav .onhover-div .show-div.shopping-cart li .total h5 {
  color: #dee2e6
}

.dark .main-menu .menu-right .icon-nav .onhover-div .show-div.shopping-cart li .buttons a {
  color: #cbcbcb
}

.dark .main-menu .menu-right .icon-nav .onhover-div .show-div.shopping-cart li .buttons a:hover {
  color: var(--theme-color)
}

.dark .footer-theme .sub-title li {
  color: #cfd4da
}

.dark .footer-theme .sub-title li a {
  color: #cfd4da
}

.dark .footer-theme .sub-title h4 {
  color: #e7eaec
}

.dark .footer-social i,
.dark .social-white i {
  color: #cfd4da
}

.dark .footer-light .subscribe {
  border-color: #404040
}

.dark .footer-theme2 .contact-details li {
  color: #cfd4da
}

.dark .footer-theme2 .contact-details li a {
  color: #e7eaec
}

.dark .footer-theme2 .footer-link a,
.dark .footer-theme2 .footer-link-b a {
  color: #cbcbcb
}

.dark .footer-theme2 .footer-link a:hover,
.dark .footer-theme2 .footer-link-b a:hover {
  color: #fff
}

.dark .top-header .header-contact li {
  color: #cfd4da
}

.dark .top-header .header-dropdown li {
  color: #cfd4da
}

.dark .top-header .header-dropdown li a i {
  color: #cfd4da
}

.dark .top-header .header-dropdown .onhover-dropdown .onhover-show-div li a {
  color: #cbcbcb
}

.dark .top-header.top-header-dark .header-dropdown li a {
  color: #cbcbcb
}

.dark .top-header.top-header-dark .header-contact li i {
  color: #cbcbcb
}

.dark .top-header.top-header-dark2 .header-dropdown li a {
  color: #cfd4da
}

.dark .top-header.top-header-dark2 .header-contact li i {
  color: #cfd4da
}

.dark .top-header.top-header-dark3 .header-dropdown li a {
  color: #cbcbcb
}

.dark .top-header.top-header-dark3 .header-contact li i {
  color: #cbcbcb
}

.dark .onhover-dropdown .onhover-show-div {
  background-color: #232323;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -webkit-box-shadow: none;
  box-shadow: none
}

.dark .testimonial {
  background-color: #232323;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease
}

.dark .testimonial .testimonial-slider .slick-track .slick-slide:nth-child(even) .media {
  border-color: #404040
}

.dark .testimonial .testimonial-slider .media img {
  border-color: #404040
}

.dark .team h2 {
  border-color: #404040
}

.dark .service-block+.service-block {
  border-color: #404040
}

.dark .pixelstrap.light-font-menu li>a {
  color: #f1f3f5
}

.dark .theme-tab .tab-title a,
.dark .theme-tab .tab-title2 a {
  color: #cbcbcb
}

.dark .theme-tab .tab-title .current a,
.dark .theme-tab .tab-title2 .current a {
  color: var(--theme-color)
}

.dark .theme-tab .tab-title2:after {
  border-color: #404040
}

.dark .theme-tab .tab-title2 .current {
  background-color: #2b2b2b;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease
}

.dark .theme-tab .tab-title2 .current a {
  background-color: #2b2b2b;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease
}

.dark .product-box .cart-info.bg-color-cls button {
  background-color: #2b2b2b
}

.dark .product-box .cart-info.bg-color-cls a i {
  background-color: #2b2b2b
}

.dark .product-box .product-detail h4,
.dark .product-box .product-info h4,
.dark .product-wrap .product-detail h4,
.dark .product-wrap .product-info h4 {
  color: #e7eaec
}

.dark .theme-card .offer-slider .media .media-body h4 {
  color: #e7eaec
}

.dark .theme-card .slick-prev:before,
.dark .theme-card .slick-next:before {
  color: #fff
}

.dark .theme-card h5.title-border {
  border-color: #404040
}

.dark .theme-card.card-border {
  border-color: #404040
}

.dark .dark-layout {
  background-color: #2b2b2b;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease
}

.dark .sub-footer.darker-subfooter {
  background-color: #232323;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease
}

.dark .sub-footer.darker-subfooter p {
  color: #cfd4da
}

.dark .blog-page .blog-media .blog-right h4 {
  color: #e7eaec
}

.dark .blog-page .blog-media .blog-right ul {
  color: #cfd4da
}

.dark .blog-page .blog-media .blog-right ul li+li {
  border-color: #404040
}

.dark .blog-page .blog-sidebar .theme-card {
  background-color: #2b2b2b;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  border-color: #404040
}

.dark .blog-page .blog-sidebar h4 {
  color: #e7eaec
}

.dark .blog-page .blog-sidebar h6 {
  color: #cfd4da
}

.dark .blog-page .blog-sidebar p {
  color: #cfd4da
}

.dark .blog-detail-page .blog-detail h3 {
  color: #eaedef
}

.dark .blog-detail-page .blog-detail p {
  color: #cfd4da
}

.dark .blog-detail-page .post-social {
  color: #cfd4da;
  border-color: #404040
}

.dark .blog-detail-page .post-social li+li {
  border-color: #404040
}

.dark .blog-detail-page .comment-section {
  border-color: #404040
}

.dark .blog-detail-page .comment-section li {
  border-color: #404040
}

.dark .blog-detail-page .comment-section li h6 {
  color: #cfd4da
}

.dark .blog-detail-page .comment-section li h6 span {
  color: #929292
}

.dark .blog-detail-page .blog-contact .theme-form label {
  color: #cfd4da
}

.dark .blog-detail-page .blog-contact .theme-form input,
.dark .blog-detail-page .blog-contact .theme-form textarea {
  background-color: #2b2b2b;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  border-color: #404040
}

.dark .form_search {
  background-color: #232323;
  -webkit-box-shadow: none;
  box-shadow: none
}

.dark .img-category .img-sec {
  border-color: #404040
}

.dark .dark-overlay {
  background-blend-mode: overlay;
  background-color: #232323
}

.dark .cart_counter {
  background: #232323;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #cbcbcb
}

.dark .cart-section .cart-table thead th,
.dark .wishlist-section .cart-table thead th {
  color: #f1f3f5
}

.dark .cart-section tbody tr td,
.dark .wishlist-section tbody tr td {
  border-color: #404040 !important
}

.dark .cart-section tbody tr td a,
.dark .wishlist-section tbody tr td a {
  color: #cbcbcb
}

.dark .cart-section tbody tr td h2,
.dark .wishlist-section tbody tr td h2 {
  color: #f1f3f5
}

.dark .table tbody+tbody {
  border-color: #404040
}

.dark .table thead th {
  border-color: #404040 !important
}

.dark .irs-from,
.dark .irs-to,
.dark .irs-single {
  color: #cbcbcb
}

.dark .irs-line {
  background: #2b2b2b
}

.dark .top-banner-wrapper .top-banner-content h4 {
  color: #e7eaec
}

.dark .top-banner-wrapper .top-banner-content h5 {
  color: #dee2e6
}

.dark .top-banner-wrapper .top-banner-content p {
  color: #cfd4da
}

.dark .collection-product-wrapper .product-top-filter {
  border-color: #404040
}

.dark .collection-product-wrapper .product-top-filter .product-filter-content .search-count,
.dark .collection-product-wrapper .product-top-filter .product-filter-content .sidebar-popup,
.dark .collection-product-wrapper .product-top-filter .popup-filter .search-count,
.dark .collection-product-wrapper .product-top-filter .popup-filter .sidebar-popup {
  border-color: #404040
}

.dark .collection-product-wrapper .product-top-filter .product-filter-content .collection-grid-view,
.dark .collection-product-wrapper .product-top-filter .popup-filter .collection-grid-view {
  border-color: #404040
}

.dark .collection-product-wrapper .product-top-filter .product-filter-content .product-page-per-view select,
.dark .collection-product-wrapper .product-top-filter .popup-filter .product-page-per-view select {
  border-color: #404040
}

.dark .collection-product-wrapper .product-top-filter .popup-filter .sidebar-popup a {
  color: #cbcbcb
}

.dark .collection-product-wrapper .product-top-filter .popup-filter .open-popup {
  border-color: #404040;
  -webkit-box-shadow: none;
  box-shadow: none
}

.dark .product-pagination {
  border-color: #404040
}

.dark .product-pagination .pagination .page-item.active a {
  background-color: #232323;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  border-color: #404040
}

.dark .product-pagination .product-search-count-bottom {
  border-color: #404040
}

.dark .product-pagination .product-search-count-bottom h5 {
  color: #dee2e6
}

.dark .collection-filter-block {
  border-color: #404040;
  background-color: #2b2b2b;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease
}

.dark .collection-collapse-block .collapse-block-title {
  color: #eaedef
}

.dark .collection-collapse-block .collapse-block-title:after {
  color: #eaedef
}

.dark .collection-collapse-block .collection-collapse-block-content .collection-brand-filter .collection-filter-checkbox label {
  color: #929292
}

.dark .load-more-sec a {
  border-color: #404040
}

.dark .category-nft a {
  -webkit-box-shadow: none;
  box-shadow: none;
  border-color: #525252
}

.dark .nft-top-collection .collection-panal .nft-collection+.nft-collection {
  border-color: #525252
}

.dark .nft-box {
  border-color: #525252
}

.dark .nft-box .nft-content .top-content h4 {
  color: #fff
}

.dark .nft-box .nft-content .top-content h5 {
  color: rgba(255, 255, 255, 0.7)
}

.dark .nft-box .nft-content .bottom-content span {
  color: rgba(255, 255, 255, 0.7)
}

.dark .nft-box .nft-content .bottom-content .price img {
  -webkit-filter: invert(1);
  filter: invert(1)
}

.dark .checkout-page .checkout-title h3 {
  color: #eaedef
}

.dark .checkout-page .checkout-form .form-group .field-label {
  color: #cfd4da
}

.dark .checkout-page .checkout-form input[type="text"],
.dark .checkout-page .checkout-form input[type="email"],
.dark .checkout-page .checkout-form input[type="password"],
.dark .checkout-page .checkout-form input[type="tel"],
.dark .checkout-page .checkout-form input[type="number"],
.dark .checkout-page .checkout-form input[type="url"] {
  background-color: #2b2b2b;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  border-color: #404040
}

.dark .checkout-page .checkout-form select,
.dark .checkout-page .checkout-form textarea {
  border-color: #404040
}

.dark .checkout-page .checkout-form .checkout-details {
  background-color: #232323;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  border: 1px solid #404040
}

.dark .order-box .title-box {
  color: #eaedef;
  border-color: #404040
}

.dark .order-box .qty {
  border-color: #404040
}

.dark .order-box .qty li {
  color: #cfd4da
}

.dark .order-box .qty li span {
  color: #dee2e6
}

.dark .order-box .sub-total {
  border-color: #404040
}

.dark .order-box .sub-total li {
  color: #cfd4da
}

.dark .order-box .sub-total .shopping-option label {
  color: #929292
}

.dark .order-box .total li {
  color: #cfd4da
}

.dark .payment-box .payment-options li .radio-option label {
  color: #929292
}

.dark .collection .collection-block .collection-content h4 {
  color: #e7eaec
}

.dark .collection .collection-block .collection-content h3 {
  color: #eaedef
}

.dark .top-panel-adv .delivery-area h5 {
  color: #222222
}

.dark .top-panel-adv .panel-left-content h4 {
  color: #222222
}

.dark .table th,
.dark .table td {
  border-color: #404040
}

.dark .compare-page .table-wrapper .table {
  color: #f1f3f5;
  border-color: #404040
}

.dark .compare-page .table-wrapper .table thead .th-compare td {
  background: #232323;
  border-color: #404040
}

.dark .compare-page .table-wrapper .table thead .th-compare th {
  border-color: #404040
}

.dark .compare-page .table-wrapper .table thead .th-compare th .remove-compare {
  color: #cfd4da
}

.dark .compare-page .table-wrapper .table tbody tr th {
  border-color: #404040;
  background: #232323
}

.dark .compare-page .table-wrapper .table tbody tr td {
  border-color: #404040
}

.dark .compare-page .table-wrapper .table tbody tr p {
  color: #cfd4da
}

.dark .compare-section .compare-part .detail-part .title-detail {
  background-color: #232323;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  border-color: #404040
}

.dark .compare-section .compare-part .btn-part {
  border-color: #404040
}

.dark .compare-section .compare-part .close-btn {
  color: #e7eaec
}

.dark .compare-section .slick-slide>div {
  border-color: #404040
}

.dark .compare-section .slick-slide:first-child {
  border-color: #404040
}

.dark .contact-page .theme-form label {
  color: #cfd4da
}

.dark .contact-page .theme-form input {
  border-color: #404040
}

.dark .contact-page .theme-form textarea {
  border-color: #404040
}

.dark .contact-page .contact-right ul li .contact-icon {
  border-color: #404040
}

.dark .dashboard .box-head h2 {
  color: #f1f3f5
}

.dark .dashboard .box .box-title {
  border-color: #404040
}

.dark .dashboard .box .box-title h3 {
  color: #eaedef
}

.dark .dashboard-left .block-content {
  border-color: #404040
}

.dark .dashboard-left .block-content ul li a {
  color: #cbcbcb
}

.dark .dashboard-right .dashboard {
  border-color: #404040
}

.dark .white-bg {
  background-color: #2b2b2b;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease
}


.dark .layout-8 .layout-8-bg {
  background-blend-mode: overlay;
  background-color: #232323;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease
}

.dark .card {
  background-color: #2b2b2b;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease
}

.dark .card .card-header {
  background-color: #232323;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease
}

.dark .faq-section .accordion.theme-accordion .card {
  border-color: #404040
}

.dark .faq-section .accordion.theme-accordion .card .card-header {
  background-color: #232323;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease
}

.dark .faq-section .accordion.theme-accordion .card .card-header button {
  color: #dee2e6
}

.dark .faq-section .accordion.theme-accordion .card .card-header button[aria-expanded="true"]:before {
  border-bottom-color: #404040
}

.dark .faq-section .accordion.theme-accordion .card .card-header button:before {
  border-top-color: #404040
}

.dark .faq-section .accordion.theme-accordion .card .card-body p {
  color: #cfd4da
}

.dark.box-layout-body .box-layout-header {
  background-color: #2b2b2b;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease
}

.dark.box-layout-body .box-layout.bg-image {
  background-color: #232323;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  background-image: none;
  -webkit-box-shadow: none;
  box-shadow: none
}

.dark.box-layout-body .light-layout {
  background-color: #2b2b2b;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease
}

.dark.box-layout-body .sub-footer {
  background-color: #232323;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease
}

.dark .bg-title.wo-bg .theme-tab .bg-title-part {
  border-color: #404040
}

.dark .bg-title.wo-bg .theme-tab .bg-title-part .title-border {
  color: #fff
}

.dark .bg-title.wo-bg .theme-tab .tab-title a {
  color: #cfd4da
}

.dark .full-box .theme-card .offer-slider .product-box2+.product-box2 {
  border-top: none
}

.dark .center-slider {
  border-color: #404040
}

.dark .bg-block {
  background-color: #232323;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  background-image: none
}

.dark .theme-tab .tab-content .product-tab .tab-box {
  background-color: #2b2b2b;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease
}

.dark .theme-tab .tab-content .product-tab .tab-box .product-box2 .media .media-body h4 {
  color: #e7eaec
}

.dark .flower-bg {
  background-color: #232323;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  background-image: none
}

.dark .pwd-page .theme-form input {
  border-color: #404040
}

.dark .full-scroll-menu {
  background-color: transparent;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease
}

.dark .full-scroll-footer .sub-footer {
  background-color: transparent;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease
}


.dark .category-m.w-bg .category-wrapper {
  background-color: #2b2b2b
}

.dark .banner-timer {
  background-blend-mode: overlay;
  background-color: rgba(0, 0, 0, 0.77)
}

.dark .bg-img-gym .dark-layout {
  background-color: transparent;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease
}

.dark .bg-img-gym .sub-footer.darker-subfooter {
  background-color: #000;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease
}

.dark .category-block .category-image {
  border-color: #404040
}

.dark .category-block .category-image.svg-image {
  background-color: #232323;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease
}

.dark .j-box .product-box {
  border-color: #404040
}

.dark .j-box .product-box .product-detail {
  background-color: #232323;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease
}

.dark .j-box .product-box .cart-info {
  background-color: rgba(35, 35, 35, 0.84);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  border-color: #404040
}

.dark .j-box .product-box .cart-info i {
  color: #cfd4da
}

.dark .j-box .product-box .cart-info a {
  border-color: #404040
}

.dark .white-layout {
  background-color: #2b2b2b;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease
}

.dark .footer-theme2.footer-border {
  border-color: #404040
}

.dark .footer-theme2.section-light .footer-block .subscribe-white {
  border-color: #404040
}

.dark .footer-theme2.section-light .footer-block h4 {
  color: #e7eaec
}

.dark .footer-theme2.section-light .footer-block .contact-details li a {
  color: #e7eaec
}

.dark .footer-theme2 .footer-link.link-white h4 {
  color: #e7eaec
}

.dark .jewel-footer .sub-footer.black-subfooter {
  background-color: #232323;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease
}

.dark .jewel-footer .sub-footer p {
  color: #cfd4da
}

.dark .absolute-banner .absolute-bg {
  background-color: #232323;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -webkit-box-shadow: none;
  box-shadow: none
}

.dark .login-page .theme-card {
  border-color: #404040;
  background-color: #232323;
  -webkit-box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.1)
}

.dark .login-page .theme-card .theme-form input {
  border-color: #404040
}

.dark .login-page .theme-card .theme-form label {
  color: #929292
}

.dark .login-page .authentication-right h6,
.dark .login-page .authentication-right p {
  color: #cfd4da
}

.dark .lookbook .lookbook-block .lookbook-dot:before {
  background-color: #232323;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease
}

.dark .lookbook .lookbook-block .lookbook-dot .dot-showbox .dot-info {
  background-color: #2b2b2b;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease
}

.dark .blog-left .blog-details h4 {
  color: #e7eaec
}

.dark .blog-left .blog-details h6 {
  color: #cfd4da
}

.dark footer.pet-layout-footer .white-layout {
  background-color: #232323;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease
}

.dark footer.pet-layout-footer .sub-footer.black-subfooter {
  background-color: #2b2b2b;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease
}

.dark footer.footer-classic .sub-footer {
  background-color: #2b2b2b;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease
}

.dark footer.footer-5 {
  background-image: unset
}

.dark footer.footer-5 .footer-theme2 .subscribe-block {
  border-color: #404040
}

.dark footer.footer-5 .sub-footer>.container {
  border-color: #404040
}


.dark footer.footer-theme-color .sub-footer.dark-subfooter {
  background-color: #2b2b2b
}

.dark .logo-block img {
  -webkit-filter: invert(100);
  filter: invert(100)
}

.dark .logo-block img:hover {
  -webkit-filter: invert(100);
  filter: invert(100)
}

.dark .bg-white {
  background-color: #2b2b2b !important;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease
}

.dark .product-box .img-wrapper .cart-box,
.dark .product-wrap .img-wrapper .cart-box {
  background-color: #2b2b2b;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -webkit-box-shadow: none;
  box-shadow: none
}

.dark .tab-bg.tab-grey-bg {
  background-color: #232323;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease
}

.dark .order-success-sec {
  background-color: #232323
}

.dark .tracking-page .border-part {
  border-color: #404040
}

.dark .tracking-page .wrapper .arrow-steps .step {
  background-color: #232323;
  color: #cbcbcb
}

.dark .tracking-page .wrapper .arrow-steps .step:after {
  border-left-color: #232323
}

.dark .tracking-page .wrapper .arrow-steps .step:before {
  border-left-color: #2b2b2b
}

.dark .tracking-page .wrapper .arrow-steps .step.current {
  color: #fff;
  background-color: var(--theme-color)
}

.dark .tracking-page .wrapper .arrow-steps .step.current:after {
  border-left-color: var(--theme-color)
}

.dark .tracking-page .table-striped tbody tr:nth-of-type(odd) {
  background-color: #232323;
  color: #cbcbcb
}

.dark .tracking-page .table {
  color: #cbcbcb
}

.dark .tracking-page .order-map iframe {
  border-color: #232323
}

.dark .product-order .total-sec ul li span {
  color: #dee2e6
}

.dark .product-order .total-sec {
  border-color: #404040
}

.dark .blog.blog_box .blog-details p {
  color: #cfd4da
}

.dark .blog.blog_box .blog-details a p {
  color: #cfd4da
}

.dark .blog.blog_box .blog-details .read-cls {
  color: #cbcbcb
}

.dark .btn-close {
  color: #fff
}

.dark .sticky-bottom-cart {
  background-color: #2b2b2b
}

.dark .sticky-bottom-cart .selection-section .form-control {
  background-color: #232323;
  border-color: #232323
}

.dark .sticky-bottom-cart .product-image .content h6 {
  color: #cfd4da
}


.dark .product-right .product-title {
  color: #cfd4da
}

.dark .product-right .border-product {
  border-color: #404040
}

.dark .product-right .product-icon .product-social li a {
  color: #cbcbcb
}

.dark .product-right .product-icon .wishlist-btn {
  color: #cbcbcb
}

.dark .product-right .product-icon .wishlist-btn i {
  border-color: #404040
}

.dark .product-right.product-form-box {
  border-color: #404040
}

.dark .product-right.product-form-box .timer {
  background-color: #232323;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease
}

.dark .product-right .timer {
  background-color: #232323;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease
}

.dark .product-right .timer p {
  color: #cfd4da
}

.dark .product-right .size-box ul li {
  background-color: #2b2b2b;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  border-color: #404040
}

.dark .product-right .size-box ul li.active {
  background-color: #232323;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease
}

.dark .product-right .size-box ul li a {
  color: #cbcbcb
}

.dark .product-right h4 del {
  color: #929292
}

.dark .product-right .product-count {
  background-color: #232323
}

.dark .timer span .timer-cal {
  color: #929292
}

.dark .qty-box .input-group span button {
  background: #2b2b2b !important;
  border-color: #404040
}

.dark .qty-box .input-group button i {
  color: #929292
}

.dark .nav-tabs {
  border-color: #404040
}

.dark .nav-tabs .nav-link.active {
  background-color: #2b2b2b;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease
}

.dark .tab-product .nav-material.nav-tabs .nav-item .nav-link {
  color: #cbcbcb
}

.dark .tab-product .nav-material.nav-tabs .nav-link.active {
  color: var(--theme-color)
}

.dark .product-full-tab .nav-material.nav-tabs .nav-link.active {
  color: var(--theme-color)
}

.dark .tab-product .theme-form input,
.dark .tab-product .theme-form textarea,
.dark .product-full-tab .theme-form input,
.dark .product-full-tab .theme-form textarea {
  border-color: #404040
}

.dark .product-related h2 {
  border-color: #404040
}

.dark .product-accordion .btn-link {
  color: #e7eaec
}

.dark .product-accordion .card-header {
  border-color: rgba(64, 64, 64, 0.125)
}

.dark .theme_checkbox label {
  color: #929292
}

.dark .theme_checkbox label .checkmark {
  background-color: #232323;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  border-color: #404040
}

.dark .theme_checkbox label .checkmark:after {
  border-color: #fff
}

.dark .bundle .bundle_detail .price_product {
  color: #fff
}

.dark .modal-content {
  background-color: #232323;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease
}

.dark button.close {
  color: #fff
}

.dark .modal-header {
  border-color: #404040
}

.dark .collection-filter-block .product-service .media {
  border-color: #404040
}

.dark .pro_sticky_info {
  border-color: #404040
}

.dark .tab-border {
  border-color: #404040
}

.dark .register-page .theme-card {
  border-color: #404040;
  background-color: #232323;
  -webkit-box-shadow: none;
  box-shadow: none
}

.dark .register-page .theme-card .theme-form input {
  border-color: #404040
}

.dark .register-page .theme-card .theme-form label {
  color: #929292
}

.dark .category-border {
  background-color: #404040;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease
}

.dark .category-border div .category-banner .category-box h2 {
  background-color: #232323;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease
}

.dark .background {
  background-color: #404040;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease
}

.dark .background .contain-bg {
  background-color: #2b2b2b;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease
}

.dark .background .contain-bg h4 {
  color: #e7eaec
}

.dark .background .contain-bg:hover h4 {
  color: var(--theme-color)
}

.dark .blog-bg {
  background-color: #404040;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease
}

.dark .sub-footer.black-subfooter {
  background-color: #232323;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease
}

.dark.tools-bg {
  background-color: #2b2b2b;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease
}

.dark.tools-bg section {
  background-color: #2b2b2b;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease
}

.dark .category-tools .category-m .category-wrapper {
  background-color: #232323;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease
}

.dark .category-m .category-wrapper {
  border-color: #404040
}

.dark .category-m .category-wrapper h4 {
  color: #e7eaec
}

.dark .category-m .category-wrapper .category-link li a {
  color: #cbcbcb
}

.dark .tools-grey .product-box .product-info {
  background-color: #232323;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  border-color: rgba(64, 64, 64, 0.2)
}

.dark .tools-grey .product-box .cart-info button {
  background-color: #232323;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  color: #cbcbcb
}

.dark .tools-grey .product-box .cart-info a {
  background-color: #232323;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease
}

.dark .tools-grey .product-box .cart-info a i {
  color: #cbcbcb
}

.dark .tools-grey .product-box .img-wrapper .front {
  background-color: #2b2b2b;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease
}

.dark .tools-brand .row {
  background-color: #232323;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -webkit-box-shadow: none;
  box-shadow: none
}

.dark .typography_section .typography-box .headings {
  background-color: rgba(35, 35, 35, 0.5);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  border-color: #404040
}

.dark .typography_section .typography-box .headings h3 {
  color: #eaedef
}

.dark .typography_section .typography-box .headings span {
  color: #929292
}

.dark .typography_section .typography-box .headings span code {
  color: #929292
}

.dark .typography_section .typography-box .typo-content .sub-title {
  color: #f1f3f5;
  border-color: rgba(64, 64, 64, 0.7)
}

.dark .typography_section .typo-content.heading_content h1,
.dark .typography_section .typo-content.heading_content h2,
.dark .typography_section .typo-content.heading_content h3,
.dark .typography_section .typo-content.heading_content h4,
.dark .typography_section .typo-content.heading_content h5,
.dark .typography_section .typo-content.heading_content h6 {
  color: white
}

.dark .typography_section .typo-content.product-pagination .pagination .page-item {
  border-color: #404040
}

.dark .typography_section code {
  background-color: #2b2b2b;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease
}

.dark .absolute_banner .collection-banner .absolute-contain {
  background-color: #232323;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -webkit-box-shadow: none;
  box-shadow: none
}

.dark .absolute_banner .collection-banner .absolute-contain h4 {
  color: #e7eaec
}

.dark .collection-banner .contain-banner.content-bg h2 {
  background-color: #2b2b2b;
  color: #f1f3f5
}

.dark .absolute-product .product-box {
  background-color: #232323;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease
}

.dark .absolute-product .product-box .product-detail .cart-bottom {
  border-color: #404040
}

.dark .absolute-product .product-box .product-detail .cart-bottom i {
  color: #cbcbcb
}

.dark .absolute-product .product-box .product-detail .cart-bottom i:hover {
  color: #fff
}

.dark .box-product .full-box .theme-card .offer-slider .product-box2 {
  -webkit-box-shadow: none;
  box-shadow: none
}

.dark .box-product .full-box .theme-card .offer-slider .product-box2 .media {
  background-color: #232323;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease
}

.dark .box-product .full-box .theme-card .offer-slider .product-box2 .cart-bottom {
  border-color: #404040
}

.dark .pets-box .product-box .img-wrapper .cart-info i {
  background-color: #2b2b2b
}

.dark .insta-title {
  background-color: #2b2b2b;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease
}

.dark .tab-bg {
  background-color: #232323;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease
}


.dark .sub-footer.dark-subfooter p {
  color: #cfd4da
}

.dark .cart-section tbody tr td a,
.dark .cart-section tbody tr td p,
.dark .wishlist-section tbody tr td a,
.dark .wishlist-section tbody tr td p {
  color: #cbcbcb
}

.dark .footer-title {
  border-color: #404040
}

.dark .category-bg {
  background-color: #232323;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease
}

.dark .category-bg .contain-block {
  background-color: #2b2b2b;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease
}

.dark .category-bg .contain-block h2 {
  color: #f1f3f5
}

.dark .category-bg .contain-block h6 span {
  color: #929292
}

.dark .service_slide .service-home .service-block1 {
  background-color: rgba(35, 35, 35, 0.9);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease
}

.dark .service_slide .service-home .service-block1:nth-child(even) {
  background-color: rgba(35, 35, 35, 0.7);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease
}

.dark .service_slide .service-home .service-block1 svg path {
  fill: #fff
}

.dark .bg-grey {
  background-color: #232323;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease
}

.dark .detail-cannabis .detail_section>div {
  background-color: #2b2b2b;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -webkit-box-shadow: none;
  box-shadow: none
}

.dark .detail-cannabis .detail_section svg {
  fill: #fff
}

.dark .border-box.tools-grey .product-box {
  border-color: #404040
}

.dark .demo-card.theme-card {
  background-color: #232323
}

.dark .theme-modal .modal-dialog .modal-content .modal-body {
  background-image: linear-gradient(135deg, var(--theme-color) 5.77%, #232323 5.77%, #232323 25%, #f1f3f5 25%, #f1f3f5 30.77%, #232323 30.77%, #232323 50%, var(--theme-color) 50%, var(--theme-color) 55.77%, #232323 55.77%, #232323 75%, #f1f3f5 75%, #f1f3f5 80.77%, #232323 80.77%, #232323 100%)
}

.dark .theme-modal .modal-dialog .modal-content .modal-body .modal-bg {
  background-color: #2b2b2b;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease
}

.dark .theme-modal .modal-dialog .modal-content .modal-body .modal-bg .age-content h4 {
  color: #e7eaec
}

.dark .theme-modal .modal-dialog .modal-content .modal-body .modal-bg .btn-close span {
  color: #fff
}

.dark .theme-modal .modal-dialog .modal-content .modal-body .modal-bg .offer-content h2 {
  color: #f1f3f5
}

.dark .theme-modal.demo-modal .modal-dialog .modal-content .modal-body {
  background-color: #2b2b2b;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease
}

.dark .theme-modal.demo-modal .modal-dialog .modal-content .modal-body .demo-section .demo-effects>div .layout-container {
  -webkit-box-shadow: none;
  box-shadow: none
}

.dark .theme-modal.demo-modal .modal-dialog .modal-content .modal-body .demo-section .demo-effects>div .demo-text h4 {
  color: #e7eaec
}

.dark .theme-modal.demo-modal .modal-dialog .modal-content .modal-body .demo-section .demo-effects>div .demo-text .demo-btn .btn {
  border-color: #404040;
  background-color: #2b2b2b;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  color: #cbcbcb
}

.dark .theme-modal.demo-modal .modal-dialog .modal-content .modal-body .demo-section .title-text h3 {
  color: #eaedef
}

.dark .theme-modal.cart-modal .modal-dialog .modal-content .modal-body .modal-bg.addtocart #upsell_product .product-box .product-detail h6 a {
  color: #cbcbcb
}

.dark .theme-modal.exit-modal .media .media-body h5 {
  color: #dee2e6
}

.dark .theme-modal.exit-modal .stop {
  -webkit-filter: invert(100);
  filter: invert(100)
}

.dark #quick-view .modal-dialog .modal-content .modal-body {
  background-color: #2b2b2b;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease
}

.dark .addcart_btm_popup {
  background-color: #232323;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  border-color: #404040
}

.dark .addcart_btm_popup .fixed_cart i {
  color: #cbcbcb
}

.dark .review-page .comnt-sec li a {
  color: #cbcbcb
}

.dark .demo-right a {
  background-color: #000;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease
}

.dark .color-picker a.handle {
  background-color: #000;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  color: #cbcbcb
}

.dark .setting-box .setting-title h4 {
  color: #333
}

.dark .faq-tab .nav-tabs .nav-item .nav-link {
  background-color: #232323;
  color: #cfd4da
}

.dark .faq-tab .nav-tabs .nav-item .nav-link.active {
  background-color: #232323
}

.dark .search-overlay {
  background-color: #2b2b2b
}

.dark .category-img-wrapper .category-wrap .category-content h3 {
  color: #eaedef
}

.dark .add_to_cart {
  background-color: #2b2b2b
}

.dark .add_to_cart .cart-inner .cart_top {
  border-color: #404040
}

.dark .add_to_cart .cart-inner .cart_top h3 {
  color: #eaedef
}

.dark .add_to_cart .cart-inner .cart_top .close-cart i {
  color: #cbcbcb
}

.dark .add_to_cart .cart-inner .cart_media li .total {
  border-color: #404040
}

.dark .add_to_cart .cart-inner .cart_media li .media .media-body h4 {
  color: #e7eaec
}

.dark .add_to_cart.top .cart-inner .cart_top,
.dark .add_to_cart.bottom .cart-inner .cart_top {
  background-color: #232323
}

.dark .add_to_cart .cart-inner {
  background-color: #2b2b2b
}

.dark .theme-settings ul li {
  background-color: #232323;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.54);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.54);
  color: #fff
}

.dark .theme-settings ul li a {
  color: #fff
}

.dark .theme-settings ul li .backend-btn a {
  color: #fff
}

.dark .setting-sidebar h5 {
  color: #ffffff
}

.dark .search-section select.form-control {
  /* background: url(../images/dropdown.png) 95% center no-repeat #2b2b2b */
}

.dark .cycle-box {
  background-color: #2b2b2b
}

.dark .cycle-box .bottom-detail h4 {
  color: #fff
}

.dark .cycle-box .product-detail h4 {
  color: #e7eaec
}

.dark .cycle-box .product-detail .details li {
  color: rgba(255, 255, 255, 0.63)
}

.dark .cycle-box .product-detail .add-wish {
  background-color: #232323
}

.dark .cycle-box .cart-detail {
  border-color: #404040
}

.dark .cycle-box .cart-detail li button,
.dark .cycle-box .cart-detail li a {
  color: rgba(255, 255, 255, 0.5)
}

.dark .cycle-box .cart-detail li svg {
  color: rgba(255, 255, 255, 0.5)
}

.dark .cycle-box .cart-detail li+li {
  border-color: rgba(255, 255, 255, 0.2)
}

.dark .cycle-box .img-wrapper:after {
  background-color: rgba(43, 43, 43, 0.77)
}

.dark .center-object-banner .full-banner {
  background-blend-mode: overlay;
  background-color: #2b2b2b
}

.dark .center-object-banner .full-banner.feature-banner h2 {
  color: #f1f3f5
}

.dark .center-object-banner .full-banner.feature-banner .feature-object li .media h4,
.dark .center-object-banner .full-banner.feature-banner .feature-object li .media p,
.dark .center-object-banner .full-banner.feature-banner .feature-object-right li .media h4,
.dark .center-object-banner .full-banner.feature-banner .feature-object-right li .media p {
  color: #e7eaec
}

.dark .cookie-bar.left-bottom {
  -webkit-box-shadow: 0 19px 38px rgba(0, 0, 0, 0.12), 0 15px 12px rgba(0, 0, 0, 0.13);
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.12), 0 15px 12px rgba(0, 0, 0, 0.13);
  background-color: #2b2b2b
}

.dark .cookie-bar.left-bottom p {
  color: #cfd4da
}

.dark .cookie-bar.left-bottom .btn-close i {
  color: #cbcbcb
}

.dark .mordern-box {
  background-color: #232323
}

.dark .mordern-box .mordern-content h6 {
  color: #cfd4da
}

.dark .mordern-box .mordern-content .mordern-bottom .right .add-extent .animated-btn {
  background-color: #2b2b2b;
  color: #cbcbcb
}

.dark .mordern-box .mordern-content .mordern-bottom .right .add-extent .options {
  background-color: #2b2b2b
}

.dark .mordern-box .mordern-content .mordern-bottom .right .add-extent .options li i {
  color: rgba(203, 203, 203, 0.6)
}

.dark .service-w-bg .service-block .media {
  background-color: #232323
}

.dark .product-parallax .theme-card .offer-slider>div .media {
  background-color: #2b2b2b
}

.dark .load-more-button .btn.loading {
  border-color: #404040 #404040 #404040 #999;
  background: #2b2b2b
}

.dark .footer-style-1 .footer-social i {
  background-color: #2b2b2b;
  border-color: #404040
}

.dark .category-width .category-block .category-image img {
  -webkit-filter: invert(0.6);
  filter: invert(0.6)
}

.dark .category-width .category-block:hover .category-image img {
  -webkit-filter: invert(1);
  filter: invert(1)
}

.dark .image-swatch li img {
  border-color: #404040
}

.dark .image-swatch li.active img {
  border-color: var(--theme-color)
}

.dark .added-notification {
  -webkit-box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.63);
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.63)
}

.dark .product-image-360 .nav_bar {
  background-color: #2b2b2b
}

.dark .gradient-category .gradient-border .img-sec {
  background-color: #232323
}

.dark .gradient-category .gradient-border .img-sec:after {
  -webkit-filter: invert(0.9);
  filter: invert(0.9)
}


.color-picker {
  position: fixed;
  right: -190px;
  top: calc(38vh + 220px);
  width: 190px;
  z-index: 1;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease
}

.color-picker .settings-header {
  background: #f3f3f3;
  padding: 5px 15px;
  -webkit-box-shadow: 0px -10px 20px -5px rgba(220, 220, 220, 0.62);
  box-shadow: 0px -10px 20px -5px rgba(220, 220, 220, 0.62)
}

.color-picker .settings-header h3 {
  color: black;
  margin: 0;
  font-size: 20px;
  padding: 4px 9px
}

.color-picker .section {
  background: white;
  padding: 10px;
  border: 1px solid #f3f3f3;
  overflow: auto
}

.color-picker .section i {
  font-size: 16px;
  margin-right: 10px
}

.color-picker a.handle {
  position: absolute;
  right: 190px;
  width: 45px;
  height: 41px;
  -webkit-transition: all ease-in 0.3s;
  transition: all ease-in 0.3s;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 22px;
  z-index: -1;
  text-decoration: none;
  background-color: #f9f9f9;
  -webkit-box-shadow: -5.985px -0.419px 30px 0 rgba(0, 0, 0, 0.08), inset -2px 0 10px 0 rgba(0, 0, 0, 0.04);
  box-shadow: -5.985px -0.419px 30px 0 rgba(0, 0, 0, 0.08), inset -2px 0 10px 0 rgba(0, 0, 0, 0.04);
  top: -80px;
  color: #000
}

.color-picker a.handle i {
  margin-left: 5px
}

.color-picker .colors a {
  width: 32px;
  height: 32px;
  margin: 5px;
  float: left;
  -webkit-transition: all ease-in 0.3s;
  transition: all ease-in 0.3s
}

.color-picker .skin a {
  display: block;
  width: 70px;
  height: 30px;
  margin-left: 0;
  margin-top: 0;
  margin-right: 5px;
  float: left;
  text-align: center;
  line-height: 30px;
  border: 2px transparent solid
}

.color-picker .skin a.actt {
  border: 2px white solid
}

.dark-light {
  position: fixed;
  right: 0;
  width: 40px;
  height: 38px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  z-index: 1;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 14px;
  text-decoration: none;
  border-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  background-color: #f7f7f7;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(154, 154, 154, 0.54);
  box-shadow: 0px 0px 5px 0px rgba(154, 154, 154, 0.54);
  top: calc(50% + 34px);
  cursor: pointer
}

.setting-sidebar {
  z-index: 9
}

.setting-sidebar h5 {
  color: #212529;
  text-transform: capitalize;
  line-height: 1.2;
  font-weight: 600;
  margin-bottom: 0;
  font-size: 14px;
  text-align: left
}

.setting-sidebar i {
  -webkit-animation: ring-rotate 3s infinite linear;
  animation: ring-rotate 3s infinite linear;
  font-size: 20px;
  color: #3c3c3c;
  margin-left: 10px
}

.setting-sidebar.open-icon {
  right: 351px;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease
}

.setting_box_body {
  position: relative;
  background-color: white;
  z-index: 9;
  height: 100vh
}

.scroll-setting-box ::-webkit-scrollbar {
  width: 4px;
  height: 7px
}

.scroll-setting-box ::-webkit-scrollbar-track {
  background: #ddd
}

.scroll-setting-box ::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px
}

.scroll-setting-box ::-webkit-scrollbar-thumb:hover {
  background: #555
}

.setting-box {
  position: fixed;
  height: 100vh;
  overflow-y: auto;
  width: 660px;
  right: -660px;
  background-color: white;
  top: 0;
  z-index: 999;
  -webkit-box-shadow: 0 15px 5px 0 rgba(77, 77, 77, 0.28);
  box-shadow: 0 15px 5px 0 rgba(77, 77, 77, 0.28);
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease
}

.setting-box.open-setting {
  right: 0;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease
}

.setting-box.open-setting .overlay {
  visibility: visible;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease
}

.setting-box .sidebar-back {
  padding: 20px;
  font-size: 18px;
  color: #222222;
  font-weight: 700;
  text-transform: uppercase;
  cursor: pointer;
  background-color: rgba(243, 243, 243, 0.8)
}

.setting-box .setting-title {
  border-bottom: 1px solid #efefef;
  margin-bottom: 0;
  text-align: center;
  padding: 30px 20px
}

.setting-box .setting-title h3 {
  line-height: 31px;
  margin-top: 10px;
  text-transform: capitalize;
  font-size: 20px;
  margin-bottom: 0;
  color: #a3a3a3
}

.setting-box .setting-title h3 span {
  color: #f54c3b;
  text-transform: uppercase
}

.setting-box .setting-title h4 {
  position: relative;
  margin-bottom: 0;
  cursor: pointer
}

.setting-box .setting-title.active .according-menu {
  font: normal normal normal 14px/1 FontAwesome
}

.setting-box .setting-title.active .according-menu:before {
  content: "\f068";
  position: absolute;
  right: 2px;
  top: 2px
}

.setting-box .setting-title .according-menu {
  font: normal normal normal 14px/1 FontAwesome
}

.setting-box .setting-title .according-menu:before {
  content: "\f067";
  position: absolute;
  right: 2px;
  top: 2px
}

.setting-box .setting-contant {
  padding: 20px;
  border-bottom: 1px solid #efefef
}

.setting-box .setting-contant .setting_buttons .setting_btn {
  background-color: var(--theme-color);
  color: white;
  width: 100%
}

.setting-box .setting-contant .setting_buttons li:first-child {
  width: 49%
}

.setting-box .setting-contant .setting_buttons li.active {
  -webkit-box-shadow: 0px 0px 5px 0px #929292;
  box-shadow: 0px 0px 5px 0px #929292
}

.setting-box .setting-contant .setting_buttons li:last-child {
  margin-left: 10px;
  width: 46%
}

.setting-box .setting-contant .color-box li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center
}

.setting-box .setting-contant .color-box li span {
  text-transform: capitalize;
  margin-bottom: 2px;
  font-size: 15px
}

.setting-box .setting-contant .color-box li input {
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  width: 34px;
  height: 34px;
  background-color: transparent;
  margin-right: 5px
}

.setting-box .setting-contant .color-box li input:focus {
  outline: none
}

.setting-box .setting-contant .color-box li+li {
  margin-top: 5px
}

.setting-box .buy_btn {
  padding: 20px
}

.setting-box .buy_btn a {
  background-color: #f3f3f3;
  color: #3c3c3c;
  padding: 10px;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease
}

.setting-box .buy_btn a i {
  margin-right: 5px;
  font-size: 18px
}

.setting-box .buy_btn a img {
  -webkit-filter: grayscale(100);
  filter: grayscale(100)
}

.setting-box .overlay {
  visibility: hidden;
  opacity: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease
}

.theme-settings {
  position: fixed;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 1
}

.theme-settings ul li {
  width: 40px;
  height: 38px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  z-index: 1;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 14px;
  text-decoration: none;
  border-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  background-color: #f7f7f7;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(154, 154, 154, 0.54);
  box-shadow: 0px 0px 5px 0px rgba(154, 154, 154, 0.54);
  margin-bottom: 15px;
  margin-left: auto;
  cursor: pointer;
  font-weight: 600
}

.theme-settings ul li:last-child {
  margin-bottom: 0
}

.theme-settings ul li.demo-li {
  width: 50px;
  height: 40px
}

.theme-settings ul li .backend-btn {
  font-size: 13px
}

.theme-settings ul li .backend-btn a {
  color: #222
}

.theme-settings ul li.input-picker {
  background: none;
  overflow: hidden
}

.theme-settings ul li.input-picker img {
  display: none
}

.theme-settings ul li.input-picker input {
  height: 100%;
  opacity: 1;
  padding: 0;
  border: none
}

.theme-settings ul li.input-picker input::-webkit-color-swatch {
  border: none
}

.demo-section .title-text h3 {
  color: var(--theme-color);
  margin-bottom: 24px;
  display: inline-block;
  padding-bottom: 5px;
  font-size: 40px;
  position: relative
}

.demo-section .title-text h3 i {
  font-size: 22px;
  position: absolute;
  top: 0;
  right: -20px
}

.demo-section .demo-effects {
  margin-bottom: 30px
}

.demo-section .demo-effects h4 {
  text-transform: capitalize
}

.demo-section .demo-effects .set-position {
  display: block
}

.demo-section .demo-effects>div {
  text-decoration: none;
  color: black
}

.demo-section .demo-effects>div .layout-container {
  height: 130px;
  width: 100%;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  background-size: cover;
  -webkit-transition: all 2s ease-out 0s;
  transition: all 2s ease-out 0s;
  -webkit-box-shadow: 1px 6px 19px 2px rgba(0, 0, 0, 0.1);
  box-shadow: 1px 6px 19px 2px rgba(0, 0, 0, 0.1)
}

.demo-section .demo-effects>div .demo-text h4 {
  font-size: 16px;
  margin-top: 10px;
  margin-bottom: 0;
  color: #222;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center
}

.demo-section .demo-effects>div .demo-text h4 span {
  background-color: var(--theme-color);
  border-radius: 4px;
  color: #fff;
  font-size: 13px;
  width: 40px;
  height: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-weight: 600;
  margin-left: 6px
}

.demo-section .demo-effects>div .demo-text .demo-btn {
  margin-top: 10px
}

.demo-section .demo-effects>div .demo-text .demo-btn .btn {
  border: 1px solid #ddd;
  background-color: white;
  width: 90px;
  color: var(--theme-color);
  padding: 5px 10px;
  border-radius: 30px
}

.demo-section .demo-effects>div .demo-text .demo-btn .btn:hover {
  background-color: var(--theme-color);
  color: white
}

.demo-section .demo-effects:last-child {
  margin-bottom: 0
}

.demo-section .demo-effects.effect-2 .layout-container {
  background-size: cover;
  background-repeat: no-repeat
}

.demo-section .demo-effects.effect-2:hover .layout-container {
  background-position: top !important
}

.demo-section .demo-effects.effect-3 .layout-container {
  background-size: cover;
  background-repeat: no-repeat
}

.demo-section .demo-effects.effect-3:hover .layout-container {
  background-position: top !important
}

@keyframes ring-rotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg)
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg)
  }
}

.sec-position {
  position: relative;
  top: -80px
}

.dark-demo .dark-light-btn {
  display: none
}

.tooltip-sec li {
  position: relative
}

.tooltip-sec li .tooltip-cls {
  position: absolute;
  right: 60px;
  top: 0;
  background-color: white;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  text-transform: capitalize;
  color: #223645;
  -webkit-box-shadow: 0 0 16px -4px rgba(0, 20, 40, 0.2), 0 0 80px -10px rgba(0, 20, 40, 0.3);
  box-shadow: 0 0 16px -4px rgba(0, 20, 40, 0.2), 0 0 80px -10px rgba(0, 20, 40, 0.3);
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  z-index: 1
}

.tooltip-sec li .tooltip-cls:after {
  position: absolute;
  content: "";
  right: -8px;
  width: 0;
  height: 0;
  border-top: 7px solid transparent;
  border-left: 8px solid #edf7fb;
  border-bottom: 7px solid transparent;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%)
}

.tooltip-sec li:hover .tooltip-cls {
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease
}



.custom-theme {
  position: fixed;
  right: 0;
  width: 40px;
  height: 38px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  z-index: 1;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 14px;
  text-decoration: none;
  border-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  background-color: #f7f7f7;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(154, 154, 154, 0.54);
  box-shadow: 0px 0px 5px 0px rgba(154, 154, 154, 0.54);
  top: calc(50% + 34px);
  cursor: pointer
}


.carousel-indicators {
  display: none;
}

.myElement {
  letter-spacing: .8px;
  font-size: 14px;
}

.title-block-ellipsis-2line {
  display: -webkit-box;
  max-width: 100%;
  /* height: 30%; */
  margin: 0 auto;
  font-size: 16px !important;
  line-height: 1;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  white-space: initial;
  text-overflow: ellipsis;
}

.title-block-ellipsis-3line {
  display: -webkit-box;
  max-width: 100%;
  margin: 0 auto;
  height: 30%;
  font-size: 16px !important;
  line-height: 1;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  white-space: initial;
  text-overflow: ellipsis;
}

.block-ellipsis-3line {
  display: -webkit-box;
  line-height: 1;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  white-space: initial;
  text-overflow: ellipsis;
}

.block-ellipsis-4line {
  display: -webkit-box;
  line-height: 1;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  white-space: initial;
  text-overflow: ellipsis;
}

.full-size-image {
  width: 100%
}

.section-container {
  min-height: 100px;
  overflow: hidden;
}

.overlay-text {
  background-color: rgba(0, 0, 0, 0.5);
}
.cursor-pointer {
  cursor: pointer;
}