.ethical-img {
    display: flex;
    justify-content: center;
    align-items: center;
}

.g {
    height: 100%;
    width: 90%;
    margin: 0;
    overflow: hidden;
}

