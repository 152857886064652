/* .radio-toolbar {
    
} */
  
.radio-toolbar input[type="radio"] {
    opacity: 0;
    position: fixed;
    width: 0;
    cursor: pointer;
    
}

.radio-toolbar label {
    display: inline-block;
    cursor: pointer;
    /*background-color: #ddd;*/
    /* padding: 1px 5px; */
    font-size: 14px;
    /* border: 1px solid #444; */
    border-radius: 2px;
}

/*.radio-toolbar label:hover {
    background-color: #dfd;
}

.radio-toolbar input[type="radio"]:focus + label {
    border: 2px dashed #444;
}*/

/* .radio-toolbar input[type="radio"]:checked + label {
    background-color: #918c8c;
    color: #ffffff;
    margin: 5px;
} */

.highlighted {
    background-color: yellow !important;
  }

  .highlight-option{
    background-color: #40590f !important;
    color: white !important;
  }

  .highlight-nonselect-option{
    background-color: grey !important;
    color: white !important;
  }
 .height{
    height: 55px;
 }