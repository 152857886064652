.subscribe-form .form-control {
    padding: 15px
}

footer .footer-theme .footer-mobile-title {
    display: none;
}

.footer-contant {
    display: block;
}

.logo-contant {
    font-size: 16.5px;
}

.footer-bg {
    background-image: url(/public/assets/images/footer-bg.png);
    color: black;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

/* .footer-title h6{
    border-bottom: 2px solid ;
} */
.footer-bg p {
    color: #f6f6f6;
}

footer p {
    line-height: 32px;
    letter-spacing: 0.03em;
    margin-bottom: 0;
    font-size: 14px;
    /* font-size: 1.1rem; */
}

.footer-social svg {
    color: #f6f6f6;
}

.wrap-content {
    width: 100%;
    word-wrap: break-word;
}

.footer-logo img {
    background-color: #ffffff;
}