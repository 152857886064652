.banner {
    position: relative;
}
.banner figure img {
    width: 100%;
}
.banner .banner-content {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}
.banner .banner-content p {
    color: #fff;
    /* font-size: 25px; */
    line-height: 35px;
    font-weight: 400;
    margin-bottom: 0;
    text-shadow: 0 0 15px #000;
}
.banner .banner-content p strong {
    /* font-size: 80px; */
    font-weight: 700;
}
.info-sec {
    /* background-image: url(/public//assets/images/aboutus.jpeg); */
    /* padding: 68px 48px 59px 60px; */
    position: relative;
    height: 100%;
    background-color: #FAFFF7;
    border-radius: 5px;
}
.inner-banner {
    position: relative;
    float: left;
    margin-bottom: 70px;
    width: 100%;
    padding-top: 15px;
}
.inner-banner .intro {
    width: 640px;
    float: left;
}
.inner-banner .intro h1 {
    color: #3b404b;
    /* font-size: 50px; */
    line-height: 60px;
    padding-top: 40px;
    text-transform: capitalize;
    padding-bottom: 25px;
}
.banner-inner {
    position: absolute;
    right: 150px;
    top: 0;
    width: auto;
}

.Bio-card-view {
    position: absolute;
    display: flex;
    flex-wrap: wrap;
    padding: 20px;
    top: 5%;
    left: 25px;
}

.about .info {
    background: #ffffff;
    padding: 20px 48px 5px 60px;
    position: relative;
    height: 37rem;   
    border-radius: 5px;

    }
/* 
.about .info h3{
    color: #ffffff;
} */

.about .info p{
    /* color: #FfFfFf; */
    margin-bottom: 0px;
  
}

.about-section2{
    background-color: #dbdbdb;
}

.sustainability-initiatives .info{
    height: 37rem

}
.info{
    background-color: #ffffff;
    box-shadow: 0px 0px 30px 0px rgba(0,0,0,0.1);
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    margin: 20px 20px 0px 20px;
    padding: 20px 36px 0px 30px;
    border-radius: 5px 5px 5px 5px;
    text-align: center;
}
.info-card{
    margin-top: -10rem;
}

.sustainability-initiatives {
    background-image: url(/public/assets/images/icon/about-us/product\ portfolio\ background\ 3.jpeg);
    /* padding: 68px 48px 59px 60px; */
    background-color: #EBDED5;
    position: relative;
    height: 100%;
    border-radius: 5px;
    background-size: contain;
}
/* .portfolio-bg{
    background-image: url(/public//assets/images/icon/about-us/product-portfolio.jpeg);
   position: relative;
   height: 100%;
   background-size: cover;
} */
.info h3{
    font-weight: 600;
    color: #517b04;
    margin-top: 22px;
    font-size: 1.4rem !important;
}

.info p{
    font-size: 1.0rem;
}

.counter h3{
    color: #517b04;
}

@keyframes cardScrollUp {
    0% {
      opacity: 0;
      transform: translateY(50px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .scroll-up {

    animation: cardScrollUp 1s ease-in-out;
  }