.carousel-indicators {
    display: none;
}
a {
    transition: 0.5s ease;
    text-decoration: none;
  }
.btn-solid {
    padding: 13px 29px;
    color: #fff;
    letter-spacing: 0.05em;
    border: 2px solid var(--theme-color);
    background-image: linear-gradient(30deg, var(--theme-color) 50%, transparent 50%);
    background-size: 850px;
    background-repeat: no-repeat;
    background-position: 0;
    -webkit-transition: background 300ms ease-in-out;
    transition: background 300ms ease-in-out;
}

.slick-track {
    display: flex;
}

.product-bg {
    background-image: url("/public/assets/images/banner-images/homeBg.jpeg");
    background-size: cover;
    background-position: center center;
    display: block;
}

.input-group-text{
    background-color: var(--theme-color) !important;
    color: #f3f3f3;
}
.title1 h4 {
    color: #ff4c3b;
    padding-bottom: 10px;
    margin-bottom: 0;
    margin-top: -3px;
    font-size: 18px;
    text-transform: capitalize;
    line-height: 1;
}

.carousel-caption {
    position: absolute;
    top: 100px;
}

.swiper-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding: 60px 0;
    max-width: 650px;
    margin-left: auto;
    margin-right: 80px;
}

.content-info {
    padding-bottom: 30px;
    z-index: 2;
    position: relative;
}

.banner-content .title {
    color: #24262b;
    font-size: 55px;
    color: #222;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 1.1;
}

.banner-content .text {
    color: #5E626F;
    font-size: 18px;
    font-weight: 400;
    max-width: 480px;
}

.banner-content .swiper-meta-items {
    margin: 25px 0px 25px;
    display: flex;
    align-items: center;
}

.banner-content .swiper-meta-items .meta-content {
    margin-right: 90px;
}

.banner-content .swiper-meta-items .meta-content span {
    display: block;
}

.banner-content .swiper-meta-items .meta-content .color-name {
    margin-bottom: 10px;
}

 .banner-media {
    position: relative;
    width: 100%;
    z-index: 1;
    float: left;
    background-color: #FAFAFA;
    height: 100%;
    text-align: center;
    display: flex;
    align-content: center;
    justify-content: center;
}

.banner-media .img-preview {
    padding: 70px;
    width: 550px;
}

.img-preview img{
    width: 100%;
}
.gx-0{
    background: #f3f3f3;
}


.add-cart-btn {
    border-color: var(--theme-color);
    font-size: 16px;
    padding: 12px 30px;
    line-height: 24px;
    display: inline-flex;
    border-radius: 0;
    font-weight: 500;
    position: relative;
    text-transform: capitalize;
    align-items: center;
    overflow: hidden;
    z-index: 1;
    text-align: center;
    -webkit-transition: all 0.2s;
    -ms-transition: all 0.2s;
    transition: all 0.2s;
    justify-content: center;
    background: var(--theme-color);
    color: aliceblue;
    margin-right: 10px;
}

.view-detail-btn{
    font-size: 16px;
    padding: 12px 30px;
    line-height: 24px;
    display: inline-flex;
    border-radius: 0;
    font-weight: 500;
    position: relative;
    text-transform: capitalize;
    /* font-family: "DM Sans", sans-serif; */
    align-items: center;
    overflow: hidden;
    z-index: 1;
    text-align: center;
    -webkit-transition: all 0.2s;
    -ms-transition: all 0.2s;
    transition: all 0.2s;
    justify-content: center;
}
.btnhover20{
    width: 200px; /* You can adjust this value as needed */
}

.Certification-card-view{
    position: absolute;
    display: flex;
    flex-wrap: wrap;
    top: -5%;
    left: 20px;
}

.Certification-set {
    background-color: #e9ebe8;
    border: 16px solid black;
    height: 474px !important;
    z-index: 1;
}

.transparency-accontability {
    top: 15%;
}
.readmore-btn {
    background: #000;
    border: 1px solid hsla(0,0%,100%,.7);
    border-radius: 25px;
    color: #fff;
    display: inline-block;
    padding: 10px 30px;
    text-shadow: 1px 1px rgba(0,0,0,.35);
}
.readmore-btn:hover{
    color: #e9ebe8;
}