.bamboo-view{
    position: relative;
    width: 100%;
}
.bamboo-card-view{
    position: absolute;
    display: flex;
    flex-wrap: wrap;
    padding: 20px;
    top:10%;
    left: 20px;
}
.success{
    background-color: antiquewhite;
}
.dark{
    background-color: #ffa6a6f7;
}
.set-img{
    margin-top: -260px !important;
}